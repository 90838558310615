import React, { useEffect, useMemo } from "react";
import { ACTION_TYPES } from "../constants";
import { DATE_RANGE_TYPES } from "units/market-share/constants/date";
import { RouteConstant } from "../../../../lib/constants/routes";
import checkUserCanAccess, {
  MY_SALES_2P_MY_BRAND_SALES_2P
} from "units/market-share/utility/checkUserAccessPermission";
import { ROUTE } from "lib/constants/routes";
import useSubscriptionType from "lib/hooks/useSubscriptionType";
import WALMART_CONSTS from "lib/constants/walmartConstants";

import useDateRange from "./useDateRange";
import useRootState from "./useRootState";

export default function useInPageFilter(id: RouteConstant) {
  const { state, dispatch } = useRootState();
  const { inPageFilter } = state;
  const { getSubscriptionTypeId } = useSubscriptionType();
  const { canUserView: canUserViewMyBrandSales2p } = checkUserCanAccess(
    MY_SALES_2P_MY_BRAND_SALES_2P,
    getSubscriptionTypeId(),
    "view"
  );
  const { rollup, disabledRollups } = useDateRange();

  const data = useMemo(() => {
    const filter = { ...inPageFilter[id] };
    if (!canUserViewMyBrandSales2p) {
      if (id === ROUTE.MyBusiness.Constant) {
        filter.salesType = inPageFilter[id]?.salesType.filter((type) => type !== "2p");
      } else if (id === ROUTE.MarketView.Constant) {
        filter.salesType = [WALMART_CONSTS.TOTAL_X];
      }
    }
    return filter;
  }, [inPageFilter[id]]);

  const setActive = (payload) => dispatch({ type: ACTION_TYPES.IN_PAGE_FILTERS.ACTIVATE, payload });

  const setFilter = (value) => dispatch({ type: ACTION_TYPES.IN_PAGE_FILTERS.SETFILTER, payload: { id, value } });

  useEffect(() => {
    if (
      rollup &&
      id &&
      [ROUTE.MyBusiness.Constant as RouteConstant, ROUTE.MarketView.Constant as RouteConstant].includes(id)
    ) {
      const currentPeriodSelection: DATE_RANGE_TYPES = inPageFilter[id].period;
      const isDisabled = disabledRollups?.includes(currentPeriodSelection);
      const value = { filter: "period", field: isDisabled ? rollup : currentPeriodSelection };
      setFilter(value);
    }
  }, [rollup, id, disabledRollups]);

  return React.useMemo(
    () => ({
      state: data ?? {},
      setActive,
      setFilter,
      disabledPeriodRollups: disabledRollups
    }),
    [data, setActive, setFilter, disabledRollups]
  );
}
