import React from "react";

import { ACTION_TYPES } from "../constants";
import useRootState from "./useRootState";

export default function useTableViewTypeOptions(id) {
  const { state, dispatch } = useRootState();

  const data = state.tableViewTypeOptions[id];
  const init = React.useCallback(
    (initObj) =>
      dispatch({
        type: ACTION_TYPES.TABLE_VIEW_TYPE_OPTIONS.INIT,
        payload: { id, initObj }
      }),
    [id, dispatch]
  );
  const setType = React.useCallback(
    (type) =>
      dispatch({
        type: ACTION_TYPES.TABLE_VIEW_TYPE_OPTIONS.SET_TYPE,
        payload: { id, type }
      }),
    [id, dispatch]
  );

  return React.useMemo(
    () => ({
      state: data ?? {},
      init,
      setType
    }),
    [data, init, setType]
  );
}
