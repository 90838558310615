/* eslint-disable @typescript-eslint/prefer-as-const */

export const SEARCH_PANE = {
  Portfolio: "portfolio" as "portfolio",
  PortfolioMore: "portfolio.more" as "portfolio.more",
  PortfolioCustom: "portfolio.custom" as "portfolio.custom",
  Period: "period" as "period",
  PeriodCustom: "period.custom" as "period.custom",
  Download: "download" as "download",
  Preferences: "preferences" as "preferences",
  User: "user" as "user",
  Search: "search" as "search",
  SearchSave: "search.save" as "search.save",
  ProfitabilityRateCard: "profitability.ratecard" as "profitability.ratecard",
  Retailer: "retailer" as "retailer"
};

export type SearchPane = typeof SEARCH_PANE[keyof typeof SEARCH_PANE];
export type SearchPanes = SearchPane[];

export const SEARCH_PARAM = {
  Pane: {
    Key: "pane" as "pane",
    Value: null as null | SearchPane
  },
  Filter: {
    Key: "filter" as "filter",
    Value: null as null | string
  },
  Editing: {
    Key: "editing" as "editing",
    Value: null as null | string
  },
  Period: {
    Key: "period" as "period",
    Value: null as null | string
  },
  Search: {
    Key: "search" as "search",
    Value: null as null | string
  }
};

export type SearchParam = typeof SEARCH_PARAM[keyof typeof SEARCH_PARAM];

export type SearchParams = {
  [K in SearchParam["Key"]]: Extract<SearchParam, { Key: K }>["Value"];
};

export const searchV2Header = {
  department: "Department",
  clicked_asin1: "No One Clicked ASIN",
  clicked_asin2: "No Two Clicked ASIN",
  clicked_asin3: "No Three Clicked ASIN",
  searchTerm: "Search Term",
  search_frequency_rank: "Search Frequency Rank",
  clicked_product_title1: "No One Product Title",
  click_share1: "No One Click Share",
  conversion_share1: "No One Conversion Share",
  clicked_product_title2: "No Two Product Title",
  click_share2: "No Two Click Share",
  conversion_share2: "No Two Conversion Share",
  clicked_product_title3: "No Three Product Title",
  click_share3: "No Three Click Share",
  conversion_share3: "No Three Conversion Share",
  startDate: "Start Date"
};
