import { ROUTE_MISSING, ROUTES, RouteValue } from "lib/constants/routes";

export function findRoute(value: string, ...keys: (keyof RouteValue)[]): RouteValue | ROUTE_MISSING {
  if (keys.length === 0) {
    keys = ["Constant", "Link", "LegacyId"];
  }
  for (const route of ROUTES) {
    for (const key of keys) {
      if (value === route[key]) {
        return route;
      }
    }
  }
  return ROUTE_MISSING;
}
