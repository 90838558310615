import styled from "styled-components/macro";

// styles
export const Ul = styled.ul<any>`
  padding-left: 1.5em;
  height: "17em",
  overflowY: "auto",
  margin: 0,
  padding: "0.7em 1.5em 1em 1.2em"
`;

export const Li = styled.li`
  line-height: 1.7;
  text-align: justify;
`;

export const HyperLink = styled.a`
  color: var(--color-primary);
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-body);
  text-decoration: none;
  padding-right: 4px;
  &:hover {
    text-decoration: underline;
  }
`;

// constants
export const passwordRules = (buttonName: string) => [
  {
    id: 1,
    rule: "We recommend password lengths of 12 characters or more and using a mix of case sensitive letters, numbers & symbols to create a more secure password."
  },
  {
    id: 2,
    rule: "Things to avoid:",
    subRule: ["Repeating characters", "Personally identifiable words/info", "Common words"]
  },
  {
    id: 4,
    rule: `Once you have a secure enough password, the '${buttonName} Password' button will allow you to proceed.`
  },
  { id: 5, rule: `if helpful`, link: "Password Generator Tool " }
];

export const passwordGenerator = {
  title: "Password Generator Tool",
  url: "https://www.lastpass.com/features/password-generator#generatorTool"
};
