import { PREDEFINED_PERIODS } from "../constants/date";

const COMMON_TRANSLATIONS = {
  "comingSoon": "Coming Soon",
  "accrued": "Accrued",
  "accrued_asc": "Accrued (asc)",
  "accrued_desc": "Accrued (desc)",
  "apply": "Apply",
  "applying": "Applying…",
  "add": "Add",
  "update": "Update",
  "added": "Added",
  "updated": "Updated",
  "done": "Done",
  "upload": "Upload",
  "load": "Load",
  "edit": "Edit",
  "clear": "Clear",
  "close": "Close",
  "cancel": "Cancel",
  "yes": "Yes",
  "confirm": "Confirm",
  "more": "More…",
  "select": "Select",
  "selected": "Selected",
  "loading": "Loading…",
  "all": "All",
  "noFilter": "No Filter",
  "copy": "Copy",
  "copied": "Copied",
  "copyLink": "Copy Link",
  "delete": "Delete",
  "deleting": "Deleting…",
  "deletingName": "Deleting… {name}",
  "deleted": "Deleted",
  "deletedName": "{name} deleted",
  "deletingTroubleName": "We are having trouble deleting {name}. Please try again later.",
  "revert": "Revert",
  "save": "Save",
  "saving": "Saving…",
  "saveAs": "Save as…",
  "custom": "Custom",
  "getData": "Get Data",
  "noData": "No data returned based on current filter selections",
  "retrievingData": "Retrieving data",
  "msLite": "MS Lite",
  "msLiteIncludedSalesTypes": "(1P & 3P)",
  "ms": "MS",
  "lite": "Lite",
  "vatSalesOnly": "(VAT SALES ONLY)",
  "vatNonSalesOnly": "(NON-VAT SALES ONLY)",
  "notSubscribed": "You are not subscribed to view this product. Please contact your account manager.",
  "internalClientMessage":
    "No Client is currently selected, to select a client, click the “Manage and more” button/icon on the top, then click on “Edge Staff” to open up the Client Selector.",

  "registeredDesign.eu.application": "Registered Community Design Nos. {number}",

  "year.short": "Year",
  "quarter.short": "Qtr",
  "month.short": "Mth",
  "day.short": "day",
  "week.short": "Wk",
  "sunday.short": "Su",
  "monday.short": "Mo",
  "tuesday.short": "Tu",
  "wednesday.short": "We",
  "thursday.short": "Th",
  "friday.short": "Fr",
  "saturday.short": "Sa",

  "weekN.short": "W{n}",
  "quarterN.short": "Q{n}",
  "weekN.long": "Week {n}",
  "quarterN.long": "Quarter {n}",

  "page.noFiscalCalendarData": "There is no released data for this and last quarter fiscal calendar.",
  "page.searchFiscalCalendarData": "Searching for released data within this and last fiscal quarter...",
  "dateRange.quarter": "Q{quarter} | {start} – {end}",
  "dateRange.quarter.same": "Q{startQuarter} {startYear}",
  "dateRange.quarter.different.sameYear": "Q{startQuarter} – Q{endQuarter} {startYear}",
  "dateRange.quarter.different.differentYear": "Q{startQuarter} {startYear} – Q{endQuarter} {endYear}",
  "dateRange.quarter.compact.same": "Q{startQuarter}",
  "dateRange.quarter.compact.different.sameYear": "Q{startQuarter} – Q{endQuarter}",
  "dateRange.quarter.compact.different.differentYear": "Q{startQuarter} – Q{endQuarter}",
  "dateRange.month.same": "{startMonth} {startYear}",
  "dateRange.month.different.sameYear": "{startMonth} – {endMonth} {startYear}",
  "dateRange.month.different.differentYear": "{startMonth} {startYear} – {endMonth} {endYear}",
  "dateRange.month.compact.same": "{startMonth}",
  "dateRange.month.compact.different.sameYear": "{startMonth} – {endMonth}",
  "dateRange.month.compact.different.differentYear": "{startMonth} – {endMonth}",
  "dateRange.week.same": "Week {startWeek} {startYear}",
  "dateRange.week.different.sameYear": "Week {startWeek} – Week {endWeek} {startYear}",
  "dateRange.week.different.differentYear": "Week {startWeek} {startYear} – Week {endWeek} {endYear}",
  "dateRange.week.compact.same": "W{startWeek}",
  "dateRange.week.compact.different.sameYear": "W{startWeek} – W{endWeek}",
  "dateRange.week.compact.different.differentYear": "W{startWeek} – W{endWeek}",
  "dateRange.day.same": "{startDay} {startMonth} {startYear}",
  "dateRange.day.different.sameMonth": "{startDay} – {endDay} {startMonth} {startYear}",
  "dateRange.day.different.sameYear": "{startDay} {startMonth} – {endDay} {endMonth} {startYear}",
  "dateRange.day.different.differentYear": "{startDay} {startMonth} {startYear} – {endDay} {endMonth} {endYear}",
  [`dateRange.predefined.${PREDEFINED_PERIODS.TODAY}`]: "Today",
  [`dateRange.predefined.${PREDEFINED_PERIODS.YESTERDAY}`]: "Yesterday",
  [`dateRange.predefined.${PREDEFINED_PERIODS.THIS_WEEK}`]: "This Week",
  [`dateRange.predefined.${PREDEFINED_PERIODS.THIS_MONTH}`]: "Latest Month",
  [`dateRange.predefined.${PREDEFINED_PERIODS.THIS_QUARTER}`]: "Latest Quarter",
  [`dateRange.predefined.${PREDEFINED_PERIODS.THIS_YEAR}`]: "Latest Year",
  [`dateRange.predefined.${PREDEFINED_PERIODS.LAST_WEEK}`]: "Previous Week",
  [`dateRange.predefined.${PREDEFINED_PERIODS.FOUR_WEEKS_TO_DATE}`]: "Latest 4 Weeks",
  [`dateRange.predefined.${PREDEFINED_PERIODS.THIRTEEN_WEEKS_TO_DATE}`]: "Latest 13 Weeks",
  [`dateRange.predefined.${PREDEFINED_PERIODS.TWENTYSIX_WEEKS_TO_DATE}`]: "Latest 26 Weeks",
  [`dateRange.predefined.${PREDEFINED_PERIODS.FIFTYTWO_WEEKS_TO_DATE}`]: "Latest 52 Weeks",
  [`dateRange.predefined.${PREDEFINED_PERIODS.LAST_MONTH}`]: "Previous Month",
  [`dateRange.predefined.${PREDEFINED_PERIODS.LAST_QUARTER}`]: "Previous Quarter",
  [`dateRange.predefined.${PREDEFINED_PERIODS.LAST_YEAR}`]: "Previous Year",

  "number.thousand": "thousand",
  "number.thousand.short": "K",
  "number.million": "million",
  "number.million.short": "M",
  "number.billion": "billion",
  "number.billion.short": "B",
  "number.trillion": "trillion",
  "number.trillion.short": "T",

  "number.ordinal.first": "first",
  "number.ordinal.second": "second",
  "number.ordinal.third": "third",
  "number.ordinal.fourth": "fourth",
  "number.ordinal.last": "last",

  "filter.portfolio": "Portfolio",
  "filter.customPortfolio": "Custom Portfolio",
  "filter.customPortfolio.create": "New Portfolio Filter",
  "filter.dateRange": "Period",
  "filter.customDateRange": "Custom Period",

  "pageTitle": "Market Share",
  "pageTitle.productName.midTier": "MARKET SHARE",
  "pageTitle.myBusiness": "My Business",
  "pageTitle.marketView": "Market View",
  "pageTitle.competitive": "Market View",
  "pageTitle.popularReports": "Popular Reports",
  "pageTitle.help": "Help",
  "pageTitle.missing": "Page not found",
  "pageTitle.manage": "Manage",
  "pageTitle.manage.reportSharing": "Report Sharing",
  "pageTitle.manage.manageFilter": "Manage Portfolio Filters",
  "pageTitle.manage.manageFilter.create": "New Portfolio Filter",
  "pageTitle.manage.users": "User Management",
  "pageTitle.manage.catalog": "Manage Catalog",
  "pageTitle.manage.filters": "Manage Filters",
  "pageTitle.account.profile": "Profile Management",
  "pageTitle.scorecard": "Scorecard",
  "pageTitle.logout": "Log out",
  "manage.reportSharing.edit": "Edit",
  "manage.reportSharing.delete": "Delete",
  "manage.filters.confirmDelete": "Are you sure you want to delete this filter?",
  "manage.reportSharing.confirmDeleteName": "Are you sure you want to delete {name}?",

  "chartLimiter.heading": "This report can show up to {limit} series",
  "chartLimiter.pendingChoice": "You chose to show",
  "chartLimiter.instruction": "Choose a <series>series</series> to hide or <cancel>cancel</cancel>",

  "section.missing.detail": "This page does not exist.",

  "section.notSubscribed": "Not subscribed",
  "section.notSubscribed.detail": "You are not subscribed to this feature.",
  "section.PoP.toolTip.info": `Period over period compares your current selected time period compared to the preceding time period. (Ex: Selecting Weeks 5-8 would compare to Weeks 1-4)`,
  "common.section.dateRange.weeks": "{count, plural, =0 {Week {start}} other {Weeks {start} – {end}}}",

  "core.components.activeDate.dateRange.weeks":
    "{count, plural, =0 {W{start} | {startDay} - {endDate}} other {W{end} | {startDay} - {endDate}}}",

  "core.components.optionsSection.title": "Options",
  "core.components.optionsButton.options": "Options",
  "core.components.optionsButton.done": "Done",
  "core.components.optionsButton.apply": "Apply",
  "core.components.optionsButton.close": "Close",

  "options.components.display.subtitle.main": "Display",

  "options.components.download.subtitle.main": "Download",
  "options.components.download.subtitle.format": "Select format",
  "options.components.download.format.csv": "CSV",
  "options.components.download.format.xlsx": "XLSX",
  "options.components.download.format.pdf": "PDF",
  "options.components.download.format.api": "API",
  "options.components.download.subtitle.layout": "Select Layout(s)",
  "options.components.download.layout.letterPortrait": "Letter portrait",
  "options.components.download.layout.letterLandscape": "Letter landscape",
  "options.components.download.layout.a4Portrait": "A4 portrait",
  "options.components.download.layout.a4Landscape": "A4 landscape",

  "options.components.download.subtitle.fileName": "Edit Report name",
  "options.components.download.subtitle.fileName2": "(optional)",
  "options.components.download.subtitle.when": "Download report",
  "options.components.download.when.now": "Download now",
  "options.components.download.when.downloading": "Downloading...",
  "options.components.download.when.schedule": "Schedule...",
  "options.components.download.filename.edit": "Edit",
  "options.components.download.filename.done": "Done",
  "options.components.download.filename.default.my_business_sales": "My Accrued Sales",
  "options.components.download.filename.default.my_business_share": "My Accrued Share",
  "options.components.download.filename.default.my_business_sales_drivers": "My Sales Drivers",
  "options.components.download.filename.default.my_business_brand": "My Brand Sales",
  "options.components.download.filename.default.competition_sales": "Market View Sales",
  "options.components.download.filename.default.competition_share": "Market View Share",
  "options.components.download.filename.default.custom_download_form": "My-Sales-Report",

  "options.components.schedule.subtitle.main": "Schedule",
  "options.components.schedule.subtitle.period": "Period",
  "options.components.schedule.period.thisMonth": "This Month",
  "options.components.schedule.subtitle.frequency": "Frequency",
  "options.components.schedule.frequency.weekly": "Weekly",
  "options.components.schedule.frequency.monthly": "Monthly",
  "options.components.schedule.subtitle.receiveOn": "Receive on",
  "options.components.schedule.receiveOn.scheduled": "scheduled dates",
  "options.components.schedule.receiveOn.specific": "specific dates",
  "options.components.schedule.subtitle.selectDays": "Select days",
  "options.components.schedule.subtitle.selectScheduled": "Select scheduled dates",
  "options.components.schedule.subtitle.selectSpecific": "Select specific dates",
  "options.components.schedule.subtitle.summary": "Summary",
  "options.components.schedule.summary.firstSent":
    "{reportName} will first be sent on {dayOfWeek} {date} {month} {year}",
  "options.components.schedule.summary.subsequentSent":
    "It will then be sent {frequency} every {timePeriod} to {recipientNumber} recipient(s)",
  "options.components.schedule.summary.managePart1":
    "This schedule is saved in {page} where it can be edited at any time.",
  "options.components.schedule.summary.reportSharing": "Report Sharing",
  "options.components.schedule.sentSchedule": "{reportName} will be sent {frequency} every {timePeriod}",

  "options.components.schedule.subtitle.share": "Share",
  "options.components.schedule.share.selectedRecipients": "Selected Recipients",
  "options.components.schedule.share.find": "Find",
  "options.components.schedule.share.findRecipients": "Find Recipients",
  "options.components.schedule.share.searchRecipients": "Search for a Recipient in your organization",
  "options.components.schedule.share.suggestedRecipients": "Recent and suggested Recipients",

  "options.components.schedule.action.create": "Save Schedule",
  "options.components.schedule.action.saving": "Saving",

  "options.components.topMoversPerformers.movers": "Movers",
  "options.components.topMoversPerformers.performers": "Leaders",

  "options.components.graphTypeOptions.title": "Weekly view chart",
  "options.components.graphTypeOptions.showArea": "Show Area",
  "options.components.graphTypeOptions.showBar": "Show Bar",
  "options.components.graphTypeOptions.showLine": "Show Line",

  "options.components.dateComparison.compare": "Compare",
  "options.components.dateComparison.compare2": "(3 max.)",
  "options.components.dateComparison.wow": "WoW",
  "options.components.dateComparison.mom": "MoM",
  "options.components.dateComparison.qoq": "QoQ",
  "options.components.dateComparison.yoy": "YoY",
  "options.components.dateComparison.validationError": "Info: Select at least 1 and no more than 3 comparison items.",

  "options.components.legendItems.subtitle": "Rows to display",
  "options.components.legendItems.5": "5",
  "options.components.legendItems.10": "10",

  "options.components.viewType.subtitle": "Full data table",
  "options.components.viewType.comparison": "Hide",
  "options.components.viewType.all": "Show",

  "options.components.salesTypeOptions.title": "Sales Type",
  "options.components.salesTypeOptions.oneP": "1P",
  "options.components.salesTypeOptions.twoP": "2P",
  "options.components.salesTypeOptions.threeP": "3P",

  "allDataView.headers.firstColumn": "Top Manufacturers",

  "nav.submenu.configureLabel": "Configure",

  "nav.submenu.manageLabel": "Manage",
  "nav.submenu.accountLabel": "Account",
  "nav.submenu.filters": "Portfolio Filters",
  "nav.submenu.reportSharing": "Report Sharing",
  "nav.submenu.users": "Users",
  "nav.submenu.catalog": "Catalog",
  "nav.submenu.allFilters": "Filters",
  "nav.submenu.help": "Help & API",
  "nav.submenu.profile": "Profile",
  "nav.submenu.classicMarketShare": "Classic Market Share",
  "nav.submenu.logout": "Log out",
  "nav.manageandmore": "Manage and more",
  "nav.beta": "beta",

  "footer.metric.label": "Metric",
  "footer.metric.sales": "Sales",
  "footer.metric.units": "Units",
  "footer.metric.prefixedSales": "{prefix} Sales",
  "footer.metric.prefixedUnits": "{prefix} Units",
  "footer.metric.share": "Share",
  "footer.vat.label": "VAT Sales",
  "footer.vat.yes": "Yes",
  "footer.vat.no": "No",
  "footer.salesType.label": "Sales Type",
  "footer.salesType.1p": "1P",
  "footer.salesType.2p": "2P",
  "footer.salesType.3p": "3P",
  "footer.viewby.label": "View by",
  "footer.viewby.manufacturer": "Manufacturer",
  "footer.viewby.brand": "Brand",
  "footer.viewby.sector": "Product Group",
  "footer.viewby.category": "Category",
  "footer.viewby.subcategory": "Subcategory",
  "footer.viewby.sales_type": "Sales Type",
  "footer.viewby.platform": "Platform",
  "footer.viewby.asin": "ASIN",
  "footer.viewby.rpc": "RPC",
  "footer.viewby.client_product_group": "Product Group",
  "footer.viewby.merchant": "Merchant",
  "footer.period.label": "Period View By",
  "footer.period.week": "Week",
  "footer.period.month": "Month",
  "footer.period.quarter": "Quarter",
  "footer.period.year": "Year",
  "footer.retailer.label": "Retailer",

  "account.profile.changepassword": "Change password",
  "account.profile.updateInfo": "Update Account Information",
  "account.profile.password.reset.send": "Send Password Reset Email",
  "message.success.add": "Added Successfully",
  "message.success.update": "Updated Successfully",

  "account.profile.changepassword.title": "Change Password",
  "account.profile.changepassword.back": "Back",
  "account.profile.changepassword.currentPassword": "Current Password",
  "account.profile.changepassword.newPassword": "New Password",
  "account.profile.changepassword.confirmPassword": "Confirm New Password",
  "account.profile.changepassword.loading": "Loading...",

  "account.profile.changepassword.errorMessage.weakPassword": "Weak password. Please try another combination.",
  "account.profile.changepassword.errorMessage.passwordNotSame": "Passwords are not matching..",
  "account.profile.changepassword.errorMessage.apiError":
    "Sorry, there was an error processing your request. Please contact your account!",
  "account.profile.changepassword.success": "Password changed Successfully!"
};

const MANAGE = {
  "report.scheduler.custom.downloader": "Get Data...",
  "manageUsers.id": "ID",
  "manageUsers.firstName": "First Name",
  "manageUsers.lastName": "Last Name",
  "manageUsers.email": "Email",
  "manageUsers.action": "Action",
  "manageUsers.permissionLevel": "Type",

  "catalog.upload.success": "Your Catalog was uploaded successfully",
  "catalog.upload.loading": "We are checking your Catalog…",
  "catalog.upload.error": "Sorry, there was a problem: {message}",
  "catalog.upload.subError": "{finalMessage}",

  "manageGetDataFilters.name": "Name",
  "manageGetDataFilters.metricsCount": "Metrics Count",
  "manageGetDataFilters.creator": "Creator",
  "manageGetDataFilters.createdDate": "Created Date",
  "manageGetDataFilters.lastUsed": "Last Used",

  "managePortfolioFilters.name": "Name",
  "managePortfolioFilters.creator": "Creator",
  "managePortfolioFilters.createdDate": "Created Date",
  "managePortfolioFilters.lastUsed": "Last Used",

  "manageRatingCardFilters.name": "Name",
  "manageRatingCardFilters.creator": "Creator",
  "manageRatingCardFilters.createdDate": "Created",
  "manageRatingCardFilters.lastUsed": "Last Used",
  "manageRatingCardFilters.actions": " ",

  "forms.manageRatingCardFilters.inboundFreight": "Inbound Freight",
  "forms.manageRatingCardFilters.cardName": "New Card Name",
  "forms.manageRatingCardFilters.discounts": "Discounts",
  "forms.manageRatingCardFilters.marketingCosts": "Marketing and CS Costs",
  "forms.manageRatingCardFilters.damageCOGS": "Damage COGS",
  "forms.manageRatingCardFilters.fixedCosts": "Fixed Costs",
  "forms.manageRatingCardFilters.accruals": "Accruals",
  "forms.manageRatingCardFilters.incremental": "Incremental",
  "forms.manageRatingCardFilters.displayAds": "Display Ads",
  "forms.manageRatingCardFilters.vendorAllowance": "Vendor Allowance",
  "forms.manageRatingCardFilters.quickPayDiscount": "Quick Pay Discount",
  "forms.manageRatingCardFilters.yes": "Yes",
  "forms.manageRatingCardFilters.no": "No",
  "forms.manageRatingCardFilters.setClientDefault": "Set as Client Default"
};

const MY_BUSINESS = {
  "section.myForecast": "My Forecast",
  "section.myForecast.current": "Current",
  "section.mySales.title": "My Sales",
  "section.mySales.byPeriod":
    "{rollup, select, week {Weekly} month {Monthly} quarter {Quarterly} year {Yearly} other {Monthly}}",
  "section.mySales.headlineFigures": "Headline Figures",
  "section.mySales.units": "{prefix} Units",
  "section.mySales.sales": "{prefix} Sales",
  "section.mySales.sales.legendTitle": "Sales Type",
  "section.mySales.weekOnWeek": "WoW",
  "section.mySales.monthOnMonth": "MoM",
  "section.mySales.quarterOnQuarter": "QoQ",
  "section.mySales.yearOnYear": "YoY",
  "section.mySales.total": "Total",
  "section.mySales.showAccumulatedAsc": "Show Accrued (asc)",
  "section.mySales.showAccumulatedDesc": "Show Accrued (desc)",
  "section.mySales.showByPeriod":
    "Show {rollup, select, week {Weekly} month {Monthly} quarter {Quarterly} year {Yearly} other {Monthly}}",

  "section.mySalesDrivers": "{titlePrefix} Sales Drivers",
  "section.mySalesDrivers.weekOnWeek": "WoW",
  "section.mySalesDrivers.monthOnMonth": "MoM",
  "section.mySalesDrivers.quarterOnQuarter": "QoQ",
  "section.mySalesDrivers.yearOnYear": "YoY",
  "section.mySalesDrivers.changePercent": "Change %",
  "section.mySalesDrivers.pageViews": "Page views",
  "section.mySalesDrivers.xPageViews": "{count} Page views",
  "section.mySalesDrivers.xUnits": "{count} Units",
  "section.mySalesDrivers.topTable.Footer.ConversionButtonLabel": "Conversion",
  "section.mySalesDrivers.topTable.Footer.TrafficButtonLabel": "Traffic",
  "section.mySalesDrivers.group.sales": "Sales",
  "section.mySalesDrivers.group.units": "Units",
  "section.mySalesDrivers.group.conversion": "Conversion",
  "section.mySalesDrivers.group.traffic": "Traffic",
  "section.mySalesDrivers.group.share": "Share",
  "section.mySalesDrivers.group.unitsShare": "Share Units",

  "section.mySalesDrivers.topMoversPerformers.table.headers.conversionButton": "Conversion",
  "section.mySalesDrivers.topMoversPerformers.table.headers.movers": "Movers",
  "section.mySalesDrivers.topMoversPerformers.table.headers.performers": "Leaders",
  "section.mySalesDrivers.topMoversPerformers.table.headers.top": "Top",
  "section.mySalesDrivers.topMoversPerformers.table.headers.trafficButton": "Traffic",

  "section.mySalesDrivers.topMoversPerformers.table.headers.sales": "Sales",
  "section.mySalesDrivers.topMoversPerformers.table.headers.periodOnPeriod": "PoP",
  "section.mySalesDrivers.topMoversPerformers.table.headers.conversion": "Conv.",

  "section.mySalesDrivers.topMoversPerformers.table.headers.traffic": "Page",
  "section.mySalesDrivers.topMoversPerformers.table.headers.traffic.footer": "views",

  "section.mySalesDrivers.showActuals": "Show Actuals",
  "section.mySalesDrivers.showTrends": "Show Trends",
  "section.mySalesDrivers.periodTrend": "Period Trend",

  "section.myShare": "My Accrued Share — all Sales Types",
  "section.myShare.share": "{prefix} Share",
  "section.myShare.weekOnWeek": "WoW",
  "section.myShare.monthOnMonth": "MoM",
  "section.myShare.quarterOnQuarter": "QoQ",
  "section.myShare.yearOnYear": "YoY",
  "section.myShare.topPerformers": "Leaders",
  "section.myShare.change": "Change",
  "section.myShare.periodOnPeriod": "PoP",
  "section.myShare.topMovers": "Movers",
  "section.myShare.alldata.tableHeaders.weekLabel": "Week {number}"
};

const COMPETITION = {
  "competition.share.sectionTitle": "Share",
  "competition.share.table.total.dimension": "Total",
  "competition.share.table.headers.title": "Top Manufacturers",
  "competition.share.table.headers.share": "Share",
  "competition.share.table.headers.weekOnWeek": "WoW",
  "competition.share.table.headers.monthOnMonth": "MoM",
  "competition.share.table.headers.quarterOnQuarter": "QoQ",
  "competition.share.table.headers.yearOnYear": "YoY",

  "competition.share.topMovers.title": "Movers",
  "competition.share.topMovers.table.headers.share": "Share",
  "competition.share.topMovers.table.headers.periodOnPeriod": "PoP",

  "competition.share.visuals.graphToggle.label.showAccumulated": "Show Accrued",
  "competition.share.visuals.graphToggle.label.showWeekly": "Show Weekly",

  "competition.sales.sectionTitle": "Sales",
  "competition.sales.visuals.graphToggle.label.showAccumulated": "Show Accrued",
  "competition.sales.visuals.graphToggle.label.showWeekly": "Show Weekly",

  "competition.salesSummary.sectionTitle": "Sales Summary",
  "competition.salesSummary.download.asShown": "As shown",
  "competition.salesSummary.download.allData": "All data",

  "competition.salesSummary.options.period": "Period - Select Any",
  "competition.salesSummary.options.metrics": "Metrics - Select Any",
  "competition.salesSummary.options.rows": "Rows to Display",

  "competition.salesSummary.options.period_1_week": "1W",
  "competition.salesSummary.options.period_4_weeks": "4W",
  "competition.salesSummary.options.period_13_weeks": "13W",
  "competition.salesSummary.options.period_26_weeks": "26W",
  "competition.salesSummary.options.period_52_weeks": "52W",
  "competition.salesSummary.options.period_ytd": "YTD",

  "competition.salesSummary.options.sales": "Sales",
  "competition.salesSummary.options.share": "Share",
  "competition.salesSummary.options.yoy_sales": "YoY Sales",
  "competition.salesSummary.options.yoy_share": "YoY Share",
  "competition.salesSummary.options.units": "Units",
  "competition.salesSummary.options.yoy_units": "YoY Units",

  "competition.salesSummary.options.10": "10",
  "competition.salesSummary.options.20": "20",
  "competition.salesSummary.options.30": "30",
  "competition.salesSummary.options.50": "50",

  "competition.sales.visuals.graphToggle.label.showAccumulatedAsc": "Show Accrued (asc)",
  "competition.sales.visuals.graphToggle.label.showAccumulatedDesc": "Show Accrued (desc)",

  "competition.sales.table.total.dimension": "Total",
  "competition.sales.table.headers.title.manufacturer": "Manufacturers",
  "competition.sales.table.headers.title.brand": "Brands",
  "competition.sales.table.headers.title.sector": "Product Groups",
  "competition.sales.table.headers.title.product_group": "Product Groups",
  "competition.sales.table.headers.title.category": "Categories",
  "competition.sales.table.headers.title.subcategory": "Subcategories",
  "competition.sales.table.headers.title.sales_type": "Sales Types",
  "competition.sales.table.headers.title.asin": "ASINs",
  "competition.sales.table.headers.title.rpc": "RPCs",
  "competition.sales.table.headers.sales": "Sales",
  "competition.sales.table.headers.weekOnWeek": "WoW",
  "competition.sales.table.headers.monthOnMonth": "MoM",
  "competition.sales.table.headers.quarterOnQuarter": "QoQ",
  "competition.sales.table.headers.yearOnYear": "YoY"
};

const FILTERS = {
  "dateFilters.custom": "Custom…",
  "dateFilters.this": "Latest",
  "dateFilters.last": "Previous",
  "dateFilters.today": "Today",
  "dateFilters.yesterday": "Yesterday",
  "dateFilters.week": "Week",
  "dateFilters.fourWeeksToDate": "4 Wks",
  "dateFilters.thirteenWeeksToDate": "13 Wks",
  "dateFilters.twentySixWeeksToDate": "26 Wks",
  "dateFilters.fiftyTwoWeeksToDate": "52 Wks",
  "dateFilters.month": "Month",
  "dateFilters.quarter": "Quarter",
  "dateFilters.year": "Year",
  "dateFilters.week.comingSoon": "Will be available when data is released",

  "portfolioFilters.custom": "Custom…",
  "portfolioFilters.selectSavedFilter": "Select Saved Filter",
  "portfolioFilters.filterName": "Filter name",
  "portfolioFilters.filterCreator": "Creator",
  "portfolioFilters.countBrands": "{n, number, ::compact-short} {n, plural, one {Brand} other {Brands}}",
  "portfolioFilters.countCategories": "{n, number, ::compact-short} {n, plural, one {Category} other {Categories}}",
  "portfolioFilters.countAttributes": "{n, number, ::compact-short} {n, plural, one {Attribute} other {Attributes}}",
  "portfolioFilters.countASINs": "{n, number, ::compact-short .#/w} {n, plural, one {ASIN} other {ASINs}}",
  "portfolioFilters.countRPCs": "{n, number, ::compact-short .#/w} {n, plural, one {RPC} other {RPCs}}",
  "portfolioFilters.newSavedFilter": "New Portfolio Filter…",
  "portfolioFilters.new": "New…",
  "portfolioFilters.enterName": "Enter a filter name",

  "customPortfolioFilters.mode.hierarchy": "Hierarchy",
  "customPortfolioFilters.mode.flat": "Flat",
  "customPortfolioFilters.name.custom": "Custom",
  "customPortfolioFilters.name.edited": "{name} <c>(edited)</c>",

  "customPortfolioFilters.menu.heading": "Filter and Configure",
  "customPortfolioFilters.menu.filters": "Select by",
  "customPortfolioFilters.menu.configure": "Configure",
  "customPortfolioFilters.menu.manufacturersAndBrands": "Manufacturers and Brands",
  "customPortfolioFilters.menu.categories": "Categories",
  "customPortfolioFilters.menu.attributes": "Attributes",
  "customPortfolioFilters.menu.retailersAndSegments": "Retailers and Segments",
  "customPortfolioFilters.menu.internalSegments": "Internal Segments",
  "customPortfolioFilters.menu.viewBy": "View by",
  "customPortfolioFilters.menu.viewBy.manufacturer": "Manufacturer",
  "customPortfolioFilters.menu.platform": "Platform",

  "customPortfolioFilters.browse.manufacturer": "Manufacturer",
  "customPortfolioFilters.browse.brand": "Brand",
  "customPortfolioFilters.browse.sector": "Product Group",
  "customPortfolioFilters.browse.category": "Category",
  "customPortfolioFilters.browse.subcategory": "Subcategory",
  "customPortfolioFilters.browse.custom_attribute": "Attribute",
  "customPortfolioFilters.browse.custom_attribute_value": "Values",
  "customPortfolioFilters.browse.asin.plural": "ASINs",
  "customPortfolioFilters.browse.asin": "ASIN",
  "customPortfolioFilters.browse.asin.extra": "Item",
  "customPortfolioFilters.browse.salesType": "Sales Type",
  "customPortfolioFilters.browse.merchant": "Merchant",
  "customPortfolioFilters.browse.platform": "Platform",
  "customPortfolioFilters.browse.selectAll": "Select all",
  "customPortfolioFilters.browse.selectNone": "Select none",
  "customPortfolioFilters.browse.pantry": "Pantry",
  "customPortfolioFilters.browse.com": ".com",
  "customPortfolioFilters.browse.core": "Core",
  "customPortfolioFilters.browse.fresh": "Fresh",

  "customPortfolioFilters.selection.progressText":
    "Selected {s, plural,=0 {} 1 {} other {#}} of {t, plural,=0 {No} other {#}}",
  "customPortfolioFilters.selection.heading": "Your Selection",
  "customPortfolioFilters.selection.ASINCount":
    "{n, plural, =0 {No ASINs} one {1 ASIN} other {{n, number} ASINs}} selected",
  "customPortfolioFilters.selection.RPCCount": "{n, plural, =0 {No RPC} one {1 RPC} other {{n, number} RPCs}} selected",
  "customPortfolioFilters.selection.ASINCount.loading": "Loading ASINs",
  "customPortfolioFilters.selection.RPCCount.loading": "Loading RPCs",
  "customPortfolioFilters.selection.manufacturers": "Manufacturers",
  "customPortfolioFilters.selection.brands": "Brands",
  "customPortfolioFilters.selection.sectors": "Product Group",
  "customPortfolioFilters.selection.categories": "Categories",
  "customPortfolioFilters.selection.subCategories": "Subcategories",
  "customPortfolioFilters.selection.attributes": "Attributes",
  "customPortfolioFilters.selection.save": "Save…",
  "customPortfolioFilters.selection.ASIN.plural": "ASINs",
  "customPortfolioFilters.selection.RPC.plural": "RPCs",

  "customPortfolioFilters.save.heading": "Save",
  "customPortfolioFilters.save.update": "Update",
  "customPortfolioFilters.save.saveAsNew": "Save as new…",
  "customPortfolioFilters.save.filterName": "Filter name",

  // NOTE: these must match PortfolioItemTypes
  "customPortfolioFilters.search.input.placeholder": "Filters and attributes",
  "customPortfolioFilters.search.in.manufacturer": "in Manufacturers",
  "customPortfolioFilters.search.in.brand": "in Brands",
  "customPortfolioFilters.search.in.sector": "in Product Groups",
  "customPortfolioFilters.search.in.category": "in Categories",
  "customPortfolioFilters.search.in.subcategory": "in Subcategories",
  "customPortfolioFilters.search.in.custom_attribute": "in Attributes",
  "customPortfolioFilters.search.in.custom_attribute_value": "in Attributes / Values",
  "customPortfolioFilters.search.asin.in.brand": "in Brand: {brand}",
  "customPortfolioFilters.search.asin.in.subcategory": "in Subcategory: {subcategory}",
  "customPortfolioFilters.search.asin.in.custom_attribute_value":
    "in Attribute: {custom_attribute} / {custom_attribute_value}",
  "customPortfolioFilters.search.noResults": "No items found",

  "customPortfolioFilters.searchAsin.add": "Add",
  "customPortfolioFilters.searchAsin.remove": "Remove",
  "customPortfolioFilters.searchAsin.notFound": "Not Found",
  "customPortfolioFilters.searchAsin.selectResults": "Select {selectedCount} of {total} results"
};

const FORMS = {
  "forms.customDownload.title": "Get Data",
  "forms.customDownload.subtitle.fileAndFormat": "File and format",
  "forms.customDownload.subtitle.timeframe": "Select Period and Portfolio Filters",
  "forms.customDownload.subtitle.portfolio": "Portfolio",
  "forms.customDownload.subtitle.metrics": "Metrics",
  "forms.customDownload.components.apiFeed.title": "API Automatic Data Feed",
  "forms.customDownload.components.editFileName.title": "Edit file name",
  "forms.customDownload.components.period.select": "Select Period",
  "forms.customDownload.components.period.default": "Select",
  "forms.customDownload.components.dataBy.default": "Select",
  "forms.customDownload.components.period.month": "Latest Month",
  "forms.customDownload.components.period.quarter": "Latest Quarter",
  "forms.customDownload.components.period.year": "Latest Year",
  "forms.customDownload.components.period.to_date_4": "Latest 4 Weeks",
  "forms.customDownload.components.period.to_date_13_w": "Latest 13 Weeks",
  "forms.customDownload.components.period.to_date_26_w": "Latest 26 Weeks",
  "forms.customDownload.components.period.to_date_52_w": "Latest 52 Weeks",
  "forms.customDownload.components.period.to_date_13": "Latest 13 Months",
  "forms.customDownload.components.period.to_date_25": "Latest 25 Months",
  "forms.customDownload.components.period.toDate4Week": "Latest 4 Weeks",
  "forms.customDownload.components.period.toDate13Week": "Latest 13 Weeks",
  "forms.customDownload.components.period.toDate26Week": "Latest 26 Weeks",
  "forms.customDownload.components.period.toDate52Week": "Latest 52 Weeks",
  "forms.customDownload.components.period.toDate13": "Latest 13 Months",
  "forms.customDownload.components.period.toDate25": "Latest 25 Months",
  "forms.customDownload.components.period.custom": "Custom",
  "forms.customDownload.components.period.pleaseSelect": "Please select a date range",
  "forms.customDownload.components.viewBy.select": "Select View By",

  "forms.customDownload.components.granularity.title": "Select granularity",
  "forms.customDownload.components.granularity.daily": "Daily",
  "forms.customDownload.components.granularity.weekly": "Weekly",
  "forms.customDownload.components.granularity.monthly": "Monthly",
  "forms.customDownload.components.granularity.quarterly": "Quarterly",
  "forms.customDownload.components.dataBy.title": "Interval",
  "forms.customDownload.components.dataBy.day": "Day",
  "forms.customDownload.components.dataBy.week": "Week",
  "forms.customDownload.components.dataBy.month": "Month",
  "forms.customDownload.components.dataBy.quarter": "Quarter",

  "forms.customDownload.components.savedFilter.select": "Select Portfolio Filter",

  "forms.customDownload.components.savedFilter.allData": "All Data",
  "forms.customDownload.components.savedFilter.currentApplied": "Current applied",
  "forms.customDownload.components.savedFilter.noFilter": "No Filter Selected",

  "forms.customDownload.components.metrics.title": "Select",
  "forms.customDownload.components.metrics.TRAFFIC": "Traffic",
  "forms.customDownload.components.metrics.CONVERSION": "Conversion",
  "forms.customDownload.components.metrics.PRODUCT": "Product",
  "forms.customDownload.components.metrics.FIRST_PARTY": "1P",
  "forms.customDownload.components.metrics.SECOND_PARTY": "2P",
  "forms.customDownload.components.metrics.THIRD_PARTY": "3P",
  "forms.customDownload.components.metrics.TOTALS": "Totals",
  "forms.customDownload.components.metrics.TOTALS_X": "Totals (1p & 3p)",
  "forms.customDownload.components.metrics.OPERATIONS": "Operations",
  "forms.customDownload.components.metrics.ATTRIBUTE": "Attribute",
  "forms.customDownload.components.metrics.CUSTOM_FIELDS": "Custom Fields",
  "forms.customDownload.components.metrics.INTERVAL": "Period attributes",
  "forms.customDownload.components.metrics.VIEW_BY": "Portfolio attributes",
  "forms.customDownload.components.metrics.CUSTOM_ATTRIBUTES": "Portfolio custom attributes",

  "forms.customDownload.components.sendReport": "Send Report",
  "forms.customDownload.components.share": "Share and Download",
  "forms.customDownload.components.download": "Download",

  "forms.manageUser.create": "New User",
  "forms.manageUser.title": "Create New User",
  "forms.manageUser.saveChanges": "Save Changes ",
  "forms.manageUser.components.username": "Username",
  "forms.manageUser.components.email": "Email",
  "forms.manageUser.components.firstname": "First Name",
  "forms.manageUser.components.lastname": "Last Name",
  "forms.manageUser.components.usertype": "User Type",
  "forms.manageUser.components.usertype.select": "Select",
  "forms.manageUser.components.usertype.reports": "Reports User",
  "forms.manageUser.components.usertype.catalog": "Client Catalog Admin",
  "forms.manageUser.components.usertype.clientadmin": "Client Admin",
  "forms.manageUser.components.usertype.ems": "Edge Market Share Admin",
  "forms.manageUser.components.usertype.root": "Super Admin (ROOT)",
  "forms.manageUser.components.release": "On Data Release",
  "forms.manageUser.components.release.notify": "Notify",
  "forms.manageUser.components.release.do_not_notify": "Do not notify",
  "forms.manageUser.components.sendSetPasswordMail": "Send user an email to set up password",
  "forms.manageUser.components.sendSetPasswordMail.yes": "Yes",
  "forms.manageUser.components.sendSetPasswordMail.no": "No"
};

const HELP = {
  "help.getHelp.button": "Get Help..."
};

const SCORECARD = {
  "search": "Search",
  "search.defaultWatchlist": "Default Watchlist Name",
  "search.scorecardSearch": "Search Frequency Rank",
  "search.periodRollup": "Period Rollup",
  "search.searchTerm": "Search Term",
  "search.department": "Department",
  "scorecard.promotions": `The logic has been updated on how unique promotions are calculated starting March 1, 2020. This may
  result in an increase in promotions and sales on promotion`,
  "scorecard.ratingsandreviews": `upon investigation, the competitor reviews trend line is not meeting our data quality expectations.
  We removed the trendline until we can deliver a representative metric for your competitor reviews.`,
  "search.clickShare.heading": "% Clicks Share",
  "search.conversionShare.heading": "% Conversions Share",
  "search.clickShare.headingv2": "Clicks Share - {searchTerm}",
  "search.conversionShare.headingv2": "Conversions Share - {searchTerm}",
  "search.changesAlert": "Please apply to update the report",
  "search.loadGraphs": "Please select search terms",
  "search.mostImportantKeyterms": "Important Keywords",
  "search.seasonalCalendar": "Seasonal Calendar",
  "search.acDecTrends": "Accelerating & Declining Trends",
  "search.shortTerm": "Short Term",
  "search.longTerm": "Long Term",
  "search.growth": "GROWTH",
  "search.decline": "DECLINE",
  "search.watchlist": "Watchlist",
  "search.watchlists": "Watchlists",
  "search.watchlistNames": "Watchlist Name",
  "search.insights.relevant": "Relevant ASINs",
  "seasonality.seasonality": "Seasonality",
  "seasonality.upcomingSeasonality": "Upcoming Seasonality",
  "seasonality.list": "LIST",
  "seasonality.trends": "TRENDS",
  "seasonality.weekly": "WEEKLY",
  "seasonality.monthly": "MONTHLY",
  "seasonality.all": "ALL",
  "seasonality.starting": "STARTING",
  "seasonality.active": "ACTIVE",
  "seasonality.ending": "ENDING",
  "seasonality.startingaAndActive": "STARTING + ACTIVE",
  "search.toWatchlist": "to watchlist",
  "advertising.spendAnalysis.title": "ASIN Spend Analysis",
  "advertising.queryMonitoring": "Query Monitoring",
  "advertising.prizeAnalysis": "Prize Analysis",
  "advertising.rankVsCosts": "Keywords Rank vs Costs",
  "advertising.spendVsRank.title": "KEYWORDS SPEND VS RANK",
  "advertising.spendVsRank.rank": "RANK",
  "advertising.spendVsRank.cost": "COST",
  "advertising.SpendvsShare.overTime": "Sales and Spend Over Time",
  "advertising.spendVsShare.title": "Spend Vs Share",
  "advertising.scorecardTerms.title": "Search Term Scorecard ",
  "prizeAnalysis.all": "ALL",
  "prizeAnalysis.top30": "TOP 30",
  "prizeAnalysis.middle30": "MIDDLE 30",
  "prizeAnalysis.bottom30": "BOTTOM 30",
  "prizeAnalysis.title": "AMS SPEND VS CATEGORY SPEND BY OWN SHARE"
};

const ALERT_MESSAGES = {
  "bottomFilter.retailerItem.disabledInPage": "{retailerName} data is not available in this page.",
  "tempBanner.MVSalesSummary.message": "You can now find the Sales Summary Report at the bottom of this page!",
  "tempBanner.PRSalesSummary.message": "You can now find the Sales Summary Report at the bottom of Market View page!"
};

const LOGIN = {
  "login.copyright": "© 2017-{currentYear} Edge by Ascential Limited. All rights reserved"
};

const GLOBAL_FILTERS = {
  "globalFilters.retailer.mainLabel": "Retailer"
};

const RESET_PASSWORD = {
  "resetPassword.title": "Reset Password",
  "resetPassword.passwordsAreNotEqual.errorMessage": "Passwords are not matching.",
  "resetPassword.passwordIsNotStrong.errorMessage": "Weak password. Please try another combination.",
  "resetPassword.apiError": "Sorry, there was an error processing your reset password request. Please contact",
  "resetPassword.supportLink": "Edge Marketshare Share Support",
  "resetPassword.passwordChangedSuccessfully": "Password successfully reset. Please log in.",
  "resetPassword.backToLogin.text": "Back to Log In"
};

const MESSAGES = {
  ...COMMON_TRANSLATIONS,
  ...MANAGE,
  ...MY_BUSINESS,
  ...COMPETITION,
  ...FILTERS,
  ...FORMS,
  ...HELP,
  ...SCORECARD,
  ...ALERT_MESSAGES,
  ...LOGIN,
  ...GLOBAL_FILTERS,
  ...RESET_PASSWORD
};

export default MESSAGES;
