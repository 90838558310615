import React from "react";
import {
  differenceInCalendarWeeks,
  differenceInCalendarMonths,
  differenceInCalendarQuarters,
  differenceInCalendarYears,
  Interval,
  isSameDay,
  isSameMonth,
  isSameQuarter,
  isSameWeek,
  isSameYear,
  lastDayOfMonth,
  lastDayOfQuarter,
  lastDayOfWeek,
  lastDayOfYear,
  setDate,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear
} from "date-fns";
import { DATE_RANGE_TYPES } from "units/market-share/constants/date";
import { getDevFeature } from "lib/stores/devFeatures";
import { DateRangeOptions, DateRange, PresetPeriod, PresetData } from "lib/types/dateRange";

export const isDateRangeOnDay = (now: Date | number, { start, end }: Interval) =>
  isSameDay(start, end) && isSameDay(start, now);

export const isDateRangeOnWeek = (now: Date | number, { start, end }: Interval, options: DateRangeOptions) =>
  isSameWeek(start, end, options) &&
  isSameWeek(start, now, options) &&
  isSameDay(start, startOfWeek(start, options)) &&
  isSameDay(end, lastDayOfWeek(end, options));

export const isDateRangeOnMonth = (now: Date | number, { start, end }: Interval) =>
  isSameMonth(start, end) &&
  isSameMonth(start, now) &&
  isSameDay(start, startOfMonth(start)) &&
  isSameDay(end, lastDayOfMonth(end));

export const isDateRangeOnQuarter = (now: Date | number, { start, end }: Interval, options: DateRangeOptions) =>
  isSameQuarter(start, now) &&
  isSameQuarter(start, startOfWeek(end, options)) &&
  isSameDay(start, startOfWeek(setDate(startOfQuarter(now), options.firstWeekContainsDate), options)) &&
  isSameDay(end, lastDayOfWeek(lastDayOfQuarter(now), options));

export const isDateRangeOnYear = (now: Date | number, { start, end }: Interval) =>
  isSameYear(start, end) &&
  isSameYear(start, now) &&
  isSameDay(start, startOfYear(start)) &&
  isSameDay(end, lastDayOfYear(end));

export const periodForDateRange = (
  now: Date | number,
  range: Interval,
  options: DateRangeOptions,
  selection: string,
  presetData?: PresetData
) => {
  if ((!range.start && !range.end) || selection === "THIS_WEEK" || selection === "LAST_WEEK") {
    return null;
  }
  if (selection !== "NONE") {
    return selection;
  }

  return null;
};

export const usePeriod = (
  { start, end }: Interval,
  options: DateRangeOptions,
  selection: string,
  presetData?: PresetData
) =>
  React.useMemo(
    () => periodForDateRange(Date.now(), { start, end }, options, selection, presetData),
    [start, end, options, selection, presetData]
  );

export const rollupForDateRange = ({ start, end }: Interval, options: DateRangeOptions) => {
  const weeks = Math.abs(differenceInCalendarWeeks(end, start, options)) + 1;
  const month = Math.abs(differenceInCalendarMonths(end, start));

  if (month > 52) return DATE_RANGE_TYPES.QUARTER;
  if (weeks <= 53) return DATE_RANGE_TYPES.WEEK;
  if (weeks >= 54) return DATE_RANGE_TYPES.MONTH;

  return DATE_RANGE_TYPES.QUARTER;
};

export const useGetDisabledRoullups = ({ start, end }: Interval, options: DateRangeOptions) => {
  const weeks = Math.abs(differenceInCalendarWeeks(end, start, options)) + 1;
  const months = Math.abs(differenceInCalendarMonths(end, start));
  const quarters = differenceInCalendarQuarters(end, start);
  const years = differenceInCalendarYears(end, start);

  const disabledPeriodRollups: string[] = [];
  if (weeks < 1 || weeks > 53) disabledPeriodRollups.push(DATE_RANGE_TYPES.WEEK);
  if (months < 1 || months > 53) disabledPeriodRollups.push(DATE_RANGE_TYPES.MONTH);
  if (quarters < 1 || quarters > 53) disabledPeriodRollups.push(DATE_RANGE_TYPES.QUARTER);
  if (years < 1 || years > 53) disabledPeriodRollups.push(DATE_RANGE_TYPES.YEAR);

  return disabledPeriodRollups;
};
