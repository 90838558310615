import React from "react";

import { ACTION_TYPES } from "../constants";
import useRootState from "./useRootState";

const useSteppers = (id) => {
  const { state, dispatch } = useRootState();

  const data = state.steppers[id];
  const setValue = React.useCallback(
    (value) =>
      dispatch({
        type: ACTION_TYPES.STEPPERS.SET_VALUE,
        payload: { id, value }
      }),
    [id, dispatch]
  );

  return React.useMemo(
    () => ({
      state: data ?? 0,
      setValue
    }),
    [data, setValue]
  );
};

export default useSteppers;
