import React from "react";

import { RETAILER_RULES_CHECKLIST } from "lib/constants/retailers";
import { ROUTE } from "lib/constants/routes";
import { getSelectedRetailerId } from "lib/stores/retailerSubscriptions";

// here a new checklist based on selected retailer can be accessed
const useRetailerSpecificPageRules = () => {
  const retailerId = getSelectedRetailerId();

  return {
    [ROUTE.MyBusiness.Constant]: {
      mySales: {
        hideConversionView: RETAILER_RULES_CHECKLIST.hideConversionView[retailerId],
        hideTrafficView: RETAILER_RULES_CHECKLIST.hideTrafficView[retailerId]
      },
      myBrandSales: {
        top20ItemsTitle: RETAILER_RULES_CHECKLIST.top20ItemsTitle[retailerId]
      },
      my1PSalesDrivers: {
        hideConversionView: RETAILER_RULES_CHECKLIST.hideConversionView[retailerId],
        hideTrafficView: RETAILER_RULES_CHECKLIST.hideTrafficView[retailerId],
        titlePrefix: RETAILER_RULES_CHECKLIST.driversTitlePrefix[retailerId],
        hideTopMovers: RETAILER_RULES_CHECKLIST.hideSalesDriversTopMovers[retailerId]
      }
    },
    [ROUTE.MarketView.Constant]: {
      retailerSpecificUniqueId: RETAILER_RULES_CHECKLIST.retailerSpecificUniqueId[retailerId]
    },
    [ROUTE.Profile.Constant]: {
      retailerSpecificUniqueId: RETAILER_RULES_CHECKLIST.retailerSpecificUniqueId[retailerId]
    },
    [ROUTE.Filters.Constant]: {
      retailerSpecificUniqueId: RETAILER_RULES_CHECKLIST.retailerSpecificUniqueId[retailerId]
    },
    common: {
      defaultGetDataMatrices: RETAILER_RULES_CHECKLIST.defaultMatrices[retailerId],
      showCustomGetDataFields: RETAILER_RULES_CHECKLIST.showCustomFields[retailerId],
      prefixTotalInHeader: RETAILER_RULES_CHECKLIST.prefixToHeader[retailerId],
      showSalesTypes: RETAILER_RULES_CHECKLIST.showSalesTypes[retailerId]
    }
  };
};

export default useRetailerSpecificPageRules;
