import { createStore } from "effector";

import { FeatureFlags } from "lib/types/featureFlags";

import { persistLocal } from "./utils/storage";
import { app } from "./app";

import { FEATURE_FLAGS_STORED_KEY } from "lib/constants/featureFlags";

export const setFeatureFlags = app.createEvent<FeatureFlags>();
export const $featureFlags = app.createStore({}).on(setFeatureFlags, (state, featureFlags) => ({
  ...state,
  ...featureFlags
}));

const $store = createStore<FeatureFlags>({}, { name: FEATURE_FLAGS_STORED_KEY });
$store.on(setFeatureFlags, (state, payload) => ({ ...state, ...payload }));
persistLocal<FeatureFlags>($store);

export const getFeatureFlagsState = $featureFlags.getState;
export const getFeatureFlag = (flagName: string): boolean => getFeatureFlagsState()[flagName];
