/* eslint-disable @typescript-eslint/dot-notation */
import axios, { AxiosInstance } from "axios";
import { setupCache } from "axios-cache-adapter";

import clearStorage from "lib/utilities/clearStorage";
import { Debug } from "lib/debug";

import getOcrUserUuid from "units/market-share/utility/getOcrUserUuid";

const cache = setupCache({
  maxAge: 60 * 60 * 1000,
  exclude: { query: false }
});

export const axiosInstance = axios.create({
  adapter: cache.adapter
});

export const applyInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (req) => {
      const loggedUuid = getOcrUserUuid();
      if (loggedUuid) req.headers.common["OCR-USER-ID"] = loggedUuid;
      return req;
    },
    (err) => {
      return err;
    }
  );

  instance.interceptors.response.use(
    (res) => {
      const method = (res.config.method || "GET").toUpperCase();
      const path = (res.config?.url || "")
        .replace(/\/clients\/[^/]*/, "/clients/{client}")
        .replace(/\/users\/[^/]*/, "/users/{user}")
        .replace(/^(https?:\/\/)?[^/]*\//, "");
      Debug(`axios: ${method} /${path}`, "hsl(220, 60%, 40%)")(res.status, res.data);
      return res;
    },
    (err) => {
      if (
        err &&
        err.response &&
        [401].includes(err.response.status) &&
        window.location.pathname !== "/reset-password"
      ) {
        window.Intercom("shutdown");
        clearStorage();
        window.location.replace("/");
      }

      return axios.isCancel(err) ? err : Promise.reject(err);
    }
  );

  const ENDPOINTS_WITH_DEPENDENCIES = ["/profitability_rate", "/search_watchlists"];
  const ENDPOINT_DEPENDENCIES = {
    "/profitability_rate": [
      "/profitability_rate",
      "/reports/profitability",
      "/reports/sales_to_crap_items",
      "/reports/profitability_heatmap_data"
    ],
    "/search_watchlists": [
      "/search_frequency/watchlist",
      "search_watchlists",
      "key_search_terms",
      "upcoming_seasonal_search_terms",
      "search_term_seasonality_items"
    ]
  };

  let URLsToByPass = [] as string[];
  const CRUD_METHODS = ["post", "put", "patch", "delete"];

  instance.interceptors.request.use((req) => {
    if (CRUD_METHODS.includes(req?.method?.toLowerCase() as string)) {
      URLsToByPass.push(req?.url as string);
      const endpointsWithDependencies = ENDPOINTS_WITH_DEPENDENCIES.find((e) => req?.url?.includes(e));
      if (endpointsWithDependencies) {
        URLsToByPass = [...URLsToByPass, ...ENDPOINT_DEPENDENCIES[endpointsWithDependencies]];
      }
    }
    return req;
  });

  instance.interceptors.request.use((req) => {
    const isGetMethod = req?.method?.toLowerCase() === "get";
    const cacheArrayIncludesURL = URLsToByPass.some(
      (url) => req?.url?.includes(url as string) || url.includes(req?.url as string)
    );
    if (isGetMethod && cacheArrayIncludesURL) {
      const storeKeyArray = Object.keys(cache.store["store"]).filter(
        (key) => key.includes(req?.url as string) || req?.url?.includes(key)
      );
      storeKeyArray.forEach((storeKey) => delete cache.store["store"][storeKey]);
      URLsToByPass = URLsToByPass.filter(
        (url) => !req?.url?.includes(url as string) && !url.includes(req?.url as string)
      );
    }
    return req;
  });
};

applyInterceptors(axiosInstance);
