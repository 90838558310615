import { CUSTOM_DOWNLOAD_METRICS } from "lib/pages/ReportScheduler/constants";
import { CUSTOM_DOWNLOAD_METRICS_WM } from "lib/pages/ReportScheduler/constants/customDownoadWMMetrics";
import { ROUTE } from "./routes";

export const RETAILER_IDS = {
  AMAZON: 1,
  WALMART: 2
};

export const RETAILER_IDS_LENGTH = {
  ASIN: 10,
  RPC: 9
};

// for each of cosmetic change we can add new rule for each retailer in this checklist
export const RETAILER_RULES_CHECKLIST = {
  top20ItemsTitle: {
    [RETAILER_IDS.AMAZON]: "ASIN",
    [RETAILER_IDS.WALMART]: "Item"
  },
  hideConversionView: {
    [RETAILER_IDS.AMAZON]: false,
    [RETAILER_IDS.WALMART]: true
  },
  hideTrafficView: {
    [RETAILER_IDS.AMAZON]: false,
    [RETAILER_IDS.WALMART]: true
  },
  retailerSpecificUniqueId: {
    [RETAILER_IDS.AMAZON]: "ASIN",
    [RETAILER_IDS.WALMART]: "RPC"
  },
  defaultMatrices: {
    [RETAILER_IDS.AMAZON]: CUSTOM_DOWNLOAD_METRICS,
    [RETAILER_IDS.WALMART]: CUSTOM_DOWNLOAD_METRICS_WM
  },
  showCustomFields: {
    [RETAILER_IDS.AMAZON]: true,
    [RETAILER_IDS.WALMART]: false
  },
  prefixToHeader: {
    [RETAILER_IDS.AMAZON]: "",
    [RETAILER_IDS.WALMART]: "Total"
  },
  driversTitlePrefix: {
    [RETAILER_IDS.AMAZON]: "My 1P",
    [RETAILER_IDS.WALMART]: "Total"
  },
  showSalesTypes: {
    [RETAILER_IDS.AMAZON]: true,
    [RETAILER_IDS.WALMART]: false
  },
  hideSalesDriversTopMovers: {
    [RETAILER_IDS.AMAZON]: false,
    [RETAILER_IDS.WALMART]: true
  }
};
