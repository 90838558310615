/* eslint-disable @typescript-eslint/prefer-as-const */

export const PERIOD_ROLLUP = {
  Day: "day" as "day",
  Week: "week" as "week",
  Month: "month" as "month",
  Quarter: "quarter" as "quarter",
  Year: "year" as "year"
};

export type PERIOD_ROLLUP = typeof PERIOD_ROLLUP[keyof typeof PERIOD_ROLLUP];

export const PERIOD_SETTING = {
  Numeric: "numeric" as "numeric",
  Current: "current" as "current",
  Previous: "previous" as "previous"
};

export type PERIOD_SETTING = typeof PERIOD_SETTING[keyof typeof PERIOD_SETTING];

export type PERIOD = typeof PERIOD;
export const PERIOD = {
  Today: {
    Constant: "TODAY" as "TODAY",
    Abbreviated: "t" as "t",
    Rollup: PERIOD_ROLLUP.Day,
    Setting: PERIOD_SETTING.Current,
    IsDefault: false as false,
    IsConfigurable: false as false,
    Title: "Today",
    MenuTitle: "Today"
  },

  ThisWeek: {
    Constant: "THIS_WEEK" as "THIS_WEEK",
    Abbreviated: "tw" as "tw",
    Rollup: PERIOD_ROLLUP.Week,
    Setting: PERIOD_SETTING.Current,
    IsDefault: false as false,
    IsConfigurable: false as false,
    Title: "This Week",
    MenuTitle: "Latest Week"
  },

  ThisMonth: {
    Constant: "THIS_MONTH" as "THIS_MONTH",
    Abbreviated: "tm" as "tm",
    Rollup: PERIOD_ROLLUP.Month,
    Setting: PERIOD_SETTING.Current,
    IsDefault: false as false,
    IsConfigurable: true as true,
    Title: "This Month",
    MenuTitle: "Latest Month"
  },

  ThisQuarter: {
    Constant: "THIS_QUARTER" as "THIS_QUARTER",
    Abbreviated: "tq" as "tq",
    Rollup: PERIOD_ROLLUP.Quarter,
    Setting: PERIOD_SETTING.Current,
    IsDefault: true as true,
    IsConfigurable: true as true,
    Title: "This Quarter",
    MenuTitle: "Latest Quarter"
  },

  ThisYear: {
    Constant: "THIS_YEAR" as "THIS_YEAR",
    Abbreviated: "ty" as "ty",
    Rollup: PERIOD_ROLLUP.Year,
    Setting: PERIOD_SETTING.Current,
    IsDefault: false as false,
    IsConfigurable: true as true,
    Title: "This Year",
    MenuTitle: "Latest Year"
  },

  Yesterday: {
    Constant: "YESTERDAY" as "YESTERDAY",
    Abbreviated: "y" as "y",
    Rollup: PERIOD_ROLLUP.Day,
    Setting: PERIOD_SETTING.Previous,
    IsDefault: false as false,
    IsConfigurable: false as false,
    Title: "Yesterday",
    MenuTitle: "Yesterday"
  },

  LastWeek: {
    Constant: "LAST_WEEK" as "LAST_WEEK",
    Abbreviated: "lw" as "lw",
    Rollup: PERIOD_ROLLUP.Week,
    Setting: PERIOD_SETTING.Previous,
    IsDefault: false as false,
    IsConfigurable: false as false,
    Title: "Last Week",
    MenuTitle: "Previous Week"
  },

  LastMonth: {
    Constant: "LAST_MONTH" as "LAST_MONTH",
    Abbreviated: "lm" as "lm",
    Rollup: PERIOD_ROLLUP.Month,
    Setting: PERIOD_SETTING.Previous,
    IsDefault: false as false,
    IsConfigurable: true as true,
    Title: "Last Month",
    MenuTitle: "Previous Month"
  },

  LastQuarter: {
    Constant: "LAST_QUARTER" as "LAST_QUARTER",
    Abbreviated: "lq" as "lq",
    Rollup: PERIOD_ROLLUP.Quarter,
    Setting: PERIOD_SETTING.Previous,
    IsDefault: false as false,
    IsConfigurable: true as true,
    Title: "Last Quarter",
    MenuTitle: "Previous Quarter"
  },

  LastYear: {
    Constant: "LAST_YEAR" as "LAST_YEAR",
    Abbreviated: "ly" as "ly",
    Rollup: PERIOD_ROLLUP.Year,
    Setting: PERIOD_SETTING.Previous,
    IsDefault: false as false,
    IsConfigurable: true as true,
    Title: "Last Year",
    MenuTitle: "Previous Year"
  },

  FourWeeksToDate: {
    Constant: "FOUR_WEEKS_TO_DATE" as "FOUR_WEEKS_TO_DATE",
    Abbreviated: "24w" as "24w",
    Rollup: PERIOD_ROLLUP.Week,
    Setting: PERIOD_SETTING.Numeric,
    IsDefault: false as false,
    IsConfigurable: true as true,
    Title: "4 Weeks To Date",
    MenuTitle: "Latest 4 Weeks"
  },

  ThirteenWeeksToDate: {
    Constant: "THIRTEEN_WEEKS_TO_DATE" as "THIRTEEN_WEEKS_TO_DATE",
    Abbreviated: "13w" as "13w",
    Rollup: PERIOD_ROLLUP.Week,
    Setting: PERIOD_SETTING.Numeric,
    IsDefault: false as false,
    IsConfigurable: true as true,
    Title: "13 Weeks To Date",
    MenuTitle: "Latest 13 Weeks"
  },

  TwentysixWeeksToDate: {
    Constant: "TWENTYSIX_WEEKS_TO_DATE" as "TWENTYSIX_WEEKS_TO_DATE",
    Abbreviated: "26w" as "26w",
    Rollup: PERIOD_ROLLUP.Week,
    Setting: PERIOD_SETTING.Numeric,
    IsDefault: false as false,
    IsConfigurable: true as true,
    Title: "26 Weeks To Date",
    MenuTitle: "Latest 26 Weeks"
  },

  FiftytwoWeeksToDate: {
    Constant: "FIFTYTWO_WEEKS_TO_DATE" as "FIFTYTWO_WEEKS_TO_DATE",
    Abbreviated: "52w" as "52w",
    Rollup: PERIOD_ROLLUP.Week,
    Setting: PERIOD_SETTING.Numeric,
    IsDefault: false as false,
    IsConfigurable: true as true,
    Title: "52 Weeks To Date",
    MenuTitle: "Latest 52 Weeks"
  },

  ThirteenMonthsToDate: {
    Constant: "THIRTEEN_MONTHS_TO_DATE" as "THIRTEEN_MONTHS_TO_DATE",
    Abbreviated: "13m" as "13m",
    Rollup: PERIOD_ROLLUP.Month,
    Setting: PERIOD_SETTING.Numeric,
    IsDefault: false as false,
    IsConfigurable: false as false,
    Title: "13 Months To Date",
    MenuTitle: "Latest 13 Months"
  },

  TwentyfiveMonthsToDate: {
    Constant: "TWENTYFIVE_MONTHS_TO_DATE" as "TWENTYFIVE_MONTHS_TO_DATE",
    Abbreviated: "25m" as "25m",
    Rollup: PERIOD_ROLLUP.Month,
    Setting: PERIOD_SETTING.Numeric,
    IsDefault: false as false,
    IsConfigurable: false as false,
    Title: "25 Months To Date",
    MenuTitle: "Latest 25 Months"
  }
};

export type PeriodKey = keyof PERIOD;
export type PeriodValue = PERIOD[PeriodKey];

export type PERIODS = typeof PERIOD[keyof typeof PERIOD];
export const PERIODS = Object.values(PERIOD);

export type ConfigurablePeriod = Extract<PERIODS, { IsConfigurable: true }>;
export type CONFIGURABLE_PERIODS = ConfigurablePeriod[];
export const CONFIGURABLE_PERIODS = PERIODS.filter((p) => p.IsConfigurable).sort((pa, pb) =>
  pa.Setting === pb.Setting
    ? 0
    : pa.Setting === "current"
    ? 1
    : pb.Setting === "current"
    ? -1
    : pa.Setting === "numeric"
    ? 1
    : pb.Setting === "numeric"
    ? -1
    : pa.Setting === "previous"
    ? 1
    : pb.Setting === "previous"
    ? -1
    : 0
) as CONFIGURABLE_PERIODS;

export type DEFAULT_PERIOD = Extract<ConfigurablePeriod, { IsDefault: true }>;
export const DEFAULT_PERIOD = CONFIGURABLE_PERIODS.find((p) => p.IsDefault) as DEFAULT_PERIOD;

export type PERIODS_BY_CONSTANT = {
  [k in PERIODS["Constant"]]: Extract<PERIODS, { Constant: k }>;
};
export const PERIODS_BY_CONSTANT = PERIODS.reduce((a, r) => ({ ...a, [r.Constant]: r }), {}) as PERIODS_BY_CONSTANT;
