import React from "react";

import { ACTION_TYPES } from "../constants";
import useRootState from "./useRootState";

const useDownloadOptions = (id) => {
  const { state, dispatch } = useRootState();

  const data = state.downloadOptions[id];
  const init = React.useCallback(
    (initObj) =>
      dispatch({
        type: ACTION_TYPES.DOWNLOAD_OPTIONS.INIT,
        payload: { id, initObj }
      }),
    [id, dispatch]
  );
  const setFileName = React.useCallback(
    (fileName) =>
      dispatch({
        type: ACTION_TYPES.DOWNLOAD_OPTIONS.SET_FILENAME,
        payload: { id, fileName }
      }),
    [id, dispatch]
  );

  return React.useMemo(
    () => ({
      state: data ?? {},
      init,
      setFileName
    }),
    [data, init, setFileName]
  );
};

export default useDownloadOptions;
