import React from "react";
import { ACTION_TYPES } from "../constants";
import useRootState from "./useRootState";

// Hook to get and set the subscriptions state
const useSubscriptions = () => {
  const { state, dispatch } = useRootState();
  const setSubscriptions = React.useMemo(
    () => (payload) => dispatch({ type: ACTION_TYPES.SET_SUBSCRIPTIONS, payload }),
    [dispatch]
  );
  return React.useMemo(
    () => ({
      state: state.subscriptions,
      setSubscriptions
    }),
    [state, setSubscriptions]
  );
};

export default useSubscriptions;
