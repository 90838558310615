import React from "react";
import INITIAL_STATE from "./initialState";
import reducer from "./reducer";

export const RootStateContext = React.createContext();

export const RootStateProvider = function ({ children, initialState }) {
  const state = React.useReducer(reducer, initialState);

  // TODO: we'll probably have some effects here to sync to and from local storage

  return <RootStateContext.Provider value={state}>{children}</RootStateContext.Provider>;
};

RootStateProvider.defaultProps = {
  children: null,
  initialState: INITIAL_STATE
};
