import { FEATURES } from "lib/constants/features";

export default function clearStorage(...keys: ("dev" | "auth" | "preferences")[]) {
  if (keys.length === 0) {
    keys = ["dev", "auth"];
  }

  if (keys.includes("dev")) {
    clearAll([
      "dev",
      "dev:locked",
      ...Object.values(FEATURES).map(({ Key }) => `dev:features:${Key}`),
      "dev:binding:pane1",
      "dev:binding:portfolio",
      "dev:binding:period",
      "dev:binding:download",
      "dev:binding:preferences",
      "dev:binding:close"
    ]);
  }

  if (keys.includes("auth")) {
    clearAll([
      "setup-ocr-jwts",
      "setup-ocr-admin-client",
      "setup-ocr-admin",
      "setup-ocr-client",
      "setup-ocr-tokens",
      "setup-ocr-user"
    ]);
  }

  if (keys.includes("preferences")) {
    clearAll([
      "pref:sp",
      "pref:fp",
      "pref:fP",
      "pref:fs",
      "pref:mb-ms-gv",
      "pref:mb-ms-gt",
      "pref:mb-ms-st",
      "pref:mb-mb-gs",
      "pref:mb-mb-as",
      "pref:mb-mb-gt",
      "pref:mb-mb-st",
      "pref:mb-md-am",
      "pref:mb-md-as",
      "pref:mb-ip-m",
      "pref:mb-ip-v",
      "pref:c-sl-gv",
      "pref:c-sl-as",
      "pref:c-sl-gt",
      "pref:c-sh-gv",
      "pref:c-sh-as",
      "pref:c-sh-gt",
      "pref:c-ip-m",
      "pref:c-ip-st",
      "pref:c-ip-sv",
      "pref:sc-dt",
      "pref:sc-ip-sv",
      "pref:pr-ip-st",
      "pref:pr-ip-sv"
    ]);
  }
}

function clearAll(xs: string[]) {
  for (const x of xs) {
    window.localStorage.removeItem(x);
  }
}
