import React from "react";
import styled, { css } from "styled-components/macro";
import { Button } from "lib/components/Button/Button";
import { toggleDevLocked, useDevLocked } from "../../stores/devLocked";

export const Footer = () => {
  const locked = useDevLocked();
  return (
    <Inner>
      <Lock bare onClick={() => toggleDevLocked()}>
        {locked ? "🔒" : "🔓"}
      </Lock>
    </Inner>
  );
};

const Inner = styled.menu`
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  justify-content: flex-start;
  padding: 0;
  transition: all 0.5s;
  width: var(--shrunken-width);
`;

const Lock = styled(Button)`
  filter: brightness(3);
  font-size: large;
  padding-left: 0.25rem;
`;
