import { combine } from "effector";
import { app } from "lib/stores/app";
import { $clientUuid, $userToken } from "lib/stores/auth";
import { getEnv } from "units/market-share/utility/env";

// export const api = createDomain("api");
export const apiDomain = app;

export const getApiBase = () => (getEnv() as Record<string, string>).REACT_APP_MS_API_NEW;

export const clientUuidUserToken = combine({ $clientUuid, $userToken });
