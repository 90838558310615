import { createEvent, createStore } from "effector";
import { FeatureRecord } from "lib/constants/features";
import { persistLocal } from "./utils/storage";
import { FEATURES } from "../constants/features";

export const clearDevFeatures = createEvent<void>();
export const clearDevFeature = () => clearDevFeatures();

export const setDevFeatures = createEvent<Partial<FeatureRecord>>("setDevFeatures");

// Get each feature's initial value from localStorage
const initialValues: Partial<FeatureRecord> = {};
for (const { Key, DefaultEnabled } of Object.values(FEATURES)) {
  const storedKey = `dev:features:${Key}`;
  const storedValue = localStorage.getItem(storedKey);
  // eslint-disable-next-line no-multi-assign
  const value = (initialValues[Key] = storedValue == null ? DefaultEnabled : storedValue === "true");
  const $store = createStore<boolean>(value, { name: `${Key}Feature` });
  $store.on(setDevFeatures, (state, payload) => (Key in payload ? payload[Key] : state));
  persistLocal<boolean>(storedKey, $store);
}

export const $devFeatures = createStore<FeatureRecord>(initialValues as FeatureRecord, { name: "feature" });
export const getDevFeatures = $devFeatures.getState;
export const setDevFeature = <K extends keyof FeatureRecord, V extends FeatureRecord[K]>(key: K, value: V) =>
  setDevFeatures({ [key]: value });
export const getDevFeature = <K extends keyof FeatureRecord>(key: K) => getDevFeatures()[key];
$devFeatures.on(setDevFeatures, (state, payload) => ({ ...state, ...payload }));
$devFeatures.reset(clearDevFeatures);
