import { Event, Store } from "effector";
import * as Patronum from "patronum";

export * from "effector";
export * from "effector-react";

const mapper =
  <O>(fn: (value: unknown, meta?: unknown, prev?: unknown) => O) =>
  <T, U>(unit: U, meta: null | T = null): U extends Store<any> ? Store<O> : Event<O> =>
    (unit as any).map((value: any, prev?: any) => fn(value, meta, prev));

const applier = (fn: (mapState: unknown, mapValue: unknown) => boolean) =>
  mapper<boolean>((state, _prev, value) => fn(state, value));

export const not = applier((state) => !state);
export const bool = applier((state) => !!state);

export const eq = applier((value, compare) => value === compare);
export const neq = applier((value, compare) => value !== compare);

export const some = (...stores: Store<boolean>[]) => Patronum.some({ predicate: (x) => x, stores });
export const every = (...stores: Store<boolean>[]) => Patronum.every({ predicate: (x) => x, stores });

export const delay = (store: Event<any> | Store<any>, timeout: number) => Patronum.delay({ source: store, timeout });
export const throttle = (store: Event<any> | Store<any>, timeout: number) =>
  Patronum.throttle({ source: store, timeout });
export const debounce = (store: Event<any> | Store<any>, timeout: number) =>
  Patronum.debounce({ source: store, timeout });
