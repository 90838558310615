import React from "react";
import { useStore } from "effector-react";
import styled, { css } from "styled-components/macro";
import { Button } from "lib/components/Button/Button";
import { FeatureRecord } from "lib/constants/features";
import { $devFeatures, setDevFeature } from "lib/stores/devFeatures";
import { Panel } from "./Panel";

export const Features = () => {
  const features = useStore($devFeatures);
  return (
    <Panel>
      <h2>Features</h2>
      <Inner>
        {Object.entries(features).map(([key, value]) => (
          <Button key={key} css="padding: 0" onClick={(_) => toggleFeature(key as any, !value)} fill>
            <input type="checkbox" checked={value} onChange={() => {}} />
            <span>
              {key
                .split("_")
                .map((part) => part[0].toUpperCase() + part.slice(1))
                .join(" ")}
            </span>
          </Button>
        ))}
      </Inner>
    </Panel>
  );
};

const Inner = styled.div`
  color: white;
  flex: 1;
  text-align: left;

  input[type="checkbox"] {
    margin-right: 0.5rem;
  }

  span {
    text-align: left;
    align-self: flex-start;
    flex: 1;
    color: white;
    justify-content: left;
  }
`;

function toggleFeature(key: keyof FeatureRecord, value: any) {
  setDevFeature(key, value);
  setTimeout(() => {
    window.location.reload();
  }, 100);
}
