import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useStore } from "effector-react";
import qs from "query-string";
import { SearchParams } from "lib/constants/search";
import { app, getAppMode } from "./app";
import { usePathname } from "./pathname";

export const initialSearch = qs.parse(window.location.search, {
  decode: true
}) as SearchParams;

export const $search = app.createStore<SearchParams>(initialSearch, {
  name: "search"
});
export const setSearch = app.createEvent<Partial<SearchParams>>("setSearch");
export const getSearch = $search.getState;
export const useSearch = () => {
  // Keep window search synced with store
  const pathname = usePathname();
  const location = useLocation();
  const history = useHistory();
  const state = useStore($search);

  const parsed = useMemo(
    () =>
      qs.parse(location.search, {
        decode: true
      }),
    [location.search]
  );

  const merged = useMemo(
    () =>
      cleanParams({
        ...parsed,
        ...state
      }),
    [state, parsed]
  );

  const mergedString = useMemo(
    () =>
      qs.stringify(merged, {
        encode: false,
        skipEmptyString: true,
        skipNull: true
      }),
    [merged]
  );

  const mergedSearch = useMemo(() => {
    return mergedString ? `?${mergedString}` : mergedString;
  }, [mergedString]);

  const mergedHref = useMemo(() => `${pathname}${mergedSearch}`, [pathname, mergedSearch]);

  React.useEffect(() => history.replace(mergedHref), [mergedHref]);
  return state;
};

export const getSearchParam = (key: keyof SearchParams) => getSearch()[key];
export const setSearchParam = (key: keyof SearchParams, val: SearchParams[keyof SearchParams]) =>
  setSearch({
    [key]: val === "null" ? null : val
  } as SearchParams);

$search.on(setSearch, (state, payload) => ({
  ...state,
  ...payload
}));

function cleanParams<T extends Record<string, unknown>>(params: T): T {
  // If `api_key` was passed, remove it after app has initialized.
  // This is mostly for demos, so that we don't accidentally expose an API key.
  if (params.api_key && getAppMode() !== "initializing") {
    delete params.api_key;
  }
  return params;
}
