import React from "react";
import useAxios from "units/market-share/RootState/hooks/useAxios";
import { Redirect } from "react-router-dom";
import "styled-components/macro";
import { getEnv } from "units/market-share/utility/env";
import useAuth from "units/market-share/RootState/hooks/useAuth";
import { Payload as AuthPayload } from "lib/types/auth";
import { Title } from "./UI";

interface LoginResponse {
  data: AuthPayload;
}

const useLoginWithApiKey = (apiKey: string) => {
  const { REACT_APP_MS_API_NEW } = getEnv();

  return useAxios<AuthPayload>({
    url: `${REACT_APP_MS_API_NEW}/v2/user_auth/login_with_key`,
    method: "POST",
    data: { api_key: apiKey }
  });
};

export const setAuthToStorage = (payload: AuthPayload) => {
  window.localStorage.setItem("setup-ocr-admin", JSON.stringify(payload.data.admin));
  window.localStorage.setItem("setup-ocr-client", JSON.stringify(payload.data.client));
  window.localStorage.setItem("setup-ocr-admin-client", JSON.stringify(payload.data.admin_client));
  window.localStorage.setItem("setup-ocr-user", JSON.stringify(payload.data.user));
  window.localStorage.setItem("setup-ocr-jwts", JSON.stringify(payload.data.jwts));
  window.localStorage.setItem("setup-ocr-tokens", JSON.stringify(payload.tokens));
};

interface Props {
  apiKey: string;
}

const LoginWithApiKey: React.FC<Props> = function ({ apiKey }) {
  const { setAuth } = useAuth();
  const [result] = useLoginWithApiKey(apiKey);

  React.useEffect(() => {
    if (result.data) {
      setAuthToStorage(result.data);
      setAuth(result.data);
    }
  }, [result, setAuth]);

  if (result.error) return <Redirect to="/login" />;

  return (
    <>
      <Title>Log in</Title>
      <div css="flex: 1; display: flex; align-items: center; padding-bottom: 32px">
        <p css="flex: 1; text-align: center; font-size: 16px">Loading…</p>
      </div>
    </>
  );
};

export default LoginWithApiKey;
