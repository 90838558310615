import React from "react";
import { Section, SectionBody } from "units/ui/Section";
import { FormattedMessage } from "react-intl";

const Missing = function () {
  return (
    <Section>
      <SectionBody mx={0}>
        <p>
          <FormattedMessage id="section.missing.detail" />
        </p>
      </SectionBody>
    </Section>
  );
};

export default Missing;
