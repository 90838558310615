import React from "react";
import styled from "styled-components/macro";
import { Button } from "lib/components/Button/Button";
import clearSearchParams from "lib/utilities/clearSearchParams";
import clearStorage from "lib/utilities/clearStorage";
import { Panel } from "./Panel";

export const Actions = () => {
  return (
    <Panel>
      <h2>Actions</h2>

      <Action
        onClick={() => {
          clearSearchParams();
          setTimeout(() => {
            window.location.pathname = "/";
          }, 1000);
        }}
      >
        Clear URL querystring and path
      </Action>

      <Action onClick={() => clearStorage("dev")}>Clear dev keys</Action>

      <Action onClick={() => clearStorage("auth")}>Clear auth keys</Action>

      <Action onClick={() => clearStorage("preferences")}>Clear preference keys</Action>
    </Panel>
  );
};

const Action = styled(Button)`
  background-color: var(--color-grey_30);
  color: white;
  margin: 0;
  margin-bottom: 0.5rem;
  flex: 1;
  min-width: 100%;

  &${Button} {
    margin-left: 0;
  }

  &:hover {
    background-color: var(--color-grey_20);
    color: var(--color-white);
    transition-duration: 0.1s;
  }
`;
