import { createEvent, createStore, Domain, is, Store } from "effector";
import { persist as PersistLocal } from "effector-storage/local";
import { persist as PersistSession } from "effector-storage/session";
import { app } from "../app";

type EffectorStorage = typeof PersistLocal | typeof PersistSession;
type WindowStorage = typeof localStorage | typeof sessionStorage;

interface Config {
  key: string;
  name?: string;
  domain?: Domain;
}

export type Args<T> = [Config] | [T, Config] | [Store<T>] | [string, Store<T>];

// export const storageDomain = app.createDomain("storage");
export const storageDomain = app;

export const registerLocalKey = app.createEvent<string>();
export const registerSessionKey = app.createEvent<string>();

export const $localKeys = app.createStore<Set<string>>(new Set()).on(registerLocalKey, (state, key) => state.add(key));
export const $sessionKeys = app
  .createStore<Set<string>>(new Set())
  .on(registerSessionKey, (state, key) => state.add(key));

export const persistLocal = <T>(...args: Args<T>): Store<T> => {
  const [key, store] = persist(window.localStorage, PersistLocal, ...args);
  registerLocalKey(key);
  return store;
};

export const persistSession = <T>(...args: Args<T>): Store<T> => {
  const [key, store] = persist(window.sessionStorage, PersistSession, ...args);
  registerSessionKey(key);
  return store;
};

function persist<T>(windowStorage: WindowStorage, effectorStorage: EffectorStorage, ...args: Args<T>) {
  let storeDomain: Domain = storageDomain;

  if (args.length === 1) {
    if (is.store(args[0])) {
      const store = args[0] as Store<T>;
      const name = (store.shortName || store.sid) as string;
      return persist<T>(windowStorage, effectorStorage, name, store);
    }
    const config = args[0] as Config;
    return persist<unknown>(windowStorage, effectorStorage, null, config);
  }

  if (!is.store(args[1])) {
    const [initial, { key, domain, name, ...config }] = args as [T, Config];
    if (domain) storeDomain = domain;
    const store = createStore<T>(initial, { name: name || key, ...config });
    return persist<T>(windowStorage, effectorStorage, key, store);
  }

  const [key, store] = args as [string, Store<T>];

  effectorStorage({ key, store });

  const update = createEvent<T>();
  store.on(update, (_state, payload) => payload);

  const raw = windowStorage.getItem(key);
  if (raw != null) {
    try {
      const parsed = JSON.parse(raw) as T;
      update(parsed);
    } catch (error) {
      console.error(error);
    }
  }

  return [key, store];
}
