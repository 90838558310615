import React from "react";
import { ACTION_TYPES } from "../constants";
import useRootState from "./useRootState";

// Hook to get and set the chart options
const useChartOptions = () => {
  const { state, dispatch } = useRootState();

  const { chartoptions } = state;
  const setChartOptions = React.useCallback(
    (payload) => dispatch({ type: ACTION_TYPES.SET_CHART_OPTIONS, payload }),
    [dispatch]
  );

  return React.useMemo(
    () => ({
      state: chartoptions ?? {},
      setChartOptions
    }),
    [chartoptions, setChartOptions]
  );
};

export default useChartOptions;
