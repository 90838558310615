import * as Preference from "lib/constants/preferences";
import { getAuth } from "lib/stores/auth";
import { getDownload } from "lib/stores/download";
import { getFilterData } from "lib/stores/filterData";
import { getPeriod, getPeriodSelection } from "lib/stores/period";
import {
  getPortfolioBranches,
  getPortfolioFilterEditingId,
  getPortfolioFilterOriginal,
  getPortfolioFilterSavedId,
  getPortfolioFiltersSavedByIds,
  getPortfolioItemsByGroup,
  getPortfolioLoading
} from "lib/stores/portfolio";
import { getRoute } from "lib/stores/route";
import { App } from "lib/types/app";
import { PeriodWeekdayNumber } from "lib/types/period.d";

import { Debug } from "lib/debug";

const auth = getAuth();

const INITIAL_STATE: App = Debug("initial_state")({
  filterData: getFilterData(),

  portfolioLoading: getPortfolioLoading(),
  portfolioBranches: getPortfolioBranches(),
  portfolioItemsByGroup: getPortfolioItemsByGroup(),

  filterOriginal: getPortfolioFilterOriginal(),
  filterEditing: getPortfolioFilterEditingId(),
  filterSavedId: getPortfolioFilterSavedId(),
  filterSavedByIds: getPortfolioFiltersSavedByIds(),

  download: getDownload(),

  route: getRoute(),

  date: {
    type: getPeriod().rollup,
    start: getPeriod().start,
    end: getPeriod().end,
    weekStartsOn: PeriodWeekdayNumber.Sunday,
    firstWeekContainsDate: 7
  },

  inPageFilter: {
    my_business: {
      activated: true,
      metric: Preference.MyBusinessInPageMetric.getValue().toUpperCase(),
      salesType: Preference.MyBusinessMyBrandSalesType.getValue(),
      vat: Preference.MyBusinessInPageVat.getValue(),
      period: Preference.MyBusinessInPagePeriod.getValue()
    },

    market_view: {
      activated: true,
      customAttribute: {},
      metric: Preference.CompetitiveInPageMetric.getValue().toUpperCase(),
      salesType: Preference.CompetitiveInPageSalesType.getValue(),
      viewBy: Preference.CompetitiveInPageSectionView.getValue().toUpperCase(),
      period: Preference.CompetitiveInPagePeriod.getValue()
    },

    scorecard: {
      activated: true,
      viewBy: Preference.ScorecardInPageSectionView.getValue().toUpperCase(),
      customAttribute: {}
    },

    popular_reports: {
      activated: true,
      viewBy: Preference.PopularReportsInPageSectionView.getValue().toUpperCase(),
      salesType: Preference.PopularReportsInPageSalesType.getValue(),
      customAttribute: {}
    }
  },

  currencyData: {},

  calendar: {
    items: [],
    isNotDateFormatTickX: true,
    isInitialLoad: true,
    selection: getPeriodSelection(),
    originalSelection: "NONE"
  },

  // prettier-ignore
  auth: {
    isAuth: (
      process.env.REACT_APP_CODE_ENV === "development" &&
      process.env.REACT_APP_BYPASS_AUTH === "true"
    ) || !!auth?.isAuthenticated,
    authTokens: !auth?.tokens ? {} : auth.tokens,
    authInformation: !auth ? {} : {
      admin: auth.adminUser,
      admin_client: auth.adminClient,
      user: auth.user,
      client: auth.client,
      jwts: auth.jwts,
    },
  },

  subscriptions: {
    "amz_dash": false,
    "amz_promo": false,
    "3p_dash": false,
    "traffic": false
  },

  chartoptions: {
    mbSales: {
      numberOfLegendItems: 10,
      comparisonOptions: {
        wow: true,
        yoy: true
      }
    },
    mbShare: {
      numberOfLegendItems: 10,
      comparisonOptions: {
        wow: true,
        yoy: true
      }
    },
    mbSalesAndShare: {
      numberOfLegendItems: 5,
      comparisonOptions: {
        wow: true,
        yoy: true
      }
    },
    mbSalesDriver: {
      numberOfLegendItems: 10,
      comparisonOptions: {
        wow: true,
        yoy: true
      },
      topChartDisplay: {
        topPerformer: false,
        topMover: true
      }
    },
    cSales: {
      numberOfLegendItems: 11,
      comparisonOptions: {
        wow: true,
        yoy: true
      }
    },
    cShare: {
      numberOfLegendItems: 11,
      comparisonOptions: {
        wow: true,
        yoy: true
      }
    }
  },

  downloadOptions: {},

  tableViewTypeOptions: {},

  steppers: {},

  createUserOpen: false,

  scoreCard: {
    scoreCard: "",
    lookerContent: ""
  },

  usersManagement: {
    user: {
      canUpdate: false,
      isUserUpdate: ""
    }
  },

  customFields: [],

  rateCard: {
    openRateCardDrawer: false,
    editRateCardData: null,
    clientDefRateCardData: null,
    updateRateCards: true,
    showRateCardData: null,
    updateLoading: false,
    rateCards: []
  },
  profitLoss: {
    rateCardX: null,
    rateCardY: null,
    selectedScenario: null
  },
  riskChart: {
    x: "",
    xDomain: [],
    periodEnd: ""
  },
  heatMap: {
    x: 0,
    y: 0
  },

  // Search (ScoreCard page)
  search: {
    departmentList: []
  },

  searchv2: {
    allGraphData: {
      searchTerm: "",
      clicks: [],
      conversions: [],
      ranks: [],
      department: "",
      startDate: "",
      endDate: ""
    }
  }
});

export default INITIAL_STATE;
