import styled from "styled-components/macro";
import { space, variant } from "styled-system";

export const Section = styled.section<any>`
  align-items: stretch;
  background-color: var(--color-white);
  box-sizing: border-box;
  margin-top: var(--space-4);
  margin-left: var(--space-4);
  margin-right: var(--space-4);
  padding: var(--space-4);
  position: relative;
  // width: var(--content-width);
  ${variant({
    variants: {
      search: {
        height: "auto",
        marginTop: "0px",
        marginLeft: "0px",
        width: "var(--content-width)"
      },
      scorecardSection: {
        boxSizing: "border-box",
        backgroundColor: "var(--color-white)",
        borderTop: "0",
        display: "flex",
        flexFlow: "wrap",
        marginTop: "0",
        zIndex: "0"
      }
    }
  })}
  ${space}
`;

export const SectionTitle = styled.h2`
  box-sizing: border-box;
  font-size: var(--font-size-2);
  font-weight: bold;
  margin: 0 0 var(--space-2);
  position: relative;
  z-index: var(--section-title-z-index);
  ${space}
`;

export const SectionBody = styled.div<any>`
  box-sizing: border-box;
  display: flex;
  margin-left: calc(-1 * var(--space-2));
  margin-right: calc(-1 * var(--space-2));
  ${variant({
    variants: {
      search: {
        flexDirection: "column",
        width: "22%",
        padding: "1%",
        background: "#fff",
        marginTop: "0",
        boxShadow: "5px 1px 3px 1px #f0f0f2"
      },
      scorecardMain: {
        display: "flex",
        flexFlow: "wrap",
        width: "100%"
      }
    }
  })}

  ${space}
`;

export const SectionActions = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  margin-top: var(--space-3);
  margin-bottom: calc(-1 * var(--space-2));
  ${space}
`;

export const SectionWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--color-grey-98) ${space};
`;
