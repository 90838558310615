import React from "react";
import { Auth as AuthState, Payload as AuthPayload } from "lib/types/auth";
import useRootState from "./useRootState";
import { ACTION_TYPES } from "../constants";

export const getInfo = (auth: AuthState) => auth?.authInformation;
export const getTokens = (auth: AuthState) => auth?.authTokens;
export const getClient = (auth: AuthState) => getInfo(auth)?.client;
export const getClientUuid = (auth: AuthState) => getClient(auth)?.uuid;
export const getUser = (auth: AuthState) => getInfo(auth)?.user;
export const getUserUuid = (auth: AuthState) => getUser(auth)?.uuid;
export const getUserToken = (auth: AuthState) => getTokens(auth)?.user;
export const getAdmin = (auth: AuthState) => getInfo(auth)?.admin;
export const getAdminToken = (auth: AuthState) => getTokens(auth)?.admin;
export const getAuthContext = (auth: AuthState) => ({
  tokens: getTokens(auth),
  client: getClient(auth),
  clientUuid: getClientUuid(auth),
  user: getUser(auth),
  userUuid: getUserUuid(auth),
  userToken: getUserToken(auth),
  admin: getAdmin(auth),
  adminToken: getAdminToken(auth)
});

export const useAuthInfo = () => getInfo(useAuthState());
export const useAuthTokens = () => getTokens(useAuthState());
export const useAuthClient = () => getClient(useAuthState());
export const useAuthClientUuid = () => getClientUuid(useAuthState());
export const useAuthUser = () => getUser(useAuthState());
export const useAuthUserUuid = () => getUserUuid(useAuthState());
export const useAuthUserToken = () => getUserToken(useAuthState());
export const useAuthAdmin = () => getAdmin(useAuthState());
export const useAuthAdminToken = () => getAdminToken(useAuthState());
export const useAuthContext = () => {
  const auth = useAuthState();
  return React.useMemo(() => getAuthContext(auth), [auth]);
};

export const useAuthState = () => useRootState()?.state?.auth;

/**
 * Hook to get and set the auth state.
 */
const useAuth = () => {
  const { state, dispatch } = useRootState();
  const auth = state?.auth as AuthState;

  const setAuth = React.useCallback(
    (payload: AuthPayload) => {
      dispatch({ type: ACTION_TYPES.SET_AUTH, payload });
    },
    [dispatch]
  );

  return React.useMemo(
    () => ({
      state: auth,
      setAuth
    }),
    [auth, setAuth]
  );
};

export default useAuth;
