import addWeeks from "date-fns/addWeeks";
import eachWeekOfIntervalWithOptions from "date-fns/fp/eachWeekOfIntervalWithOptions";
import endOfWeekWithOptions from "date-fns/fp/endOfWeekWithOptions";
import format from "date-fns/fp/format";
import parse from "date-fns/fp/parse";
import startOfWeekWithOptions from "date-fns/fp/startOfWeekWithOptions";
import getDate from "date-fns/getDate";
import differenceInDays from "date-fns/differenceInDays";

// NOTE: Date formatting in date-fns follows Unicode Technical Standard #35
//       For details see https://date-fns.org/v2.6.0/docs/format
const FORMAT = "yyyy-MM-dd";

export const stringToDate = parse(new Date(), FORMAT);

export const dateToString = format(FORMAT);

export const getNextWeek = (date: string) => addWeeks(stringToDate(date), 1);

export const getDayNumeral = (date: string) => getDate(stringToDate(date));

export const getEndWeek = (date: number | Date, weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined) => {
  return endOfWeekWithOptions({ weekStartsOn }, date);
};

export const weeksInDateRange = (range: { start: string; end: string }, options = {}) => {
  const start = startOfWeekWithOptions(options, stringToDate(range.start));
  const end = startOfWeekWithOptions(options, stringToDate(range.end));
  return eachWeekOfIntervalWithOptions(options, { start, end });
};

export const formatDate = (date, formateOfDate) => format(formateOfDate, date);

export { addWeeks, differenceInDays };
