export const CUSTOM_DOWNLOAD_METRICS_WM = {
  INTERVAL: ["Beginning Date Range", "Week", "Month", "Quarter", "Year"],
  VIEW_BY: ["Manufacturer", "Brand", "Product Group", "Category", "Subcategory", "ASIN", "Name", "UPC"],
  CUSTOM_ATTRIBUTES: [],
  TOTALS: {
    PRODUCT_LITE: {
      NO_VAT_METRICS: [],
      VAT_METRICS: []
    },
    PRODUCT: {
      NO_VAT_METRICS: ["Total Sales", "Total Units", "Total ASP"],
      VAT_METRICS: ["My Total Non-VAT Sales", "Market Total VAT Sales"]
    },
    MARKET_SHARE_X: {
      NO_VAT_METRICS: ["Total Sales", "Total Units", "Total ASP"],
      VAT_METRICS: ["Market Total VAT Sales", "Market Total Units"]
    }
  }
};
