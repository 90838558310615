import type { Client, Tokens, Jwts, User } from "lib/types/auth";
import type { StoreValue } from "effector";
import { combine, createStore } from "effector";
import { bool } from "./utils/functions";
import { persistLocal } from "./utils/storage";
import { get, getSetUseTuple, getUse, set } from "./utils/wrappers";

// prettier-ignore
export const $user = persistLocal<User>({ key: "setup-ocr-user", name: "auth:user" });
export const [getUser, setUser, useUser] = getSetUseTuple($user);
$user.on(setUser, (_state, payload) => payload);

// prettier-ignore
export const $adminUser = persistLocal<User>({ key: "setup-ocr-admin", name: "auth:adminUser" });
export const [getAdminUser, setAdminUser, useAdminUser] = getSetUseTuple($adminUser);
$adminUser.on(setAdminUser, (_state, payload) => payload);

// prettier-ignore
export const $client = persistLocal<Client>({ key: "setup-ocr-client", name: "auth:client", });
export const [getClient, setClient, useClient] = getSetUseTuple($client);
$client.on(setClient, (_state, payload) => payload);

// prettier-ignore
export const $adminClient = persistLocal<Client>({ key: "setup-ocr-admin-client", name: "auth:adminClient", });
export const [getAdminClient, setAdminClient, useAdminClient] = getSetUseTuple($adminClient);
$adminClient.on(setAdminClient, (_state, payload) => payload);

// prettier-ignore
export const $tokens = persistLocal<Tokens>({ key: "setup-ocr-tokens", name: "auth:tokens", });
export const [getTokens, setTokens, useTokens] = getSetUseTuple($tokens);
$tokens.on(setTokens, (_state, payload) => payload);

// prettier-ignore
export const $jwts = persistLocal<Jwts>({ key: "setup-ocr-jwts", name: "auth:jwts", });
export const [getJwts, setJwts, useJwts] = getSetUseTuple($jwts);
$jwts.on(setJwts, (_state, payload) => payload);

export const $adminClientUuid = $adminClient.map((client) => client?.uuid || null);
export const [getAdminClientUuid, useAdminClientUuid] = getUse($adminClientUuid);

export const $adminUserUuid = $adminUser.map((user) => user?.uuid || null);
export const [getAdminUserUuid, useAdminUserUuid] = getUse($adminUserUuid);

export const $clientUuid = $client.map((client) => client?.uuid || null);
export const [getClientUuid, useClientUuid] = getUse($clientUuid);

export const $userUuid = $user.map((user) => user?.uuid || null);
export const [getUserUuid, useUserUuid] = getUse($userUuid);

export const $userToken = $tokens.map((tokens) => tokens?.user || null);
export const [getUserToken, useUserToken] = getUse($userToken);

export const $adminUserToken = $tokens.map((tokens) => tokens?.admin || null);
export const [getAdminUserToken, useAdminUserToken] = getUse($adminUserToken);

export const $isAuthenticated = bool($userToken);
export const [getIsAuthenticated, useIsAuthenticated] = getUse($isAuthenticated);

export const $auth = combine(
  $user,
  $adminUser,
  $client,
  $adminClient,
  $tokens,
  $jwts,
  (user, adminUser, client, adminClient, tokens, jwts) =>
    !user
      ? null
      : {
          user,
          userUuid: user.uuid,
          adminUser,
          adminUserUuid: adminUser.uuid,
          client,
          clientUuid: client.uuid,
          adminClient,
          adminClientUuid: adminClient.uuid,
          tokens,
          jwts,
          isAuthenticated: Boolean(user)
        }
);

export const setAuth = set<
  Partial<{
    user: StoreValue<typeof $user>;
    adminUser: StoreValue<typeof $adminUser>;
    client: StoreValue<typeof $client>;
    adminClient: StoreValue<typeof $adminClient>;
    tokens: StoreValue<typeof $tokens>;
    jwts: StoreValue<typeof $jwts>;
  }>
>("auth:set");

export const getAuth = get($auth);

$auth.watch(setAuth, (_state, payload) => {
  if (payload.user) setUser(payload.user);
  if (payload.adminUser) setAdminUser(payload.adminUser);
  if (payload.client) setClient(payload.client);
  if (payload.adminClient) setAdminClient(payload.adminClient);
  if (payload.tokens) setTokens(payload.tokens);
  if (payload.jwts) setJwts(payload.jwts);
});
