import React from "react";

import { ACTION_TYPES } from "../constants";
import useRootState from "./useRootState";

const useCreateUser = () => {
  const { state, dispatch } = useRootState();
  const open = React.useCallback(
    () =>
      dispatch({
        type: ACTION_TYPES.CREATE_USER,
        payload: true
      }),
    [dispatch]
  );

  const close = React.useCallback(
    () =>
      dispatch({
        type: ACTION_TYPES.CREATE_USER,
        payload: false
      }),
    [dispatch]
  );

  return React.useMemo(
    () => ({
      isOpen: state.createUserOpen,
      open,
      close
    }),
    [state.createUserOpen, open, close]
  );
};

export default useCreateUser;
