import React from "react";
import styled, { css } from "styled-components/macro";
import clsx from "clsx";
import { Button } from "lib/components/Button/Button";
import { API_BASE_DEFAULT_URL, API_BASES } from "lib/constants/apiUrl";
import { setApiActive, setApiCustom, setApiSelection, useApiActive, useApiCustom } from "lib/stores/api";
import { Panel } from "./Panel";

export const ApiUrls = () => {
  const active = useApiActive();
  const custom = useApiCustom();

  return (
    <Panel>
      <h2>API Host</h2>

      {API_BASES.map(({ Key, Label, Url }) => {
        if (API_BASE_DEFAULT_URL === Url) Label += " (default)";
        return <Selector key={Key} id={Key} label={Label} url={Url} />;
      })}

      <Hr />

      <Selector id="custom" label="Custom" url={custom}>
        <input
          className="text-input"
          type="text"
          value={custom}
          onClick={() => setApiActive("custom")}
          onChange={(e) => {
            const value = e.currentTarget.value;
            setApiActive("custom");
            setApiCustom(value);
            setApiSelection(value);
          }}
        />
      </Selector>
    </Panel>
  );
};

interface Props {
  id: string;
  label: string;
  url?: string;
  children?: any;
}

const toggleApi = (id: string, url: string) => {
  setApiActive(id);
  setApiSelection(url);
  setTimeout(() => {
    window.location.reload();
  }, 100);
};

const Selector = ({ id, label, url, children }: Props) => {
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
  const custom = useApiCustom();
  const active = id === useApiActive();
  return (
    <SelectorInner className={clsx(active && "active")}>
      <label htmlFor={`selector-input-${label}`}>
        <input
          className="radio-input"
          id={`selector-input-${label}`}
          type="radio"
          checked={active}
          onChange={() => toggleApi(id, id === "custom" ? custom : (url as string))}
        />
        <span className="radio-name">{label}</span>
        {!children && <div className="radio-value">{(url || "").replace(/^https:\/\//, "")}</div>}
      </label>
      {children}
    </SelectorInner>
  );
};

const SelectorInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 2rem;
  line-height: 2rem;
  padding-right: 0.325rem;

  label {
    align-items: center;
    display: flex;
    cursor: pointer;
    flex: 1;
  }

  .radio-value {
    flex: 1;
    text-align: right;
  }

  .radio-input {
    margin-right: 0.5rem;
  }

  .text-input {
    background-color: hsla(0, 100%, 100%, 0.05);
    border-radius: 4px;
    border: 0;
    color: white;
    flex: 3;
    line-height: 1;
    margin-right: -0.25rem;
    outline: 0;
    padding: 0.25rem;
    text-align: right;
  }

  .radio-value,
  .text-input {
    font-size: 11px;
    opacity: 0.65;
  }

  &:not(.active):hover *:where(.radio-value, .text-input) {
    opacity: 0.8;
  }

  &.active *:where(.radio-value, .text-input),
  &:where(:hover, :focus-within) .text-input {
    opacity: 1;
  }

  ${Button} {
    align-items: center;
    background-color: var(--color-grey_30);
    color: white;
    justify-content: space-between;
    margin: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex: 1;

    &${Button} {
      margin-left: 0;
    }

    &:hover {
      background-color: var(--color-grey_20);
      color: var(--color-white);
      transition-duration: 0.1s;
    }
  }
`;

const Hr = styled.hr`
  border-color: black;
  border-width: 1px 0 0 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  opacity: 0.05;
`;
