import React from "react";
import { useAuthContext } from "units/market-share/RootState/hooks/useAuth";
import { useEnvContext } from "units/market-share/utility/env";

export { useAuthContext };
export { useEnvContext };
// prettier-ignore
export const useSession = () => {
  const authContext = useAuthContext();
  const envContext = useEnvContext();
  const session = React.useMemo(() => ({
    ...authContext,
    ...envContext,
  }), [authContext]);
  // console.log("SESSION", session);
  return session;
};

export default useSession;
