import React from "react";
import styled, { css } from "styled-components/macro";

export const Panel = ({ children }: any) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  h2 {
    color: white;
    margin-bottom: 0.75rem;
  }

  &:not(:first-child) h2 {
    margin-top: 2rem;
  }
`;
