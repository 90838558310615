import { useEffect } from "react";

import type { CalendarResponse } from "lib/types/api";
import { useSession } from "lib/hooks/useSession";
import { FeatureFlags } from "lib/types/featureFlags";
import { RetailerSubscriptions } from "lib/types/retailerSubscriptions";

import useAxios from "units/market-share/RootState/hooks/useAxios";

/**
 * Get all Subscriptions for a Client.
 */
export const useApiSubscriptions = () => {
  const { clientUuid, userToken, host } = useSession();
  return useAxios(
    {
      url: `${host}/clients/${clientUuid}/subscriptions`,
      headers: { "X-API-KEY": userToken }
    },
    { manual: true }
  );
};

/**
 * Get all Feature Flags for a Client.
 */
export const useApiFeatureFlags = () => {
  const { userToken, host } = useSession();
  const [result, refetch] = useAxios<FeatureFlags>({}, { manual: true });
  useEffect(() => {
    if (host && userToken) {
      refetch({
        url: `${host}/feature_flags`,
        headers: { "X-API-KEY": userToken }
      });
    }
  }, [userToken, host]);
  return result;
};

/**
 * Get all Retailer Subscriptions for a Client.
 */
export const useApiRetailerSubscriptions = () => {
  const { clientUuid, userToken, host } = useSession();
  const [result, refetch] = useAxios<RetailerSubscriptions>({}, { manual: true });

  useEffect(() => {
    if (host && userToken) {
      refetch({
        url: `${host}/clients/${clientUuid}/retailer_subscriptions`,
        headers: { "X-API-KEY": userToken }
      });
    }
  }, [userToken, host]);
  return result;
};

/**
 * Get a client's calendar information.
 */
export const useApiCalendar = () => {
  const { clientUuid, userToken, host } = useSession();
  const [result, refetch] = useAxios<CalendarResponse>({}, { manual: true });
  useEffect(() => {
    if (host && clientUuid && userToken) {
      refetch({
        url: `${host}/clients/${clientUuid}/calendar`,
        headers: { "X-API-KEY": userToken }
      });
    }
  }, [clientUuid, userToken, host]);
  return result;
};
