import orderBy from "lodash/fp/orderBy";

import * as Preference from "lib/constants/preferences";
import { RetailerSubscriptions, Retailer } from "lib/types/retailerSubscriptions";
import { SUBSCRIPTION_TYPE_IDS } from "lib/constants/subscriptions";

import { app } from "./app";
import { RETAILERS_TAG_STORE } from "lib/constants/apiUrl";

export type updateRetailerAvailabilityHandlerParams = {
  retailerId: number;
  disabled: boolean;
};

const orderRetailersByName = orderBy("name", "asc");

export const setRetailerSubscriptions = app.createEvent<RetailerSubscriptions>();
export const selectRetailer = app.createEvent<number>();
export const updateRetailerAvailability = app.createEvent<updateRetailerAvailabilityHandlerParams>();

const setRetailerSubscriptionsHandler = (_: any, retailerSubscriptions: RetailerSubscriptions) => {
  let configuredDefaultRetailerId = Number(Preference.Retailers.getValue()) || 1;
  const configuredDefaultRetailer = retailerSubscriptions.find((r: Retailer) => r.id === configuredDefaultRetailerId);

  if (!configuredDefaultRetailer?.subscription) {
    const firstRetailerWithSubscription = retailerSubscriptions.find((r: Retailer) => r.subscription != null);
    configuredDefaultRetailerId = firstRetailerWithSubscription?.id || configuredDefaultRetailerId;
  }

  const retailers = retailerSubscriptions.map((rs) => {
    return {
      ...rs,
      isSelected: configuredDefaultRetailerId === rs.id
    };
  });

  return orderRetailersByName(retailers) as RetailerSubscriptions;
};

const selectRetailerHandler = (state: RetailerSubscriptions, retailerId: number) => {
  const retailers = state.reduce(
    (acc: RetailerSubscriptions, r: Retailer) => [...acc, { ...r, isSelected: r.id === retailerId }],
    [] as RetailerSubscriptions
  );

  return orderRetailersByName(retailers) as RetailerSubscriptions;
};

const updateRetailerAvailabilityHandler = (
  state: RetailerSubscriptions,
  params: updateRetailerAvailabilityHandlerParams
) => {
  const retailers = state.reduce(
    (acc: RetailerSubscriptions, r: Retailer) => [
      ...acc,
      { ...r, isDisabledInPage: r.id === params.retailerId ? params.disabled : r.isDisabledInPage }
    ],
    [] as RetailerSubscriptions
  );

  return orderRetailersByName(retailers) as RetailerSubscriptions;
};

export const $retailerSubscriptions = app
  .createStore([] as RetailerSubscriptions)
  .on(setRetailerSubscriptions, setRetailerSubscriptionsHandler)
  .on(selectRetailer, selectRetailerHandler)
  .on(updateRetailerAvailability, updateRetailerAvailabilityHandler);

export const getSelectedRetailer = () => $retailerSubscriptions.getState().find((r) => r.isSelected);

export const getSelectedRetailerSubscriptionId = () =>
  getSelectedRetailer()?.subscription?.id || SUBSCRIPTION_TYPE_IDS.NONE;

export const getSelectedRetailerId = () => getSelectedRetailer()?.id || 1;

export const getSelectedRetailerWebTag = (): string => RETAILERS_TAG_STORE[getSelectedRetailerId()];

export const getRetailerRedirectionURL = (tld: string) => {
  const selectedRetailer = getSelectedRetailer();
  const retailerName = selectedRetailer?.name?.toLowerCase() || "amazon";
  const retailerLinkTag = getSelectedRetailerWebTag();
  return `https://www.${retailerName}.${tld}/${retailerLinkTag}`;
};

export const disableRetailer = (retailerId: number) => updateRetailerAvailability({ retailerId, disabled: true });
export const enableRetailer = (retailerId: number) => updateRetailerAvailability({ retailerId, disabled: false });
