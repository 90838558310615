import React from "react";
import { ACTION_TYPES } from "../../units/market-share/RootState/constants";
import useRootState from "../../units/market-share/RootState/hooks/useRootState";

const useCurrencyHook = () => {
  const { state, dispatch } = useRootState();
  const data = state?.currencyData;

  const getCurrencyData = (payload) => dispatch({ type: ACTION_TYPES.GET_CURRENCY_DATA, payload });

  return React.useMemo(
    () => ({
      currencyData: data,
      getCurrencyData
    }),
    [data, getCurrencyData]
  );
};

export default useCurrencyHook;
