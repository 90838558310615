import { createDomain, forward, Unit } from "effector";
import * as Wrappers from "./utils/wrappers";
import { AppMode } from "../types/app";
import { Debug } from "lib/debug";

export const app = createDomain("app");

app.onCreateStore(($store) => {
  const name = $store.compositeName.fullName.split("/").slice(1).join(":");
  const debug = Debug(`store:${name}`, `hsl(280, 10%, 25%)`);
  let once = false;
  $store.watch((...args) => {
    try {
      /* eslint-disable */
      once && debug(...args);
      /* eslint-enable */
      once = true;
    } catch (error) {
      console.warn(error);
    }
  });
});

export const { storeSetGetUse, getUseTuple, getUse, withConfig } = Wrappers.withConfig({
  domain: app
});

export const [$appMode, setAppMode, getAppMode, useAppMode] = storeSetGetUse<AppMode>("initializing", "mode");
