import styled from "styled-components/macro";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";
import css from "@styled-system/css";

interface ThemeBaseProps extends SpaceProps, LayoutProps {}

// This applies the main background and typically inherited styles such as font
const ThemeBase = styled.div<ThemeBaseProps>(
  css({
    boxSizing: "border-box",
    fontFamily: "body",
    fontSize: 1,
    lineHeight: "body",
    color: "text",
    bg: "background"
  }),
  layout,
  space
);

export default ThemeBase;
