import React from "react";
import { ACTION_TYPES } from "../constants";
import useRootState from "./useRootState";

// Hook to get and set the Route state
const usePageRefreshRoute = () => {
  const { state, dispatch } = useRootState();
  const setRoute = React.useMemo(
    () => (payload) => dispatch({ type: ACTION_TYPES.SET_ROUTE_FOR_REFRESH, payload }),
    [dispatch]
  );
  return React.useMemo(
    () => ({
      state: state.route,
      setRoute
    }),
    [state, setRoute]
  );
};

export default usePageRefreshRoute;
