import React, { useState } from "react";
import { useStore } from "effector-react";
import styled from "styled-components/macro";
import { Button } from "lib/components/Button/Button";
import CustomSearch from "lib/drawers/CustomClientDrawer/searchBox";
import { $featureFlags, setFeatureFlags } from "lib/stores/featureFlags";
import { Panel } from "./Panel";

export const FeatureFlags = () => {
  const featureFlags = useStore($featureFlags);
  const [text, setText] = useState("");
  
  return (
    <Panel>
      <Header>
        <h2>Launch Darkly Feature Flags</h2>
        <CustomSearch searchedText={text} setSearchedText={setText} selectedTab="Feature Flags" />
      </Header>
      <Inner>
        {Object.entries(featureFlags).map(
          ([key, value]) =>
            key?.toLowerCase().includes(text?.toLowerCase()) && (
              <Button key={key} css="padding: 0" onClick={(_) => toggleFeatureFlag({ [key]: !value })} fill>
                <input type="checkbox" checked={value as boolean} onChange={() => {}} />
                <span>{key}</span>
              </Button>
            )
        )}
      </Inner>
    </Panel>
  );
};

const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  > div {
    margin-left: 0.5em;
  }
`;

const Inner = styled.div`
  color: white;
  flex: 1;
  text-align: left;
  max-height: 11vh;
  overflow: auto;

  input[type="checkbox"] {
    margin-right: 0.5rem;
  }

  span {
    text-align: left;
    align-self: flex-start;
    flex: 1;
    color: white;
    justify-content: left;
  }
`;

function toggleFeatureFlag(featureFlag) {
  setFeatureFlags(featureFlag);
  setTimeout(() => {
    window.location.reload();
  }, 100);
}
