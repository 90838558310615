import React from "react";
import styled from "styled-components/macro";
import {
  color,
  ColorProps,
  compose,
  flex,
  FlexProps,
  justifyContent,
  JustifyContentProps,
  justifyItems,
  JustifyItemsProps,
  layout,
  LayoutProps,
  space,
  SpaceProps
} from "styled-system";

export interface ButtonProps {
  css?: string;
  active: boolean;
  bare: boolean;
  compact: boolean;
  cursor: "default" | "pointer";
  fill: boolean;
  disabled: boolean;
  invert: boolean;
  primary: boolean;
  round: boolean;
  solid: boolean;
  underline: ButtonUnderline;
}

type ButtonUnderline =
  | "active"
  | "focus"
  | "hover"
  | "active focus"
  | "active hover"
  | "active focus hover"
  | "focus hover";

type ButtonMergedProps<T> = T &
  ColorProps &
  FlexProps &
  JustifyContentProps &
  JustifyItemsProps &
  LayoutProps &
  SpaceProps;

export const Button = styled.button.attrs<ButtonMergedProps<Partial<ButtonProps>>>((props) => ({
  // Default props
  /**
   * These checks for `unidentified` are necessary because boolean props
   * confuse styled components in typescript.
   */
  active: props.active !== undefined ? props.active : undefined,
  bare: props.bare !== undefined ? props.bare : undefined,
  compact: props.compact !== undefined ? props.compact : undefined,
  disabled: props.disabled !== undefined ? props.disabled : undefined,
  fill: props.fill !== undefined ? props.fill : undefined,
  invert: props.invert !== undefined ? props.invert : undefined,
  primary: props.primary !== undefined ? props.primary : undefined,
  round: props.round !== undefined ? props.round : true,
  solid: props.solid !== undefined ? props.solid : undefined,
  // Default attributes
  type: "button",
  tabIndex: props.disabled ? undefined : props.tabIndex || -1,
  ...props
}))<ButtonMergedProps<Partial<ButtonProps>>>`
  /* Static styles */
  align-items: center;
  align-self: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  height: var(--height);
  justify-content: center;
  line-height: var(--line-height);
  padding: 0 var(--space-x);
  transition: all var(--duration-fast);
  user-select: none;
  white-space: nowrap;

  &,
  &:focus,
  &:active,
  &:hover {
    outline: 0;
  }

  /* Custom properties */
  --height: 2rem;
  --line-height: var(--height);

  /* Dynamic styles */
  ${({ invert }) =>
    (invert &&
      `
    background-color: var(--accent-color);
    color: var(--backdrop-color);
  `) ||
    `
    background-color: var(--backdrop-color);
    color: var(--accent-color);
  `}
  ${({ bare }) =>
    (bare &&
      `
    border: 0 solid transparent;
  `) ||
    `
    border: inset 1px solid var(--border-color);
  `}
  ${({ bare, round }) =>
    !bare &&
    round &&
    `
    border-radius: 4px;
  `}
  ${({ active, cursor, disabled }) =>
    (cursor &&
      `
    cursor: ${cursor};
  `) ||
    ((active || disabled) &&
      `
    cursor: default;
  `) ||
    `
    cursor: pointer;
  `}
  ${({ underline }) =>
    underline &&
    `
    ${underline
      .split(" ")
      .map((x) => `&:${x}`)
      .join(", ")} {
      text-decoration: underline;
    }
  `}
  ${({ active, solid }) =>
    solid &&
    `
    ${active && "&,"}
    &:hover {
      background-color: var(--accent-color);
      color: var(--backdrop-color);
    }
  `}
  ${({ fill }) =>
    (fill &&
      `
    min-width: 100%;
  `) ||
    `
    &:where(:not(:first-child)) {
      margin-left: var(--space-y);
    }
  `}

  /* Custom properties */
  ${({ compact }) =>
    (compact &&
      `
    --space-x: var(--space-2);
  `) ||
    `
    --space-x: var(--space-3);
  `}
  ${({ solid }) =>
    (solid &&
      `
    --backdrop-color: var(--color-grey-95);
  `) ||
    `
    --backdrop-color: transparent;
  `}
  ${({ bare, disabled, primary }) =>
    (!disabled &&
      primary &&
      `
    --accent-color: var(--color-grey-20);
    ${!bare && "--border-color: var(--color-grey-70);"}
  `) ||
    (!disabled &&
      `
    --accent-color: var(--color-grey-20);
    ${!bare && "--border-color: var(--color-grey-90);"}
  `) ||
    `
    --accent-color: var(--color-disabled);
    ${!bare && "--border-color: var(--color-disabled);"}
  `}
  &:hover {
    ${({ bare, disabled, primary }) =>
      (!disabled &&
        primary &&
        `
      --accent-color: var(--color-primary);
      ${!bare && "--border-color: var(--color-green_50);"}
    `) ||
      (!disabled &&
        `
      --accent-color: var(--color-grey-15);
      ${!bare && "--border-color: var(--color-grey-70);"}
    `)}
  }
  ${({ active }) => active && "&,"}
  &:active {
    ${({ bare, disabled, primary }) =>
      (!disabled &&
        primary &&
        `
      --accent-color: var(--color-primary);
      ${!bare && "--border-color: var(--accent-color);"}
    `) ||
      (!disabled &&
        `
      --accent-color: var(--color-grey-15);
      ${!bare && "--border-color: var(--accent-color);"}
    `)}
  }

  ${({ css }) => css || ""}

  ${compose(color, flex, justifyContent, justifyItems, layout, space)}
`;
