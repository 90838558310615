import React from "react";
import { stringToDate } from "lib/utilities/date";
import { rollupForDateRange, useGetDisabledRoullups } from "units/market-share/utility/dateRange";

import { ACTION_TYPES } from "../constants";
import useRootState from "./useRootState";

// Hook to get and set the date range state
export default function useDateRange() {
  const { state, dispatch } = useRootState();

  const setDateRange = (payload) => {
    dispatch({ type: ACTION_TYPES.SET_DATE_RANGE, payload });
  };

  // Values computed from state for convenience
  const { start, end, weekStartsOn, firstWeekContainsDate } = state.date;

  const weekOptions = React.useMemo(
    () => ({ weekStartsOn, firstWeekContainsDate }),
    [weekStartsOn, firstWeekContainsDate]
  );

  const rollup = React.useMemo(() => {
    const interval = { start: stringToDate(start), end: stringToDate(end) };
    return rollupForDateRange(interval, weekOptions);
  }, [start, end, weekOptions]);

  const disabledRollups = React.useMemo(() => {
    const interval = { start: stringToDate(start), end: stringToDate(end) };
    return useGetDisabledRoullups(interval, weekOptions);
  }, [start, end, weekOptions]);

  return React.useMemo(
    () => ({
      state: state.date,
      weekOptions,
      rollup,
      setDateRange,
      disabledRollups
    }),
    [state, weekOptions, rollup, setDateRange, disabledRollups]
  );
}
