import React from "react";
import styled from "styled-components/macro";
import useAxios from "units/market-share/RootState/hooks/useAxios";
import { getEnv } from "units/market-share/utility/env";
import useAuth from "units/market-share/RootState/hooks/useAuth";
import { Payload as AuthPayload } from "lib/types/auth";
import { Form, Title, Field, Label, Input, Button, Terms, ForgotPasswordLink, End } from "./UI";

const ErrorLabel = styled.p`
  color: var(--color-red);
  margin: 0;
`;

interface LoginResponse {
  data: AuthPayload;
}

const useLogin = () => {
  const { REACT_APP_MS_API_NEW } = getEnv();

  return useAxios<AuthPayload>(
    {
      url: `${REACT_APP_MS_API_NEW}/auth/login`,
      method: "POST"
    },
    { manual: true }
  );
};

const setAuthToStorage = (payload: AuthPayload) => {
  window.localStorage.setItem("setup-ocr-admin", JSON.stringify(payload.data.admin));
  window.localStorage.setItem("setup-ocr-client", JSON.stringify(payload.data.client));
  window.localStorage.setItem("setup-ocr-admin-client", JSON.stringify(payload.data.admin_client));
  window.localStorage.setItem("setup-ocr-user", JSON.stringify(payload.data.user));
  window.localStorage.setItem("setup-ocr-jwts", JSON.stringify(payload.data.jwts));
  window.localStorage.setItem("setup-ocr-tokens", JSON.stringify(payload.tokens));
  window.localStorage.removeItem("logged-user-data");
};

enum FocusedField {
  Username,
  Password
}

const LoginForm: React.FC = function () {
  const { setAuth } = useAuth();
  const [result, execute] = useLogin();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [focusedField, setFocusedField] = React.useState<FocusedField>();

  React.useEffect(() => {
    if (result.data) {
      setAuthToStorage(result.data);
      setAuth(result.data);
    } else if (result.error) {
      setUsername("");
      setPassword("");
    }
  }, [result, setAuth]);

  const errorResponse = result.error?.response;

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        if (username && password && !result.loading) execute({ data: { username, password } });
      }}
    >
      <Title>Log in</Title>

      {errorResponse?.status === 400 ? (
        <ErrorLabel>Sorry, your details were not recognized. Please try again.</ErrorLabel>
      ) : null}

      <Field onFocus={() => setFocusedField(FocusedField.Username)} onBlur={() => setFocusedField(undefined)} mb={32}>
        <Label variant={focusedField === FocusedField.Username ? "active" : undefined}>Username</Label>
        <Input
          name="username"
          type="text"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          placeholder="Username"
          required
        />
      </Field>
      <Field onFocus={() => setFocusedField(FocusedField.Password)} onBlur={() => setFocusedField(undefined)}>
        <Label variant={focusedField === FocusedField.Password ? "active" : undefined}>Password</Label>
        <Input
          name="password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          placeholder="Password"
          required
          css={{ letterSpacing: password ? 3 : 0 }}
        />
      </Field>
      <div css="text-align: right; margin: -8px 0 16px">
        <ForgotPasswordLink to="/forgot-password">Forgot Password?</ForgotPasswordLink>
      </div>
      <Terms>
        Unless your company has a separate written agreement for the Service, by clicking “Log in” below, you confirm
        your agreement to be bound by{" "}
        <a
          href="https://www.ascentialedge.com/edge-ascential-general-terms-business"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ascential’s General Terms of Business (including the Digital Service Modules)
        </a>
        .
      </Terms>
      <End>
        <Button type="submit" disabled={!username || !password}>
          {result.loading ? "Logging in" : "Log in"}
        </Button>
      </End>
    </Form>
  );
};

export default LoginForm;
