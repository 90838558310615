import React from "react";

/**
 * Components that are mounted will not abort rendering cycles.
 * This can cause problems when functions and components are called
 * on the unmounted components. This hook returns a function that
 * can be used to check the current mounted status of a component.
 *
 * Usage:
 *
 *     const mounted = useMounted();
 *
 *     // Returns true if component is mounted, false if not.
 *     mounted();
 *
 *     // The first argument is a nullary function that
 *     // returns if the component is mounted.
 *     mounted(() => "value when mounted");
 *
 *     // The second optional argument is the fallback value,
 *     // which returns if the component if not mounted.
 *     mounted(() => "value when mounted", "fallback value;")
 */

type MountedArgs = [] | [() => any] | [() => any, any];

export default function useMounted(): (...args: MountedArgs) => any {
  const mounted = React.useRef<boolean>(true);

  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [mounted.current]);

  return React.useCallback(
    (...args: MountedArgs) => {
      switch (args.length) {
        case 0:
          return mounted.current;
        case 1:
          return mounted.current ? args[0]() : undefined;
        case 2:
          return mounted.current ? args[0]() : args[1];
        default:
          return undefined;
      }
    },
    [mounted.current]
  );
}

export function Mounted({ children }: { children: any }) {
  return useMounted()(() => children, null);
}
