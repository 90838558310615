import type { CalendarResponseItem } from "units/market-share/endpoints/core/calendar";
import type { CalendarItem } from "units/market-share/types/calendar";
import React from "react";
import sortBy from "lodash/fp/sortBy";
import { addWeeks, differenceInDays, stringToDate } from "lib/utilities/date";
import { addDays, getTime, parseISO } from "date-fns";
import { findLastTimeFrame, findThisTimeFrame, getMonthRange } from "units/market-share/utility/calendar";
import useRootState from "./useRootState";
import { ACTION_TYPES } from "../constants";

type SameWeekArg = Pick<CalendarItem, "year" | "week">;
type SameMonthArg = Pick<CalendarItem, "year" | "month">;
type SameQuarterArg = Pick<CalendarItem, "year" | "quarter">;
type SameYearArg = Pick<CalendarItem, "year">;

export class WeekCalendarItem implements CalendarItem {
  readonly weekLength: number;

  readonly timestamp: number;

  constructor(
    readonly date: string,
    readonly week: number,
    readonly month: number,
    readonly quarter: number,
    readonly year: number,
    readonly isReleased: boolean
  ) {
    this.timestamp = +stringToDate(date);
    this.weekLength = getTime(addDays(parseISO(date), 6)) - getTime(parseISO(date));
  }

  matchesDate(date: number | Date | string, isInitialLoad = false, isNotDateFormatTickX = false) {
    let currentdiff = 0;
    let currentdiffWeek = 0;
    let timestamp;
    if (typeof date === "string") {
      timestamp = +stringToDate(date);
      currentdiff = differenceInDays(stringToDate(date), stringToDate(this.date));
      currentdiffWeek = differenceInDays(stringToDate(date), addWeeks(stringToDate(this.date), 1));
    } else {
      timestamp = +date;
      currentdiff = differenceInDays(date, stringToDate(this.date));
      currentdiffWeek = differenceInDays(date, addWeeks(stringToDate(this.date), 1));
    }

    if (isNotDateFormatTickX || isInitialLoad) {
      // Added on 10-05-2021, Time stamp showing different  dates for this.timestampEnd on different time zones so added below date comparison
      return currentdiff >= 0 && currentdiffWeek < 0;
      // return timestamp >= this.timestamp && timestamp <= this.timestampEnd;
      // End on 10-05-2021
    }
    return timestamp === this.timestamp;
  }

  get timestampEnd() {
    return this.timestamp + this.weekLength;
  }

  static sort(a: CalendarItem, b: CalendarItem) {
    return a.timestamp - b.timestamp;
  }

  static map(data: CalendarResponseItem[]) {
    const values = sortBy(["date", "year", "quarter", "month", "week"], data);

    return values.reduce<WeekCalendarItem[]>((acc, item) => {
      const prev = acc[acc.length - 1];
      if (prev && item.date === prev.date) return acc;

      acc.push(new WeekCalendarItem(item.date!, item.week!, item.month!, item.quarter!, item.year!, item.is_released!));
      return acc;
    }, []);
  }

  static isSameWeek(a: SameWeekArg, b: SameWeekArg) {
    return a.year === b.year && a.week === b.week;
  }
  static isSameMonth(a: SameMonthArg, b: SameMonthArg) {
    return a.year === b.year && a.month === b.month;
  }
  static isSameQuarter(a: SameQuarterArg, b: SameQuarterArg) {
    return a.year === b.year && a.quarter === b.quarter;
  }
  static isSameYear(a: SameYearArg, b: SameYearArg) {
    return a.year === b.year;
  }
}

const useCalendar = () => {
  const { state, dispatch } = useRootState();
  const items = state.calendar.items as CalendarItem[];
  const selection = state.calendar.selection;
  const originalSelection = state.calendar.originalSelection as string;
  const isInitialLoad = state.calendar.isInitialLoad as boolean;

  const setInitialLoad = React.useCallback(
    (payload: any) => {
      return dispatch({ type: ACTION_TYPES.CALENDAR.SET_INITIAL_LOAD, payload });
    },
    [dispatch]
  );

  const setSelection = React.useCallback(
    (payload: any) => {
      return dispatch({ type: ACTION_TYPES.CALENDAR.SET_SELECTION, payload });
    },
    [dispatch]
  );

  const setOriginalSelection = React.useCallback(
    (payload: any) => {
      return dispatch({ type: ACTION_TYPES.CALENDAR.SET_ORIGINAL_SELECTION, payload });
    },
    [dispatch]
  );

  const setItems = React.useCallback(
    (data: CalendarResponseItem[]) => {
      return dispatch({ type: ACTION_TYPES.CALENDAR.SET_ITEMS, payload: WeekCalendarItem.map(data) });
    },
    [dispatch]
  );

  const getItemForDate = React.useCallback(
    (date: string | number | Date, isInitialyLoad = false, isNotDateFormatTickX = false) => {
      return items.find((item) => {
        return item.matchesDate(date, isInitialyLoad, isNotDateFormatTickX);
      });
    },
    [items]
  );

  const currentDate = React.useMemo(() => {
    return getItemForDate(Date.now(), isInitialLoad, true);
  }, [isInitialLoad, getItemForDate]);

  const getCurrentDate = React.useCallback(() => {
    if (!currentDate) return {};
    const weekItems = findLastTimeFrame(items, currentDate, "week", 1);
    return weekItems.length === 0 ? {} : weekItems[0];
  }, [currentDate, items]);

  const createGetQuickFn = React.useCallback(
    (fn: (...args: any[]) => CalendarItem[]) => () => {
      if (!currentDate || items.length === 0) return {};
      const matched = fn(currentDate);
      if (!matched.length) return {};
      const start = parseISO(matched[0]?.date);
      const end = addDays(parseISO(matched[matched.length - 1]?.date), 6);
      const isReleased = matched.some((item) => item.isReleased);
      return { start, end, isReleased };
    },
    [currentDate, items]
  );

  // Since this is the quick toggle, base last quarter on current date
  const getQuickLastYear = createGetQuickFn((date) => findLastTimeFrame(items, date, "year", 0));
  const getQuickLastQuarter = createGetQuickFn((date) => findLastTimeFrame(items, date, "quarter", 0));
  const getQuickLastMonth = createGetQuickFn((date) => findLastTimeFrame(items, date, "month", 0));
  const getQuickLastWeek = createGetQuickFn((date) => findLastTimeFrame(items, date, "week", 1));
  const getQuickThisYear = createGetQuickFn((date) => findThisTimeFrame(items, date, "year"));
  const getQuickThisQuarter = createGetQuickFn((date) => findThisTimeFrame(items, date, "quarter"));
  const getQuickThisMonth = createGetQuickFn((date) => findThisTimeFrame(items, date, "month"));
  const getQuickFourWeeksToDate = createGetQuickFn((date) => findLastTimeFrame(items, date, "week", 4));
  const getQuickThirteenWeeksToDate = createGetQuickFn((date) => findLastTimeFrame(items, date, "week", 13));
  const getQuickTwentySixWeeksToDate = createGetQuickFn((date) => findLastTimeFrame(items, date, "week", 26));
  const getQuickFiftyTwoWeeksToDate = createGetQuickFn((date) => findLastTimeFrame(items, date, "week", 52));
  const getQuickThirteenMonthsToDate = createGetQuickFn((date) => getMonthRange(items, "week", date, 13));
  const getQuickTwentyFiveMonthsToDate = createGetQuickFn((date) => getMonthRange(items, "week", date, 25));

  const presetData = React.useMemo(
    () => ({
      THIS_MONTH: getQuickThisMonth(),
      THIS_QUARTER: getQuickThisQuarter(),
      THIS_YEAR: getQuickThisYear(),
      LAST_MONTH: getQuickLastMonth(),
      LAST_QUARTER: getQuickLastQuarter(),
      LAST_YEAR: getQuickLastYear(),
      FOUR_WEEKS_TO_DATE: getQuickFourWeeksToDate(),
      THIRTEEN_WEEKS_TO_DATE: getQuickThirteenWeeksToDate(),
      TWENTYSIX_WEEKS_TO_DATE: getQuickTwentySixWeeksToDate(),
      FIFTYTWO_WEEKS_TO_DATE: getQuickFiftyTwoWeeksToDate()
    }),
    [items]
  );

  const defaultSelection =
    selection === "NONE"
      ? "NONE"
      : presetData[selection]?.isReleased
      ? selection
      : presetData.THIS_QUARTER.isReleased
      ? "THIS_QUARTER"
      : "LAST_QUARTER";

  const defaultPreset = presetData[defaultSelection];

  return {
    getCurrentDate,
    getItemForDate,
    getQuickFiftyTwoWeeksToDate,
    getQuickFourWeeksToDate,
    getQuickLastMonth,
    getQuickLastQuarter,
    getQuickLastWeek,
    getQuickLastYear,
    getQuickThirteenMonthsToDate,
    getQuickThirteenWeeksToDate,
    getQuickThisMonth,
    getQuickThisQuarter,
    getQuickThisYear,
    getQuickTwentyFiveMonthsToDate,
    getQuickTwentySixWeeksToDate,
    isInitialLoad,
    items,
    originalSelection,
    presetData,
    defaultPreset,
    defaultSelection,
    selection,
    setInitialLoad,
    setItems,
    setOriginalSelection,
    setSelection
  };
};

export default useCalendar;
