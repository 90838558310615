import React from "react";
import axios from "axios";
import { getEnv } from "units/market-share/utility/env";
import useAxios from "units/market-share/RootState/hooks/useAxios";
import useAuth from "units/market-share/RootState/hooks/useAuth";
import useCurrencyHook from "lib/hooks/useCurrencyHook";

export const getUrlToDownloadReport = (params) => {
  /*
  parameters passed:
    start_date:string, (yyyy-mm-dd)
    end_date:string, (yyyy-mm-dd)
    number_of_items:integer, 4
    include_vat: boolean/integer (0 or 1)
  */
  const { clientUuid, apiKey, dates, ...downloadParams } = params;
  const { REACT_APP_MS_API_NEW } = getEnv();

  return axios.get({
    url: `${REACT_APP_MS_API_NEW}/clients/${clientUuid}/reports/fakeDownload`,
    method: "GET",
    headers: {
      "X-API-KEY": apiKey
    },
    params: {
      start_date: dates.start,
      end_date: dates.end,
      number_of_items: 4,
      include_vat: 0,
      ...downloadParams
    }
  });
};

export const useGetUsers = () => {
  const { state: auth } = useAuth();
  const { REACT_APP_MS_API_NEW } = getEnv();

  return useAxios({
    url: `${REACT_APP_MS_API_NEW}/clients/${auth.authInformation?.client?.uuid}/users`,
    method: "GET",
    headers: {
      "X-API-KEY": auth.authTokens.user
    },
    params: {
      client_uuid: auth.authInformation?.client?.uuid
    }
  });
};

//currency list API call
export const getCurrency = () => {
  const { REACT_APP_MS_API_NEW } = getEnv();
  const { state: auth } = useAuth();
  const { getCurrencyData } = useCurrencyHook();

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${REACT_APP_MS_API_NEW}/tld/currency`,
      method: "GET",
      headers: {
        "X-API-KEY": auth.authTokens.user
      }
    },
    { manual: true }
  );

  React.useEffect(() => {
    /* eslint-disable */
    if (error) console.error(error);
    /* eslint-enable */
    if (!loading && !error && data?.data) getCurrencyData(data.data);
  }, [data, loading]);

  return { data, loading, error, execute };
};
