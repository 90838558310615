/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
// Disabling because running into incorrect eslint typescript error.
/* eslint-disable */
import { CalendarItem } from "units/market-share/types/calendar";
/* eslint-enable */

type TimeSpan = "quarter" | "month" | "week" | "year";

interface TimeFrame {
  previousTimeFrameNumeral: number;
  previousTimeFrame: TimeSpan;
}

export const getMonthRange = (
  items: CalendarItem[],
  timeSpan: TimeSpan,
  currentDate: CalendarItem,
  numberToTraverse: number
) => {
  const firstIndexOfCurrent = items.findIndex(
    (item) => item.year === currentDate.year && item[timeSpan] === currentDate[timeSpan]
  );

  let latestReleasedWeekIndex: number;

  for (let i: number = firstIndexOfCurrent; i >= 0; --i) {
    if (items[i].isReleased) {
      latestReleasedWeekIndex = i;
      break;
    }
  }
  const monthRange = [0].map((x) => {
    if (numberToTraverse) {
      const temp = {
        ...items[latestReleasedWeekIndex],
        month: items[latestReleasedWeekIndex].month - 1 === 0 ? 12 : items[latestReleasedWeekIndex].month - 1,
        year:
          numberToTraverse === 25 ? items[latestReleasedWeekIndex].year - 2 : items[latestReleasedWeekIndex].year - 1
      };

      return items.findIndex((val) => {
        if (val.year === temp.year) {
          if (val.month === temp.month) {
            return val;
          }
        }
        return null;
      });
    }
    return null;
  });

  // return date range array
  const something = monthRange[0] ? monthRange[0] : 0;
  return items.filter((x, index) => index > something && index < latestReleasedWeekIndex + 1);
};

export const findLastTimeFrame = (
  items: CalendarItem[],
  currentItem: CalendarItem,
  timeSpan: TimeSpan,
  numberOfWeeks: number
) => {
  const firstIndexOfCurrent = items.findIndex(
    (item) => item.year === currentItem.year && item[timeSpan] === currentItem[timeSpan]
  );

  const relevantItems = items.slice(0, firstIndexOfCurrent);
  const lastItem = relevantItems[relevantItems.length - 1];

  let latestReleasedWeekIndex: number;
  for (let i: number = firstIndexOfCurrent; i >= 0; --i) {
    if (items[i].isReleased) {
      latestReleasedWeekIndex = i;
      break;
    }
  }

  const oldestIndexOfCurrent = items.findIndex((item, index) => latestReleasedWeekIndex - index <= numberOfWeeks);

  const result =
    numberOfWeeks < 1
      ? relevantItems.filter(
          (item) => item.year === lastItem.year && item[timeSpan] === lastItem[timeSpan] && item.isReleased
        )
      : relevantItems.filter((item, index) => index > oldestIndexOfCurrent && index <= latestReleasedWeekIndex);
  return result;
};

export const findThisTimeFrame = (items: CalendarItem[], currentItem: CalendarItem, timeSpan: TimeSpan = "quarter") => {
  return items.filter(
    (item) => item.year === currentItem.year && item[timeSpan] === currentItem[timeSpan] && item.isReleased
  );
};
