/* eslint-disable no-continue, react/jsx-boolean-value */

import React from "react";
import styled, { css } from "styled-components/macro";
import { useDev } from "lib/stores/dev";
import { useDevLocked } from "lib/stores/devLocked";
import { Actions } from "./Actions";
import { ApiUrls } from "./ApiUrls";
// import { Bindings } from "./Bindings";
import { Features } from "./Features";
import { Footer } from "./Footer";
import { FeatureFlags } from "./FeatureFlags";

export default function DevPanels() {
  const dev = useDev();
  const locked = useDevLocked();
  const isDevEnvironment = process.env.REACT_APP_CODE_ENV === "development";

  return !dev ? null : (
    <Container locked={locked}>
      <div className="content">
        {isDevEnvironment && <FeatureFlags />}
        <Features />
        <ApiUrls />
        <Actions />
        {/* <Bindings /> */}
      </div>
      <Footer />
    </Container>
  );
}

const Container = styled.div<{ locked: boolean }>`
  --shrunken-width: 4rem;
  --expanded-width: 34rem;

  border-radius: var(--space-1);
  bottom: 0;
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-direction: column;
  left: 0;
  margin-bottom: var(--space-3);
  margin-left: var(--space-3);
  padding: 1rem;
  position: fixed;
  z-index: var(--dev-panel-z-index);

  ${({ locked }) => (!locked ? "&," : "")}
  & {
    background-color: transparent;
    filter: drop-shadow(0 2px 2px hsla(0, 0%, 0%, 0.8));
    width: var(--shrunken-width);
    transition: all 0.5s, background-color 0 0.5s;
  }

  ${({ locked }) => (locked ? "&," : "")}
  &:hover {
    background-color: var(--color-green);
    filter: drop-shadow(0 2px 5px hsla(0, 0%, 0%, 0.2));
    width: var(--expanded-width);
    opacity: 1;
    transition: background-color 0, all 0.5s;
  }

  .content {
    flex: 1;
    margin-bottom: 1rem;
    max-height: 0;
    opacity: 0;
    overflow-y: hidden;
    pointer-events: none;
  }

  ${({ locked }) => (locked ? ".content," : "")}
  &:hover .content {
    opacity: 1;
    pointer-events: all;
    max-height: 100vh;
  }

  .columns {
    display: flex;
    flex-direction: row;

    & > * {
      box-sizing: border-box;
      flex: 1;
      padding-left: 1rem;
      padding-right: 1rem;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
`;
