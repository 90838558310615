import React from "react";
import { Route, Switch } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { css } from "styled-components/macro";

import ThemeBase from "lib/theme/ThemeBase";
import ResetPasswordForm from "./ResetPasswordForm";
import { Background, Content, Identity, ResetPasswordPanel, Wrapper } from "./UI";

const ResetPassword = (params) => {
  const currentYear = React.useMemo(() => new Date().getFullYear(), []);
  return (
    <ThemeBase minHeight="100vh" minWidth={848}>
      <Background>
        <Wrapper>
          <ResetPasswordPanel>
            <Identity />
            <Content>
              <Switch>
                <Route path="/reset-password">
                  <ResetPasswordForm {...params} />
                </Route>
              </Switch>
            </Content>
            <p css="position: absolute; top: 100%; left: 24px; color: #fff;">
              <FormattedMessage id="login.copyright" values={{ currentYear }} />
            </p>
          </ResetPasswordPanel>
        </Wrapper>
      </Background>
    </ThemeBase>
  );
};

export default ResetPassword;
