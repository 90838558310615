import { SEARCH_PARAM } from "lib/constants/search";
import { getSearch, setSearch } from "lib/stores/search";

export default function clearParams() {
  const searchParams = getSearch();
  for (const { Key } of Object.values(SEARCH_PARAM)) {
    if (searchParams[Key]) {
      searchParams[Key] = null;
    }
  }
  setSearch(searchParams);
}
