// See https://styled-system.com/theme-specification
/* eslint-disable */

const colors: Record<string, string | Record<string, string>> = {};

const rootPre: Record<string, string> = {};
const rootPost: Record<string, string> = {};

/* prettier-ignore */
const palette: [string[], [number, number, number, number]][] = [
//     CSS PROP             ALT NAME    FIGMA NAME          LEGACY NAME          HSLA                           HEX
  [["--color-grey_00",     "transparent"                                     ], [207.0,  17.3,  17.1, 0.00], /*           */ ],
  [["--color-grey_05",                                                       ], [207.0,  17.3,  17.1, 0.05], /*           */ ],
  [["--color-grey_10",                                                       ], [207.0,  17.3,  17.1, 0.10], /*           */ ],
  [["--color-grey_20",                                                       ], [207.0,  17.3,  17.1, 0.20], /*           */ ],
  [["--color-grey_30",                                                       ], [207.0,  17.3,  17.1, 0.30], /*           */ ],
  [["--color-grey_50",                                                       ], [207.0,  17.3,  17.1, 0.50], /*           */ ],
  [["--color-grey-10",     "black",     "normalText",       "text",          ], [207.0,  17.3,  17.1, 1.00], /* #242c33   */ ],
  [["--color-grey-10_70",               "tint",             "overlay"        ], [208.0,  17.2,  17.1, 0.70], /* #242c33b3 */ ],
  [["--color-grey-20",                                                       ], [208.0,  17.0,  17.0, 1.00], /* #394651   */ ],
  [["--color-grey-30",                                                       ], [209.0,  16.0,  11.0, 1.00], /* #424f5c   */ ],
  [["--color-grey-35",                  "primaryNavSelected"                 ], [225.0,   2.2,  35.7, 1.00], /* #495765   */ ],
  [["--color-grey-40",                  "columnHeaders",    "text2"          ], [206.0,   8.1,  38.9, 1.00], /* #5b646b   */ ],
  [["--color-grey-45",                                                       ], [209.0,  13.0,  45.0, 1.00], /* #647382   */ ],
  [["--color-grey-50",                                                       ], [209.0,  10.0,  53.0, 1.00], /* #7b8793   */ ],
  [["--color-grey-60",                                                       ], [209.0,  10.0,  60.0, 1.00], /* #8f99a3   */ ],
  [["--color-grey-70",                  "disabled",                          ], [210.0,   5.2,  69.4, 1.00], /* #adb1b5   */ ],
  [["--color-grey-75",                                                       ], [210.0,   3.2,  75.0, 1.00], /*           */ ],
  [["--color-grey-80",                                                       ], [209.0,   1.0,  82.0, 1.00], /* #cdd1d5   */ ],
  [["--color-grey-85",                                                       ], [209.0,   1.0,  88.0, 1.00], /* #dde1e4   */ ],
  [["--color-grey-90",                                                       ], [208.0,   1.0,  90.0, 1.00], /* #e5e5e5   */ ],
  [["--color-grey-92",                                                       ], [208.0,   1.0,  92.5, 1.00], /* #f6f7f9   */ ],
  [["--color-grey-95",                                                       ], [208.0,   1.0,  96.0, 1.00], /* #f7f7f7   */ ],
  [["--color-grey-97",                  "neutralGrey",      "background"     ], [  0.0,   0.0,  98.0, 1.00], /* #fafafa   */ ],
  [["--color-grey-98",                  "backgroundGrey",   "background3"    ], [  0.0,   0.0,  99.2, 1.00], /* #fdfdfd   */ ],
  [["--color-grey-99",     "white",                         "background2"    ], [  0.0,   0.0, 100.0, 1.00], /* #ffffff   */ ],
  [["--color-green",                    "pureGreen",        "primary"        ], [157.5, 100.0,  31.4, 1.00], /* #00a065   */ ],
  [["--color-green_05",    "highlight", "pureGreen5",       "positive"       ], [157.5, 100.0,  31.4, 0.05], /*           */ ],
  [["--color-green_10",                 "pureGreen10",                       ], [157.5, 100.0,  31.4, 0.10], /*           */ ],
  [["--color-green_20",                                                      ], [157.5, 100.0,  31.4, 0.20], /*           */ ],
  [["--color-green_30",                                                      ], [157.5, 100.0,  31.4, 0.30], /*           */ ],
  [["--color-green_40",                                                      ], [157.5, 100.0,  31.4, 0.40], /*           */ ],
  [["--color-green_50",                                                      ], [157.5, 100.0,  31.4, 0.50], /*           */ ],
  [["--color-green_70"                                                       ], [157.5, 100.0,  31.4, 0.70], /*           */ ],
  [["--color-green_80"                                                       ], [157.5, 100.0,  31.4, 0.80], /*           */ ],
  [["--color-green_90"                                                       ], [157.5, 100.0,  31.4, 0.90], /*           */ ],
  [["--color-green-90"                                                       ], [157.5,  47.1,  93.3, 1.00], /*           */ ],
  [["--color-green-10"                                                       ], [1145,  39,  22, 1.00], /* #008000   */ ],
  [["--color-light-green-10"                                                       ], [150,  28,  51, 1.00], /* #008000   */ ],
  [["--color-greenlight-10"                                                  ], [120.0,  73.0,  75.0, 1.00], /* #90ee90   */ ],
  [["--color-lime",                     "lime",                              ], [ 77.4,  68.9,  44.1, 1.00], /* #91be23   */ ],
  [["--color-red",                      "negativeRed",      "negative"       ], [  0.0, 100.0,  50.0, 1.00], /* #f00f00   */ ],
  [["--color-red_10",                                                        ], [  0.0, 100.0,  50.0, 0.10], /* #ff00001a */ ],
  [["--color-slate",                                        "secondary"      ], [228.0,   5.1,  19.8, 1.00], /* #303135   */ ],
  [["--color-wheat-39",                                                      ], [ 39.0,  77.0,  83.0, 0.39], /* #f5deb363   */ ],
  [["--color-tomato-10",                                                     ], [  9.0, 100.0,  64.0, 1.00], /* #ff6347   */ ],
  [["--color-eton-blue",                                                     ], [ 155.0, 39.0, 64.0, 1.00], /* #80C7AA  */ ],
  [["--color-blue-green",                                                     ], [ 155.0, 82.0, 17.0, 1.00], /* #084f32  */ ],
  [["--color-blue-light",                                                     ], [ 203.0, 92.0, 75.0, 0.50], /* #084f32  */ ],
  [["--color-capri",                            ], [193.0, 100.0, 49.0, 1.0], /* #00C2F9      */],
  [["--color-grey-new",                            ], [208.0, 8.0, 39.0, 1.0], /* #5C646B      */],
  [["--color-blue-green-60",], [ 155.0, 33.0, 58.0, 1.00], /* #  "#70b79a",",*/ ],
  [["--color-blue-green-70",  ], [ 155.0, 40.0, 35.0, 1.00], /* #  "#357c5",*/ ],
  [["--color-blue-green-80",], [ 155.0, 55.0, 25.0, 1.00], /* #  "#1d6447",",*/ ],
  [["--color-blue-green-90",                                                     ], [ 157.0, 48.0, 19.0, 1.00], /* #  "#194836",*/ ],
  [["--color-pink-10" ], [ 325.0, 16.0, 74.0, 1.00], /* #  "#C7B1BE",",*/ ],
  [["--color-pink-20",], [ 324.0, 13.0, 53.0, 1.00], /* #  "#96788A",",*/ ],
  [["--color-pink-30",], [ 326.0, 19.0, 48.0, 1.00], /* #  "#91637D",",*/ ],
  [["--color-pink-40",], [ 326.0, 48.0, 40.0, 1.00], /* #  "#98356D",",*/ ],
  [["--color-pink-50",                            ], [325, 61, 55, 1.00], /*   */],
  [["--color-pink-60",], [ 326.0, 63.0, 36.0, 1.00], /* #  "#952263",",*/ ],
  [["--color-pink-90",                            ], [326, 85, 34, 1.00], /*   */],
  [["--color-blue",], [218.0, 52.0, 52.0, 1.00]],
  [["--color-orange",], [24.0, 84.0, 56.0, 1.00]]
];

for (const [[cssProp, ...aliases], [h, s, l, a]] of palette) {
  const cssDecl = `hsla(${h}, ${s}%, ${l}%, ${a})`;
  const cssVar = `var(${cssProp}, ${cssDecl})`;

  rootPost[cssProp] = cssDecl;
  colors[toCamelCase(cssProp.slice(2))] = cssVar;

  for (const camelAlias of aliases.filter((x) => x)) {
    rootPost[`--color-${toSpinalCase(camelAlias)}`] = cssVar;
    colors[camelAlias] = cssDecl;
  }
}

colors.chart = {
  jazzberryJam: "#9F0162",
  barbiePink: "#FF5AAF",
  aquamarine: "#00FCCF",
  frenchViolet: "#8400CD",
  dodgerBlue: "#008DF9",
  capri: "#00C2F9",
  plum: "#FFB2FD",
  carmine: "#A40122",
  outrageousOrange: "#FF6E3A",
  brightSpark: "#FFC33B",
  gray90: "#E5E5E5",
  darkGray: "#B7B7B7"
};

export const CHART_COLOR_KEYS = [
  "chart.jazzberryJam",
  "chart.barbiePink",
  "chart.aquamarine",
  "chart.frenchViolet",
  "chart.dodgerBlue",
  "chart.capri",
  "chart.plum",
  "chart.carmine",
  "chart.outrageousOrange",
  "chart.brightSpark"
];

type Space = Record<string | number, number>;
const legacySpace = [0, 4, 8, 16, 24, 32, 48, 64] as unknown as Space;

export const space = new Proxy(legacySpace, {
  get(target, key) {
    if (typeof key === "string" && key[0] === "x") {
      const n = parseFloat(key.slice(1).replace("_", "."));
      if (!Number.isNaN(n)) return n * 8;
    } else if (typeof key === "symbol") {
      return undefined;
    }
    return target[key];
  }
}) as typeof legacySpace;

export const breakpoints = [];

export const borders = {};

export const durations = { fast: 125, normal: 275, slow: 400, xslow: 650, xxslow: 1000 };

export const fonts = {
  body: "Roboto, sans-serif",
  heading: "inherit",
  monospace: "Menlo, monospace"
};

export const fontWeights = { body: 400, heading: 300, bold: 700 };

export const fontSizes = [10, 12, 14, 16, 20, 24, 32];

export const lineHeights = { body: 1.5, heading: 1.25 };

export const text = {};

export const sizes = {
  navBarHeight: 56,
  titleBarHeight: 56,
  footer: 40,
  contentWidth: 1280,
  overlayOffset: 114
};

export const radii = { graph: 4 };

export const shadows = {
  none: "0 0 0 0 transparent",
  thin: "0 0 1px -1px var(--color-black)"
};

export default {
  borders,
  breakpoints,
  colors,
  durations,
  fontSizes,
  fontWeights,
  fonts,
  lineHeights,
  shadows,
  sizes,
  space,
  text
};

(
  [
    ["--$", sizes, toPixel],
    ["--$-border-radius", radii, toPixel],
    ["--space-$", space, toPixel],
    ["--font-family-$", fonts, toString],
    ["--font-weight-$", fontWeights, toString],
    ["--font-size-$", fontSizes, toPixel],
    ["--line-height-$", lineHeights, toString],
    ["--shadow-$", shadows, toString],
    ["--duration-$", durations, toMs]
  ] as [string, Record<string, any> | number[], (x: string | number) => string][]
).forEach(([prefix, target, mapToString]) => {
  const keys = Array.isArray(target) ? Array.from(target.keys()) : Object.keys(target);
  for (const key of keys) {
    // If key is an index, increment it so we start at 1, otherwise
    // turn it into a CSS-friendly prop.
    const suffix = typeof key === "string" ? toSpinalCase(key) : `${key}`;
    const prop = prefix.replace("$", suffix);
    const decl = mapToString(target[key]);
    rootPre[prop] = decl;
  }
});

export const root = Object.assign({}, rootPre, rootPost);

export function themevar(prop: string) {
  if (!(prop in root)) {
    const allowed = Object.keys(root)
      .sort()
      .map((x: string) => `  ${x}`)
      .join("\n");
    const message = `CSS variable "${prop}" does not exist. Change it to one of the following:\n${allowed}`;
    console.error(message);
  }
  return `var(${prop})`;
}

// Helper functions

function toCamelCase(str: string) {
  return str.replace(/-([a-z0-9])?/g, (_, m1) => m1.toUpperCase());
}

function toSpinalCase(str: string) {
  return str.replace(/([a-z])([0-9A-Z])/g, (_, m1, m2) => `${m1}-${m2.toLowerCase()}`);
}

function toString(x: string | number) {
  return x.toString();
}

function toMs(x: string | number) {
  return typeof x === "string" ? x : `${x}ms`;
}

function toPixel(x: string | number) {
  return typeof x === "string" ? x : `${x}px`;
}
