import { app } from "./app";
import { getPane, setPane } from "./pane";
import { initialSearch } from "./search";

export type Download = boolean;

export const initialDownload = initialSearch?.pane === "download";

export const $download = app.createStore<Download>(initialDownload, { name: "download" });
export const setDownload = app.createEvent<Download>("setDownload");
export const getDownload = $download.getState;

$download.on(setDownload, (_state, payload) => payload);
$download.watch((download) => {
  if (download !== (getPane() === "download")) {
    setPane(download ? "download" : null);
  }
});
