import { getClientTld } from "lib/utilities/client";
import useAuth, { getClient } from "./useAuth";
import useCurrencyHook from "lib/hooks/useCurrencyHook";

export interface ICurrency {
  code: string;
  symbol: string;
  tld: string;
}
// Hook to get currency state
const useCurrency = (): ICurrency => {
  const { state: auth } = useAuth();
  const { currencyData } = useCurrencyHook();
  const client = getClient(auth);

  const currencyList =
    currencyData &&
    Object.keys(currencyData).reduce(
      (prev, current) => ({ ...prev, [current.toLowerCase()]: currencyData[current] }),
      {}
    );

  return client ? currencyList[getClientTld(client)] : {};
};

export default useCurrency;
