import React from "react";
import { isPaneOpen, setPane } from "lib/stores/pane";
import useRootState from "./useRootState";
import { ACTION_TYPES } from "../constants";

const useCustomDownload = () => {
  const { state, dispatch } = useRootState();
  const downloadSectionHeight = state.customDownloadDownloadHeight;

  const open = () => dispatch({ type: ACTION_TYPES.CUSTOM_DOWNLOAD, payload: true });

  const close = () => dispatch({ type: ACTION_TYPES.CUSTOM_DOWNLOAD, payload: false });

  const setDownloadSectionHeight = (payload) => {
    dispatch({ type: ACTION_TYPES.SET_CUSTOM_DOWNLOAD_SECTION_HEIGHT, payload });
    window.Debug("SET_DOWNLOAD_SECTION_HEIGHT", payload);
  };

  return React.useMemo(
    () => ({
      isOpen: isPaneOpen("download"),
      open: () => setPane("download"),
      close: () => setPane(null),
      downloadSectionHeight,
      setDownloadSectionHeight
    }),
    [state.customDownloadOpen, open, close, downloadSectionHeight, setDownloadSectionHeight]
  );
};

export default useCustomDownload;
