import React from "react";
import { useLocation } from "react-router-dom";

const useURL = () => {
  const location = useLocation();

  const urlParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const lowerCaseURLParams = React.useMemo(() => {
    const newParams = new URLSearchParams();
    for (const [name, value] of urlParams) {
      newParams.append(name.toLowerCase(), value);
    }
    return newParams;
  }, [urlParams]);

  return React.useMemo(
    () => ({
      urlParams,
      lowerCaseURLParams
    }),
    [urlParams, lowerCaseURLParams]
  );
};

export default useURL;
