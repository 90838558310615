import React from "react";
import zxcvbn from "zxcvbn";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";

import useAxios from "units/market-share/RootState/hooks/useAxios";
import { getEnv } from "units/market-share/utility/env";
import { ResetPassword } from "lib/types/auth";
import { Form, Title, Field, Label, Input, Button, End, ErrorLabel, Icon, BackToLoginLink } from "./UI";
import NewPasswordRules from "lib/pages/Profile/PasswordChange.tsx/components/newPasswordRules";

export const HyperLink = styled.a`
  color: blue;
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-body);
  text-decoration: none;
  padding-left: 4px;
  &:hover {
    text-decoration: underline;
  }
`;

export const ResetTitleContainer = styled(Title)`
  margin-bottom: 16px;
`;

const useResetPassword = (token: string, uuid: string) => {
  const { REACT_APP_MS_API_NEW } = getEnv();

  const [result, execute] = useAxios<ResetPassword>(
    {
      url: `${REACT_APP_MS_API_NEW}/password`,
      method: "PATCH"
    },
    { manual: true }
  );

  return React.useMemo(
    () => ({
      result,
      execute: (new_password: string) =>
        execute({
          data: { token, uuid, new_password }
        })
    }),
    [result, execute, token, uuid]
  );
};

enum FocusedField {
  Username,
  Password
}

const ResetPasswordForm = ({ token, uuid }: { token: string; uuid: string }) => {
  const { result, execute } = useResetPassword(token, uuid);
  const [newPassword, setNewPassword] = React.useState("");
  const [repeatPassword, setRepeatPassword] = React.useState("");
  const [passwordsAreEqual, setPasswordsAreEqual] = React.useState(true);
  const [passwordIsStrong, setPasswordIsStrong] = React.useState(true);

  const [focusedField, setFocusedField] = React.useState<FocusedField>();

  React.useEffect(() => {
    if (result.data) {
      window.location.replace("/login?rp=1");
    } else if (result.error) {
      setNewPassword("");
      setRepeatPassword("");
    }
  }, [result]);

  const errorResponse = result.error?.response;

  React.useEffect(() => {
    if (newPassword && repeatPassword) {
      setPasswordsAreEqual(newPassword === repeatPassword);
      setPasswordIsStrong(zxcvbn(newPassword).score >= 3);
    }
  }, [newPassword, repeatPassword]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (newPassword && repeatPassword && passwordsAreEqual && passwordIsStrong && !result.loading) execute(newPassword);
  };

  const backToLoginHandler = () => {
    window.location.replace("/login");
  };

  return (
    <Form onSubmit={onSubmitHandler}>
      <ResetTitleContainer>
        <FormattedMessage id="resetPassword.title" />
      </ResetTitleContainer>
      <NewPasswordRules pageId="Reset" />
      <Field onFocus={() => setFocusedField(FocusedField.Password)} onBlur={() => setFocusedField(undefined)}>
        <Label variant={focusedField === FocusedField.Password ? "active" : undefined}>Password</Label>
        <Input
          name="new-password"
          type="password"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
          placeholder="New Password"
          required
          css={{ letterSpacing: newPassword ? 3 : 0 }}
        />
      </Field>
      {!passwordIsStrong && (
        <ErrorLabel>
          <Icon className="fa fa-warning" />
          <FormattedMessage id="resetPassword.passwordIsNotStrong.errorMessage" />
        </ErrorLabel>
      )}
      <Field onFocus={() => setFocusedField(FocusedField.Password)} onBlur={() => setFocusedField(undefined)}>
        <Label variant={focusedField === FocusedField.Password ? "active" : undefined}>Password</Label>
        <Input
          name="repeat-password"
          type="password"
          value={repeatPassword}
          onChange={(event) => setRepeatPassword(event.target.value)}
          placeholder="Repeat Password"
          required
          css={{ letterSpacing: repeatPassword ? 3 : 0 }}
        />
      </Field>
      {!passwordsAreEqual && (
        <ErrorLabel>
          <Icon className="fa fa-warning" />
          <FormattedMessage id="resetPassword.passwordsAreNotEqual.errorMessage" />
        </ErrorLabel>
      )}
      <BackToLoginLink onClick={backToLoginHandler}>
        <FormattedMessage id="resetPassword.backToLogin.text" />
      </BackToLoginLink>
      {errorResponse && (
        <ErrorLabel>
          <Icon className="fa fa-warning" />
          {errorResponse.status === 401 ? (
            errorResponse.data?.message
          ) : (
            <>
              <FormattedMessage id="resetPassword.apiError" />
              <HyperLink
                data-testid="resetPassword_SupportLink"
                href="https://ascentialedge.zendesk.com/agent/dashboard"
                target="_blank"
                rel="noreferrer">
                <FormattedMessage id="resetPassword.supportLink" />
              </HyperLink>
            </>
          )}
        </ErrorLabel>
      )}
      <End>
        <Button type="submit" disabled={!newPassword || !repeatPassword || !passwordsAreEqual}>
          {result.loading ? "Reseting Password" : "Reset Password"}
        </Button>
      </End>
    </Form>
  );
};

export default ResetPasswordForm;
