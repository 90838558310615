export const ACTION_TYPES = {
  SET_DATE_RANGE: "SET_DATE_RANGE",

  SET_AUTH: "SET_AUTH",

  SET_SUBSCRIPTIONS: "SET_SUBSCRIPTIONS",

  SET_ROUTE_FOR_REFRESH: "SET_ROUTE_FOR_REFRESH",

  SET_CHART_OPTIONS: "SET_CHART_OPTIONS",

  SET_CURRENCY: "SET_CURRENCY",

  SET_CUSTOM_DOWNLOAD_SECTION_HEIGHT: "SET_CUSTOM_DOWNLOAD_SECTION_HEIGHT",

  DOWNLOAD_OPTIONS: {
    INIT: "DOWNLOAD_OPTIONS_INIT",
    SET_FILENAME: "DOWNLOAD_OPTIONS_SET_FILENAME"
  },

  FILTERS: {
    SET_OPEN_ID: "FILTERS_SET_OPEN_ID",
    SET_DATA: "FILTERS_SET_DATA",
    SET_EDITING: "FILTERS_SET_EDITING"
  },

  IN_PAGE_FILTERS: {
    ACTIVATE: "ACTIVATE",
    SETFILTER: "SETFILTER"
  },

  TABLE_VIEW_TYPE_OPTIONS: {
    INIT: "TABLE_VIEW_TYPE_OPTIONS_INIT",
    SET_TYPE: "TABLE_VIEW_TYPE_OPTIONS_SET_TYPE"
  },

  SCORE_CARD: {
    SET_ACTIVE_CARD: "SCORE_CARD_SET_ACTIVE_CARD",
    SET_LOOKER_CONTENT: "SCORE_CARD_SET_LOOKER_CONTENT"
  },

  STEPPERS: {
    SET_VALUE: "STEPPERS_SET_VALUE"
  },

  PORTFOLIO: {
    SET_ITEMS: "PORTFOLIO_SET_ITEMS",
    SET_BRANCHES: "PORTFOLIO_SET_BRANCHES",
    SET_SAVED_FILTERS: "PORTFOLIO_SET_SAVED_FILTERS",
    SET_SAVED_FILTER: "PORTFOLIO_SET_SAVED_FILTER",
    REMOVE_SAVED_FILTER: "PORTFOLIO_REMOVE_SAVED_FILTER",
    SET_LOADING: "PORTFOLIO_SET_LOADING",
    SET_ORIGINAL_FILTER: "SET_ORIGINAL_FILTER"
  },

  SET_PORTFOLIO_UUID: "SET_PORTFOLIO_UUID",

  CUSTOM_DOWNLOAD: "CUSTOM_DOWNLOAD",

  CALENDAR: {
    SET_ITEMS: "CALENDAR_SET_ITEMS",
    SET_INITIAL_LOAD: "CALENDAR_SET_INITIAL_LOAD",
    SET_SELECTION: "SET_SELECTION",
    SET_ORIGINAL_SELECTION: "SET_ORIGINAL_SELECTION"
  },

  SET_CUSTOM_FIELDS: "SET_CUSTOM_FIELDS",

  CREATE_USER: "CREATE_USER",

  USERS: {
    SET_EDIT_USER: "SET_EDIT_USER"
  },

  RATECARDS: {
    TOGGLE_RATECARD_DRAWER: "TOGGLE_RATECARD_DRAWER",
    IS_EDIT_RATECARD_DRAWER: "IS_EDIT_RATECARD_DRAWER",
    SAVE_DEFAULT_RATECARD_DATA: "SAVE_DEFAULT_RATECARD_DATA",
    UPDATE_RATE_CARDS: "UPDATE_RATE_CARDS",
    SHOW_RATECARD_INFO: "SHOW_RATECARD_INFO",
    SET_UPDATE_LOADING: "SET_UPDATE_LOADING",
    SET_RATE_CARDS: "SET_RATE_CARDS"
  },
  PROFITLOSS: {
    SET_PROFIT_LOSS_CARDS: "SET_PROFIT_LOSS_CARDS",
    SET_PROFIT_LOSS_SCENARIO: "SET_PROFIT_LOSS_SCENARIO"
  },
  RISK_CHART: {
    SET_SELECTED_RISK_PERIOD: "SET_SELECTED_RISK_PERIOD",
    SET_XDOMAIN: "SET_XDOMAIN",
    SET_PERIOD_END_DATE: "SET_PERIOD_END_DATE"
  },
  GET_CURRENCY_DATA: "GET_CURRENCY_DATA",
  SEARCH: {
    GET_DEPARTMENT_LIST: "GET_DEPARTMENT_LIST"
  },
  HEAT_MAP: {
    SET_XY: "SET_XY"
  },

  SEARCHV2: {
    GET_CLICKS_GRAPH_DATA: "GET_CLICKS_GRAPH_DATA",
    GET_CONVERSIONS_GRAPH_DATA: "GET_CONVERSIONS_GRAPH_DATA",
    GET_SEARCH_GRAPH_DATA: "GET_SEARCH_GRAPH_DATA",
    SET_WATCHLIST_DATA: "SET_WATCHLIST_DATA"
  }
};

export enum FILTERS {
  PORTFOLIO = "PORTFOLIO",
  CUSTOM_PORTFOLIO = "CUSTOM_PORTFOLIO",
  DATE = "DATE",
  CUSTOM_DATE = "CUSTOM_DATE"
}
