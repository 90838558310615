import React from "react";
import { HyperLink, passwordGenerator, Li, passwordRules, Ul } from "./passwordConst";

const NewPasswordRules = ({ pageId }: { pageId: string }) => (
  <Ul>
    {passwordRules(pageId).map((passwordRule) => (
      <Li key={passwordRule.id}>
        {passwordRule?.link && (
          <HyperLink href={passwordGenerator.url} target="_blank" rel="noreferrer">
            {passwordGenerator.title}
          </HyperLink>
        )}
        {passwordRule.rule}
        {passwordRule?.subRule?.map((subRule) => (
          <Ul key={subRule}>
            <li>{subRule}</li>
          </Ul>
        ))}
      </Li>
    ))}
  </Ul>
);

export default NewPasswordRules;
