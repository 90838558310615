import React from "react";
import * as ReactRouter from "react-router-dom";
import * as EffectorReact from "effector-react";
import { app } from "./app";

export type Pathname = string;

export const initialPathname = window.location.pathname;

export const $pathname = app.createStore<Pathname>(initialPathname, { name: "pathname" });
export const setPathname = app.createEvent<Pathname>("setPathname");
export const getPathname = $pathname.getState;
export const usePathname = () => {
  // Keep window pathname synced with store
  const location = ReactRouter.useLocation();
  const state = EffectorReact.useStore($pathname);
  React.useEffect(() => {
    if (state === location.pathname) return;
    setPathname(location.pathname);
  }, [location.pathname, state]);
  return state;
};

$pathname.on(setPathname, (_, pathname) => pathname);
