/* eslint-disable @typescript-eslint/prefer-as-const */
export type Feature = (typeof FEATURE)[keyof typeof FEATURE];

export type FeatureEntry<K extends Feature["Key"]> = [K, Extract<Feature, { Key: K }>["DefaultEnabled"]];

export type FeatureRecord<K extends Feature["Key"] = Feature["Key"]> = Record<
  K,
  Extract<Feature, { Key: K }>["DefaultEnabled"]
>;

export const FEATURE = {
  SearchFilter: {
    Key: "search" as "search",
    DefaultEnabled: false
  }
};

export const FEATURES = Object.values(FEATURE) as Feature[];

export const FEATURES_INITIAL = FEATURES.reduce(
  (acc, { Key, DefaultEnabled }) => ({ ...acc, [Key]: DefaultEnabled }),
  {}
) as FeatureRecord;
