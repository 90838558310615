import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import WebFont from "webfontloader";
import { enableMapSet } from "immer";

import App from "lib/app";

// Load stylesheets
import "normalize.css";
import "react-vis/dist/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Initialize macros
import "styled-components/macro";

import * as serviceWorker from "./serviceWorker";

// https://immerjs.github.io/immer/docs/complex-objects
enableMapSet();

// Load fonts
WebFont.load({
  google: {
    families: ["Roboto:300,400,700", "Titillium Web:300,400", "Roboto Mono"]
  }
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
