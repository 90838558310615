import { app } from "./app";

export type Title = string;

export const initialTitle = document.title;

export const $title = app.createStore<Title>(initialTitle, { name: "title" });
export const setTitle = app.createEvent<Title>("setTitle");
export const getTitle = $title.getState;

export const setTitleDocument = app.createEffect("setTitleDocument", {
  handler: (title: Title) => {
    document.title = title;
  }
});

$title.on(setTitle, (_, title) => `${title} | Market Share`);
$title.watch(setTitleDocument);
