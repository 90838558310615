import React from "react";
import { RootStateContext } from "../context";

import type { App } from "lib/types/app";

// Hook to get the entire root state and its dispatch function
export default function useRootState() {
  const context = React.useContext(RootStateContext);
  const state: App = context?.[0];
  const dispatch = context?.[1];

  return React.useMemo(() => ({ state, dispatch }), [state, dispatch]);
}
