// This avoids direct usage of process.env which allows
// it to be mocked easily in tests
import React from "react";
import { $apiSelection, useApiSelection } from "lib/stores/api";

export const getEnv = () => Object.assign(process.env, { REACT_APP_MS_API_NEW: $apiSelection.getState() });
export const getEnvHost = $apiSelection.getState;
export const getEnvNodeEnv = () => getEnv().NODE_ENV;
export const getEnvContext = () => ({
  host: getEnvHost(),
  nodeEnv: getEnvNodeEnv()
});

export const useEnv = getEnv;
export const useEnvHost = useApiSelection;
export const useEnvNodeEnv = () => useEnvContext().nodeEnv;
export const useEnvContext = () => React.useMemo(getEnvContext, []);
