import { useStore } from "effector-react";
import { createEvent, createStore } from "effector";
import { persistLocal } from "./utils/storage";

export const initialDev = ["1", "true"].includes(localStorage.getItem("dev") || "");

export const $dev = createStore<boolean>(initialDev, { name: "dev" });
export const setDev = createEvent<boolean>("setDev");
export const getDev = $dev.getState;
export const useDev = () => useStore($dev);

$dev.on(setDev, (_state, payload) => payload);

persistLocal<boolean>("dev", $dev);
