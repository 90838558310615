import React from "react";
import styled, { css } from "styled-components/macro";

export const Loading = styled.div`
  position: fixed;

  &,
  &:after {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &:after {
    content: "Loading...";
    color: var(--loading-color);
    animation: var(--loading-animation);
  }
`;

export const GraphLoading = styled(Loading)`
  position: relative;
  left: 1%;
`;

export const ChartLoading = styled(GraphLoading)`
  position: relative;
  left: -3rem;
  top: 12rem;
`;
