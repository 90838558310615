import React from "react";
import clearStorage from "lib/utilities/clearStorage";

const Logout: React.FC = function () {
  React.useEffect(() => {
    window.Intercom("shutdown");
    clearStorage("auth");
    window.localStorage.removeItem("logged-user-data");
    window.sessionStorage.clear();
    window.localStorage.removeItem("switched");
    window.location.replace("/login");
  });

  return null;
};

export default Logout;
