import type { Data } from "lib/types/filter";
import { app } from "./app";

export const initialFilterData = {};

export const $filterData = app.createStore<Data>(initialFilterData, {
  name: "filterData"
});
export const setFilterData = app.createEvent<Data>("setFilterdata");
export const getFilterData = $filterData.getState;

$filterData.on(setFilterData, (state, filterData) => ({ ...state, ...filterData }));
