import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components/macro";
import system from "./system";

const ThemeContext = function ({ children }) {
  return <ThemeProvider theme={system}>{children}</ThemeProvider>;
};

ThemeContext.propTypes = {
  children: PropTypes.node
};
ThemeContext.defaultProps = {
  children: null
};

export default ThemeContext;
