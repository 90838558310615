import Debug$ from "debug";

type ConsoleMethods = "debug" | "log" | "info" | "warn" | "error";

const applyConsoleArgs =
  (method: ConsoleMethods) =>
  (...args: any[]) => {
    args.splice(2, 2);
    const slicedArgs = args as [string, string, any];
    const parsedArgs = parseConsoleArgs(...slicedArgs);
    console[method].call(console, ...parsedArgs);
  };

const parseConsoleArgs = (prefix: string, colorRule: string, primary: any, ...args: any[]) => {
  const [namespace, domain, time] = prefix
    .split("%c")
    .filter((x) => x)
    .map((x) => x.trim());

  const formatter =
    typeof primary === "number" ? "%d" : typeof primary === "object" ? "%o" : typeof primary === "string" ? "%s" : "";

  const color = colorRule.slice(7);

  const styles = `
    background: ${color};
    color; white;
    font-size: 11px;
    line-height: 16px;
    text-shadow: 0 1px 1px black;
  `;

  const hasDomain = !/^-$/.test(domain);
  const hasTime = !/^\+[0-9]ms$/.test(time);
  const $domain = hasDomain ? domain : "";
  const $time = hasTime ? ` %c${time}%c ` : ` %c%c`;

  return [
    `%c${namespace}%c${$domain}%c${$time}${formatter}`,
    `${styles}
    border-right: 1px solid hsla(0, 0%, 0%, .25);
    border-radius: 3px 0 0 3px;
    font-style: italic;
    padding-left: 9px;
    padding-right: 6px;`,
    hasDomain
      ? `
    ${styles}
    border-left: 1px solid hsla(0, 0%, 100%, .25);
    border-radius: 0 3px 3px 0;
    padding-left: 6px;
    padding-right: 9px;`
      : ``,
    ``,
    `color: lightgray;
    font-size: 9px;`,
    ``,
    primary,
    ...args
  ];
};

const wrapDebugger = (func: typeof $app) => {
  let lastNamespace = "";
  let lastArgs = "";
  return (...args: Parameters<typeof func>) => {
    const [first, ...rest] = args;
    let $namespace: string;
    let $returns: any;
    let $args: any[];

    if (rest.length === 0) {
      $namespace = "-";
      $returns = first;
      $args = [first];
    } else if (typeof first === "string") {
      $namespace = first;
      $returns = rest[0];
      $args = rest;
    } else {
      $namespace = "-";
      $returns = first;
      $args = [first, ...rest];
    }

    if (lastNamespace === $namespace) {
      const currArgs = JSON.stringify($args);
      if (lastArgs !== currArgs) {
        lastArgs = currArgs;
        func($namespace, ...$args);
      }
    } else {
      lastNamespace = $namespace;
      lastArgs = JSON.stringify($args);
      func($namespace, ...$args);
    }

    return $returns;
  };
};

const $app = Debug$("app");

const $log = $app.extend("log");
$log.log = applyConsoleArgs("log");
$log.color = "#008855"; // green

const $info = $app.extend("info");
$info.log = applyConsoleArgs("info");
$info.color = "#0088ff"; // blue

const $warn = $app.extend("warn");
$warn.log = applyConsoleArgs("info");
$warn.color = "#bb9911"; // gold

const $error = $app.extend("error");
$error.log = applyConsoleArgs("error");
$error.color = "#882200"; // red

const $debug = $app.extend("debug");
$debug.log = applyConsoleArgs("debug");
$debug.color = "#777"; // gray

Object.assign(window, { $warn, $app, $log, $debug, $error });

const cache = new Map<string, any>();

function $Debug(namespace: string, color?: string) {
  if (!cache.has(namespace)) {
    const $debugger = $app.extend(namespace);
    $debugger.log = applyConsoleArgs("log");
    $debugger.color =
      color ||
      (() => {
        const hue = Math.floor(Math.random() * 360);
        const sat = Math.floor(Math.random() * 20) + 30;
        const lig = Math.floor(Math.random() * 10) + 30;
        return `hsl(${hue}, ${sat}%, ${lig}%)`;
      })();
    const $wrapped = wrapDebugger($debugger);
    cache.set(namespace, $wrapped);
  }

  return cache.get(namespace);
}

function $DebugWrap(namespace: string) {
  const debug = $Debug(`wrap:${namespace}`);
  return (fn: (...args: any[]) => any) => {
    return (...args: any[]) => {
      const result = fn(...args);
      /* eslint-disable */
      debug(result);
      /* eslint-enable */
      return result;
    };
  };
}

export { $Debug as default };
export { $Debug as Debug };
export { $DebugWrap as DebugWrap };

(window as any).Debug = $Debug;
(window as any).DebugWrap = $DebugWrap;
