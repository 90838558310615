import React, { useState, useRef } from "react";
import styled from "styled-components/macro";

interface SearchBoxProps {
  isFocused: boolean;
}
const SearchBoxWrapper = styled.div<SearchBoxProps>`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  input {
    background-color: inherit;
    border: 0;
    color: inherit;
    flex: 1;
    height: 3em;
    padding-bottom: 0;
    outline: 0;
    &:hover: {
      border: 1px solid var(--color-grey-70);
    }
    &::placeholder {
      color: var(--color-disabled);
      padding-left: ${(props) => (props.isFocused ? "0.25em" : "1.5em")};
      transition: padding-left var(--duration-normal);
    }
  }
`;

interface IconProps {
  search?: boolean;
}
const StyledIcon = styled.i<IconProps>`
  position: relative;
  left: ${(props) => (props.search ? "5%" : "-5%")};
  font-weight: 200;
  font-size: ${(props) => (props.search ? "inherit" : "18px")};
  cursor: ${(props) => (props.search ? "inherit" : "pointer")};
`;

const SearchIcon = function () {
  return <StyledIcon className="fa fa-search" search />;
};
const CancelIcon = function ({ onClick }) {
  return <StyledIcon className="fa fa-close" onClick={onClick} />;
};

const SearchBox = ({ searchedText, setSearchedText, selectedTab }) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const setFoucs = () => {
    searchRef.current?.focus();
    setIsFocused(true);
  };

  return (
    <SearchBoxWrapper isFocused={isFocused}>
      {!isFocused && !searchedText && <SearchIcon />}
      <input
        id={`${selectedTab}-search-display`}
        ref={searchRef}
        placeholder={`Search ${selectedTab}`}
        value={searchedText}
        onFocus={setFoucs}
        onChange={({ target }) => setSearchedText(target.value)}
        onPaste={(event) => {
          event.preventDefault();
          setSearchedText(event.clipboardData.getData("text"));
        }}
        autoComplete="off"
        spellCheck={false}
        tabIndex={-1}
      />
      {searchedText && <CancelIcon onClick={() => setSearchedText("")} />}
    </SearchBoxWrapper>
  );
};

export default SearchBox;
