import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import axios from "axios";
import { PartialLocation } from "history";

import { useApiSubscriptions } from "lib/hooks/useApi";
import Logout from "lib/pages/Logout";
import { Payload as AuthPayload } from "lib/types/auth";
import useAuth, { getAdmin } from "units/market-share/RootState/hooks/useAuth";
import useSubscriptions from "units/market-share/RootState/hooks/useSubscriptions";
import { getEnv } from "units/market-share/utility/env";
import { getCurrency } from "units/market-share/endpoints/core/index";
import useSubscriptionType from "lib/hooks/useSubscriptionType";
import { SUBSCRIPTION_TYPE_IDS } from "lib/constants/subscriptions";
import useURL from "lib/hooks/useURL";

import LoginComponent from "./Login";
import ResetPasswordComponent from "./ResetPassword";
import * as authStore from "../stores/auth";

export const getAuthFromStorage = () => {
  const auth = authStore.getAuth();
  if (!auth?.isAuthenticated) return undefined;
  return {
    tokens: auth.tokens,
    data: {
      user: auth.user,
      client: auth.client,
      admin: auth.adminUser,
      admin_client: auth.adminClient,
      jwts: auth.jwts
    }
  };
};

export const setAuthToStorage = (payload: AuthPayload) => {
  authStore.setAuth({
    adminUser: payload.data.admin,
    adminClient: payload.data.admin_client,
    user: payload.data.user,
    client: payload.data.client,
    jwts: payload.data.jwts,
    tokens: payload.tokens
  });
};

export enum Status {
  Pending = "PENDING",
  Login = "LOGIN",
  Loading = "LOADING_SUBSCRIPTIONS",
  Success = "SUCCESS",
  ResetPassword = "RESET_PASSWORD",
  Error = "ERROR"
}

interface Props {
  children: React.ReactNode;
  fallbackLocation: Location;
}

const Gatekeeper = ({ children, fallbackLocation }: Props) => {
  const location = useLocation();
  const { lowerCaseURLParams } = useURL();

  // keep reference to the original location so that we can return to it after login
  const originalLocation = React.useRef(location);

  const { state: authState, setAuth } = useAuth();
  const { isAuth, authTokens } = authState;
  const { setSubscriptions } = useSubscriptions();

  const [subscriptionsResult, requestSubscriptions] = useApiSubscriptions();
  const { execute: executeCurrency } = getCurrency();

  const hasBootedIntercom = React.useRef(false);

  const { REACT_APP_FORCE_LOGIN_CALL } = getEnv();

  const { getSubscriptionTypeId } = useSubscriptionType();

  React.useEffect(() => {
    if (String(REACT_APP_FORCE_LOGIN_CALL) === "true" && isAuth) {
      const { REACT_APP_MS_API_NEW } = getEnv();
      axios({
        method: "POST",
        url: `${REACT_APP_MS_API_NEW}/v2/user_auth/login_with_key`,
        data: { api_key: authTokens.user }
      }).then((res) => {
        setAuthToStorage(res.data);
      });
    }
  }, [isAuth]);

  const [status, setStatus] = React.useState(Status.Pending);

  const apiKey = React.useMemo(() => {
    if (isAuth || location.pathname !== "/login") return null;
    return lowerCaseURLParams.get("api_key");
  }, [isAuth, location]);

  const resetPasswordParams = React.useMemo(() => {
    if (isAuth || location.pathname !== "/reset-password") return null;
    return { token: lowerCaseURLParams.get("token"), uuid: lowerCaseURLParams.get("uuid") };
  }, [isAuth, location.pathname]);

  React.useEffect(() => {
    if (isAuth) return;

    if (apiKey) {
      setStatus(Status.Login);
      return;
    }

    if (resetPasswordParams) {
      setStatus(Status.ResetPassword);
      return;
    }

    const authPayload = getAuthFromStorage();
    if (authPayload) {
      setAuth(authPayload);
    } else {
      setStatus(Status.Login);
    }
  }, [setAuth, isAuth, apiKey]);

  React.useEffect(() => {
    if (status === Status.Success || status === Status.Error || status === Status.Loading) return;
    if (isAuth) requestSubscriptions();
  }, [isAuth, requestSubscriptions, status]);

  React.useEffect(() => {
    if (status === Status.Success || status === Status.Error) return;
    if (subscriptionsResult.loading) {
      setStatus(Status.Loading);
    } else if (subscriptionsResult.data?.data) {
      setSubscriptions(subscriptionsResult.data.data);
      setStatus(Status.Success);
    } else if (subscriptionsResult.error) {
      setStatus(Status.Error);
    }
  }, [setSubscriptions, status, subscriptionsResult]);

  React.useEffect(() => {
    if (status === Status.Success) {
      if (hasBootedIntercom.current) {
        window.Intercom("update");
      } else {
        const admin = getAdmin(authState);
        hasBootedIntercom.current = true;
        window.Intercom("boot", {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          email: admin?.email,
          user_id: admin?.uuid,
          created_at: admin?.created_at
        });
      }
    }
  }, [location.pathname, isAuth, status, authState]);

  useEffect(() => {status === Status.Success && executeCurrency()},[status])

  if (status === Status.Error) {
    return <Logout />;
  }

  if (status === Status.Pending || status === Status.Loading) return null;

  if (status === Status.Login) {
    window.Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID
    });
    return <LoginComponent apiKey={apiKey} />;
  }

  if (status === Status.ResetPassword) {
    window.Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID
    });
    return <ResetPasswordComponent {...resetPasswordParams} />;
  }

  if (status === Status.Success) {
    let redirectMap = new Map<string, PartialLocation>([
      ["/", fallbackLocation],
      ["/login", fallbackLocation],
      ["/clientarea", fallbackLocation],
      ["/clientarea/", fallbackLocation],
      ["/admin/clients", fallbackLocation],
      ["/admin/clients/", fallbackLocation],
      ["/login/", fallbackLocation],
      ["/reset-password", fallbackLocation],
      ["/reset-password/", fallbackLocation],
      ["/headlines/my-business", fallbackLocation],
      ["/headlines/my-business/", fallbackLocation],
      ["/manage/portfolio-filters", { pathname: "/manage/filters" }],
      ["/manage/portfolio-filters/", { pathname: "/manage/filters" }]
    ]);

    if (getSubscriptionTypeId() === SUBSCRIPTION_TYPE_IDS.LITE) {
      const liteRedirectMap = new Map<string, PartialLocation>([
        ["/market-view", { pathname: "/my-business" }],
        ["/scorecard", { pathname: "/my-business" }],
        ["/popular-reports", { pathname: "/my-business" }]
      ]);
      redirectMap = new Map<string, PartialLocation>([...redirectMap, ...liteRedirectMap]);
    }

    const redirectTo: PartialLocation = redirectMap.get(originalLocation.current.pathname) || originalLocation.current;

    if (redirectMap.has(location.pathname)) return <Redirect to={redirectTo} />;

    return <>{children}</>;
  }

  return null;
};

export default Gatekeeper;
