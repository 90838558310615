/* eslint-disable @typescript-eslint/prefer-as-const */

import React from "react";
import * as EffectorReact from "effector-react";
import * as Effector from "effector";
import { persist as persistLocal } from "effector-storage/local";

export const NodeChoice = function <
  NodeId extends string,
  Title extends string,
  OptionInps extends Record<any, unknown>,
  InitialValue extends string | number | (string[] | number[]),
  IsMultiple extends InitialValue extends Array<any> ? true : false,
  InitValue extends IsMultiple extends true ? (keyof OptionInps)[] : keyof OptionInps,
  EmptiedValue extends InitValue,
  ToOptions extends ([k, v]) => { value: typeof k; label: typeof v }
>(
  {
    nodeId
  }: {
    nodeId: NodeId;
  },
  {
    title,
    optionInputs,
    initialValue,
    emptiedValue,
    isMultiple,
    toOptions,
    ...props
  }: {
    title: Title;
    optionInputs: OptionInps;
    initialValue: InitialValue;
    emptiedValue?: EmptiedValue;
    isMultiple?: IsMultiple;
    toOptions?: ToOptions;
  }
) {
  const optionEntries = Object.entries(optionInputs).map(
    toOptions != null ? toOptions : ([value, label]) => ({ value, label })
  );

  return {
    id: nodeId,
    title,
    initialValue,
    initialLabel: optionInputs[initialValue],
    isMultiple: isMultiple != null ? isMultiple : Array.isArray(initialValue),
    optionValues: Object.keys(optionEntries) as (keyof OptionInps)[],
    optionLabels: Object.values(optionEntries) as OptionInps[keyof OptionInps][],
    optionInputs,
    optionEntries,
    emptiedValue,
    ...props
  };
};

export const NodeStore = <
  DomainName extends string,
  StorageKey extends string,
  Props extends ReturnType<typeof NodeChoice>
>(
  {
    domainName,
    storageKey
  }: {
    domainName: DomainName;
    storageKey: StorageKey;
  },
  props: Props
) => {
  type InitialValue = Props["initialValue"];
  type OptionEntries = Props["optionEntries"];
  type OptionValues = Props["optionValues"][number];
  type Values = IsMultiple<OptionValues[], OptionValues>;
  type Labels = IsMultiple<OptionValues[], OptionValues>;
  type Entry = { value: Values; label: string };
  type IsMultiple<T, F> = InitialValue extends Array<any> ? T : F;

  const domain = Effector.createDomain(domainName);

  // Events
  const resetValue = domain.createEvent("resetValue");
  const updateOptions = domain.createEvent<OptionEntries>("updateOptions");
  const updateValue = domain.createEvent<Values>("updateValue");
  const updateEntry = domain.createEvent<Entry>("updateEntry");

  // Turns values into labels.
  const valuesToLabels = (value: Values) =>
    // If given multiple, map over values
    Array.isArray(value)
      ? value.map((v: Props["optionInputs"]) => props.optionInputs[v])
      : (props.optionInputs[value] as Labels);

  // Retrieve locally stored state first.
  const initialEntry = (() => {
    const json = localStorage.getItem(storageKey);
    if (json) {
      try {
        const value = JSON.parse(json);
        const label = valuesToLabels(value);
        return { label, value };
      } catch (error) {
        console.error(error);
      }
    }
    return {
      label: props.initialLabel,
      value: props.initialValue
    };
  })() as Entry;
  const { label: initialLabel, value: initialValue } = initialEntry;

  // Stores
  const $options = domain.createStore<{ value: unknown; label: string }[]>(props.optionEntries);
  const $value = domain.createStore<Values>(initialValue);
  const $label = $value.map(valuesToLabels);
  const $entry = Effector.combine({ value: $value, label: $label });

  $options.on(updateOptions, (_state, payload) => payload);
  $value.on(updateEntry, (_state, { value }) => value);
  $value.on(resetValue, (_state, _payload) => props.initialValue as Values);
  $value.on(updateValue, (currentValue, value) => {
    if (props.isMultiple) {
      const changedValue = currentValue.includes(value)
        ? currentValue.filter((v: Values) => v !== value)
        : currentValue.concat(value).sort();
      const replaceValue = changedValue.length === 0 ? props.emptiedValue : changedValue;
      return replaceValue;
    }
    return value;
  });

  // Persistence
  persistLocal({ key: storageKey, store: $value });

  return {
    ...props,
    storageKey,
    optionEntries: $options.getState(),
    // Events
    updateOptions,
    updateEntry,
    updateValue,
    resetValue,
    // Stores
    $options,
    $value,
    $label,
    $entry,
    // Initial
    initialValue,
    initialLabel,
    initialEntry,
    // Getter fns
    getOptions: $options.getState,
    getValue: $value.getState,
    getLabel: $label.getState,
    getEntry: $entry.getState,
    // Hook fns
    useOptions: () => EffectorReact.useStore($options),
    useValue: () => EffectorReact.useStore($value),
    useLabel: () => EffectorReact.useStore($label),
    useEntry: () => EffectorReact.useStore($entry)
  } as const;
};

export const NodeTarget = <Name extends string, Ref extends React.RefObject<HTMLElement>, Props extends object>(
  {
    targetName,
    targetRef
  }: {
    targetName: Name;
    targetRef: Ref;
  },
  props: Props
) => {
  const $targetActive = Effector.createStore<boolean>(false, { name: targetName });
  const targetUpdate = Effector.createEvent<boolean>(`update${targetName}`);

  $targetActive.on(targetUpdate, (_state, payload) => payload);

  Effector.combine([targetRef as React.RefObject<HTMLElement> | { current: undefined }, $targetActive]).watch(
    ([targetRef, targetActive]) => {
      if (!targetRef.current) return;
      if (targetActive) {
        targetRef.current.style.outline = "6px solid blue";
      } else {
        targetRef.current.style.outline = "6px solid white";
      }
    }
  );

  return {
    ...props,
    $targetActive,
    targetUpdate,
    targetRef
  };
};
