import type { Period, PeriodConfig, PeriodSelection } from "lib/types/period.d";
import { findPeriod } from "lib/utilities/period";
import { app } from "./app";
import { initialSearch, setSearchParam } from "./search";

const parts = (initialSearch.period || "").split(",");
const selectedPeriod = findPeriod(parts[3]);
const rollup = parts[2] || selectedPeriod.Rollup;
const end = parts[1] || "";
const start = parts[0] || "";
const initialPeriod = {
  start,
  end,
  rollup,
  selection: selectedPeriod.Constant
} as Period;

export const $period = app.createStore<Period>(initialPeriod, { name: "period" });
export const setPeriod = app.createEvent<PeriodConfig>("setPeriod");
export const setPeriodSelection = app.createEvent<PeriodSelection>("setPeriodSelection");
export const getPeriod = $period.getState;
export const getPeriodSelection = () => getPeriod().selection;

$period.on(setPeriod, (state, period) => ({ ...state, ...period }));
$period.on(setPeriodSelection, (state, selection) => ({ ...state, selection }));
$period.watch(({ start, end, rollup, selection }) => {
  setSearchParam("period", [start, end, rollup, selection].join(","));
});
