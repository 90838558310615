import { useStore } from "effector-react";
import { createEvent, createStore } from "effector";
import { API_BASE_DEFAULT, API_BASE_DEFAULT_URL } from "lib/constants/apiUrl";
import { persistLocal } from "./utils/storage";

const initialApiActive = localStorage.getItem("api:active") || API_BASE_DEFAULT?.Key || "custom";
export const $apiActive = createStore<string>(initialApiActive, { name: "apiActive" });
export const setApiActive = createEvent<string>("setApiActive");
export const useApiActive = () => useStore($apiActive);
$apiActive.on(setApiActive, (_, payload) => payload);
persistLocal<string>("api:active", $apiActive);

const initialApiCustom = localStorage.getItem("api:custom") || API_BASE_DEFAULT_URL;
export const $apiCustom = createStore<string>(initialApiCustom, { name: "apiCustom" });
export const setApiCustom = createEvent<string>("setApiCustom");
export const useApiCustom = () => useStore($apiCustom);
$apiCustom.on(setApiCustom, (_, payload) => payload);
persistLocal<string>("api:custom", $apiCustom);

const initialApiSelection = localStorage.getItem("api:selection") || API_BASE_DEFAULT_URL;
export const $apiSelection = createStore<string>(initialApiSelection, { name: "apiSelection" });
export const setApiSelection = createEvent<string>("setApiSelection");
export const useApiSelection = () => useStore($apiSelection);
$apiSelection.on(setApiSelection, (_, payload) => payload);
persistLocal<string>("api:selection", $apiSelection);
