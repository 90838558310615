import { combine } from "effector";
import { SearchPane as PaneId } from "lib/constants/search";
import { app } from "./app";
import { $navFull } from "./navHeight";
import { initialSearch, setSearchParam } from "./search";

export const initialPane = (initialSearch.pane || null) as PaneId | null;

export const $pane = app.createStore<PaneId | null>(initialPane, { name: "pane" });
export const setPane = app.createEvent<PaneId | null>("setPane");
export const getPane = $pane.getState;

export const closePane = () => setPane(null);
export const togglePane = (id: PaneId) => setPane(getPane() === id ? null : id);
export const isPaneOpen = (...ids: (PaneId | null)[]) => ids.includes(getPane());

export const $paneNotNull = $pane.map((state) => state != null);
export const isPaneOpenAny = $paneNotNull.getState;

export const $paneHasBorder = combine(
  $navFull,
  $pane,
  (navFull, pane) =>
    !pane ||
    !navFull ||
    [
      "download",
      "period.custom",
      "portfolio.custom",
      "portfolio.more",
      "preferences",
      "search",
      "search.save",
      "retailer"
    ].includes(pane as string)
);

$pane.on(setPane, (_state, paneId) => paneId);
$pane.watch((paneId) => setSearchParam("pane", paneId));
