import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { css } from "styled-components/macro";

import ThemeBase from "lib/theme/ThemeBase";
import ForgotPasswordForm from "./ForgotPasswordForm";
import LoginForm from "./LoginForm";
import LoginWithApiKey from "./LoginWithApiKey";
import { Background, Content, Identity, LoginPanel, Wrapper, SuccessLabel, Icon } from "./UI";

const Login = ({ apiKey }: { apiKey: string | null }) => {
  const location = useLocation();
  const history = useHistory();
  const [passwordReseted, setPasswordReseted] = React.useState(false);

  useEffect(() => {
    const switchedKey = window.localStorage.getItem("switched");
    if (switchedKey) {
      window.localStorage.removeItem("switched");
      window.location.replace(`/login?api_key=${switchedKey}`);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("rp")) {
      history.replace("/login");
      setPasswordReseted(true);
    }
  }, [location.pathname, setPasswordReseted]);

  const currentYear = React.useMemo(() => new Date().getFullYear(), []);

  return (
    <ThemeBase minHeight="100vh" minWidth={848}>
      <Background>
        <Wrapper>
          <LoginPanel>
            <Identity />
            <Content>
              {passwordReseted && (
                <SuccessLabel>
                  <Icon className="fa fa-check" />
                  <FormattedMessage id="resetPassword.passwordChangedSuccessfully" />
                </SuccessLabel>
              )}
              <Switch>
                <Route path="/login">{apiKey ? <LoginWithApiKey apiKey={apiKey} /> : <LoginForm />}</Route>
                <Route path="/forgot-password">
                  <ForgotPasswordForm />
                </Route>
                <Route>
                  <Redirect to="/login" />
                </Route>
              </Switch>
            </Content>
            <p css="position: absolute; top: 100%; left: 24px; color: #fff;">
              <FormattedMessage id="login.copyright" values={{ currentYear }} />
            </p>
          </LoginPanel>
        </Wrapper>
      </Background>
    </ThemeBase>
  );
};

export default Login;
