import { app } from "./app";
import { sizes } from "../theme/system";

const initialNavFull = true;
const initialNavHeight = toHeight(initialNavFull);

export const $navFull = app.createStore<boolean>(initialNavFull, { name: "navFull" });
export const $navHeight = app.createStore<number>(initialNavHeight, {
  name: "navHeight"
});
export const setNavFull = app.createEvent<boolean>("setNavFull");
export const setNavHeight = app.createEvent<number>("setNavHeight");
export const getNavFull = $navFull.getState;
export const getNavHeight = $navHeight.getState;

$navFull.on(setNavFull, (_state, payload) => payload);
$navFull.map(toHeight).watch(setNavHeight);
$navHeight.on(setNavHeight, (_state, payload) => payload);

function toHeight(isNavFull: boolean) {
  return isNavFull ? sizes.navBarHeight + sizes.titleBarHeight : sizes.navBarHeight;
}
