export enum DATE_RANGE_TYPES {
  YEAR = "year",
  QUARTER = "quarter",
  MONTH = "month",
  WEEK = "week",
  DAY = "day"
}

export enum WEEKDAY_NUMBERS {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6
}

// MOST_RECENT_WEEK is the same as LAST_WEEK
export enum PREDEFINED_PERIODS {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  THIS_WEEK = "THIS_WEEK",
  THIS_MONTH = "THIS_MONTH",
  THIS_QUARTER = "THIS_QUARTER",
  THIS_YEAR = "THIS_YEAR",
  LAST_WEEK = "LAST_WEEK",
  LAST_MONTH = "LAST_MONTH",
  LAST_QUARTER = "LAST_QUARTER",
  LAST_YEAR = "LAST_YEAR",
  FOUR_WEEKS_TO_DATE = "FOUR_WEEKS_TO_DATE",
  THIRTEEN_WEEKS_TO_DATE = "THIRTEEN_WEEKS_TO_DATE",
  TWENTYSIX_WEEKS_TO_DATE = "TWENTYSIX_WEEKS_TO_DATE",
  FIFTYTWO_WEEKS_TO_DATE = "FIFTYTWO_WEEKS_TO_DATE",
  THIRTEEN_MONTHS_TO_DATE = "THIRTEEN_MONTHS_TO_DATE",
  TWENTYFIVE_MONTHS_TO_DATE = "TWENTYFIVE_MONTHS_TO_DATE"
}

// MOST_RECENT_WEEK is the same as LAST_WEEK
export enum PREDEFINED_TYPE {
  TODAY = DATE_RANGE_TYPES.DAY,
  YESTERDAY = DATE_RANGE_TYPES.DAY,
  THIS_WEEK = DATE_RANGE_TYPES.WEEK,
  LAST_WEEK = DATE_RANGE_TYPES.WEEK,
  THIS_MONTH = DATE_RANGE_TYPES.MONTH,
  LAST_MONTH = DATE_RANGE_TYPES.MONTH,
  THIS_QUARTER = DATE_RANGE_TYPES.QUARTER,
  LAST_QUARTER = DATE_RANGE_TYPES.QUARTER,
  THIS_YEAR = DATE_RANGE_TYPES.YEAR,
  LAST_YEAR = DATE_RANGE_TYPES.YEAR,
  FOUR_WEEKS_TO_DATE = DATE_RANGE_TYPES.WEEK,
  THIRTEEN_WEEKS_TO_DATE = DATE_RANGE_TYPES.WEEK,
  TWENTYSIX_WEEKS_TO_DATE = DATE_RANGE_TYPES.WEEK,
  FIFTYTWO_WEEKS_TO_DATE = DATE_RANGE_TYPES.WEEK
}
