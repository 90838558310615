import React from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import * as API from "ascentialedge-client";
import DevPanels from "lib/app/DevPanels";
import Gatekeeper from "lib/app/Gatekeeper";
import { Loading } from "lib/components/Loading";
import * as Preference from "lib/constants/preferences";
import GlobalStyles from "lib/theme/stylesheet";
import ThemeContext from "lib/theme/ThemeContext";
import getAsyncComponent from "lib/utilities/getAsyncComponent";
import { findRoute } from "lib/utilities/route";
import { RootStateProvider } from "units/market-share/RootState";
import messages from "units/market-share/translations/en-us";
import { getEnvHost } from "units/market-share/utility/env";

const AsyncAuthenticated = getAsyncComponent("lib/app/Authenticated");

API.setServer(getEnvHost() as string);

const App = function () {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <RootStateProvider>
          <IntlProvider locale="en" messages={messages}>
            <ThemeContext>
              <Gatekeeper
                fallbackLocation={
                  {
                    pathname: findRoute(Preference.StartPage.initialValue).Link
                  } as any
                }
              >
                {/* NOTE: the Authenticated component gets imported and rendered only after the user has logged in */}
                <AsyncAuthenticated fallback={<Loading />} />
              </Gatekeeper>
              <DevPanels />
            </ThemeContext>
          </IntlProvider>
        </RootStateProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
