import React from "react";
import { FormattedMessage } from "react-intl";
import { combine } from "effector";
import { FilterPortfolio } from "lib/constants/preferences";
import { withConfig } from "./app";
import { initialSearch, setSearchParam } from "./search";

import type { SavedPortfolioFilter, PortfolioFilterBranch } from "lib/types/portfolio";

const { storeSetGetUseTuple, storeSet, getUseTuple, getUse } = withConfig({ prefix: "portfolio" });

export const [$portfolioLoading, setPortfolioLoading, getPortfolioLoading, usePortfolioLoading] =
  storeSetGetUseTuple<boolean>(false, "portfolioLoading");

export const [$portfolioBranches, setPortfolioBranches, getPortfolioBranches, usePortfolioBranches] =
  storeSetGetUseTuple<PortfolioFilterBranch[]>([], "branches");

export const [
  $portfolioFiltersSavedByIds,
  setPortfolioFiltersSavedByIds,
  getPortfolioFiltersSavedByIds,
  usePortfolioFiltersSavedByIds
] = storeSetGetUseTuple<Record<string, SavedPortfolioFilter>>({}, "filtersSavedByIds");

export const $portfolioFiltersSaved = $portfolioFiltersSavedByIds.map<SavedPortfolioFilter[]>((filters) =>
  filters ? Object.values(filters) : []
);
export const setPortfolioFiltersSaved = setPortfolioFiltersSavedByIds.prepend<SavedPortfolioFilter[]>((filters) =>
  Object.values(filters || {})
    .filter((filter) => filter && filter.uuid)
    .reduce(
      (acc, filter) => ({
        ...acc,
        [filter.uuid as string]: filter
      }),
      {} as Record<string, SavedPortfolioFilter>
    )
);
export const [getPortfolioFiltersSaved, usePortfolioFiltersSaved] = getUseTuple(
  $portfolioFiltersSaved,
  setPortfolioFiltersSaved
);

export const initialPortfolioFilterSavedId =
  initialSearch.filter || (FilterPortfolio.initialValue === "no_filter" ? null : FilterPortfolio.initialValue);
export const [
  $portfolioFilterSavedId,
  setPortfolioFilterSavedId,
  getPortfolioFilterSavedId,
  usePortfolioFilterSavedId
] = storeSetGetUseTuple<string | null>(initialPortfolioFilterSavedId, "filterSavedId");
$portfolioFilterSavedId.watch((filterSavedId) => {
  setSearchParam("filter", filterSavedId);
});

export const $portfolioFilterSaved = combine(
  $portfolioFiltersSavedByIds,
  $portfolioFilterSavedId,
  (byIds, id) => (id && byIds[id]) || null
);
export const setPortfolioFilterSaved = setPortfolioFilterSavedId.prepend<SavedPortfolioFilter | null>(
  (filter) => filter?.uuid || null
);
export const [getPortfolioFilterSaved, usePortfolioFilterSaved] = getUseTuple(
  $portfolioFilterSaved,
  setPortfolioFilterSaved
);
$portfolioFiltersSavedByIds.on(setPortfolioFilterSaved, (state, payload) => {
  if (!payload || !payload.uuid) return state;
  return { ...state, [payload.uuid]: payload };
});

export const [
  $portfolioFilterOriginal,
  setPortfolioFilterOriginal,
  getPortfolioFilterOriginal,
  usePortfolioFilterOriginal
] = storeSetGetUseTuple<SavedPortfolioFilter | null>(null, "filterOriginal");

const filterNameCustom = React.createElement(FormattedMessage, { id: "custom" }, []);
const filterNameNone = React.createElement(FormattedMessage, { id: "noFilter" }, []);
export const [$portfolioFilterOriginalName, setPortfolioFilterOriginalName] = storeSet<string | React.ReactChild>(
  filterNameNone,
  "filterOriginalName"
);
export const [getPortfolioFilterOriginalName, usePortfolioFilterOriginalName] = getUseTuple(
  $portfolioFilterOriginalName,
  setPortfolioFilterOriginalName
);
combine($portfolioFilterSaved, $portfolioFilterOriginal, (savedFilter, originalFilter) => {
  if (!savedFilter) {
    setPortfolioFilterOriginalName(filterNameNone);
  } else if (originalFilter?.name) {
    setPortfolioFilterOriginalName(originalFilter.name);
  } else if (!originalFilter?.name && savedFilter.name) {
    if (originalFilter?.updatedAt !== savedFilter.updatedAt) {
      setPortfolioFilterOriginal(savedFilter);
    }
    setPortfolioFilterOriginalName(savedFilter.name);
  } else if (savedFilter && !savedFilter.name) {
    setPortfolioFilterOriginalName(filterNameCustom);
  } else {
    setPortfolioFilterOriginalName(filterNameNone);
  }
});

export const [
  $portfolioFilterActivated,
  setPortfolioFilterActivated,
  getPortfolioFilterActivated,
  usePortfolioFilterActivated
] = storeSetGetUseTuple<SavedPortfolioFilter | null>(null, "filterActivated");

export const [
  $portfolioFilterEditingId,
  setPortfolioFilterEditingId,
  getPortfolioFilterEditingId,
  usePortfolioFilterEditingId
] = storeSetGetUseTuple<string | null>(null, "filterEditingId");

export const $portfolioFilterEditing = combine(
  $portfolioFiltersSavedByIds,
  $portfolioFilterEditingId,
  (byIds, id) => (id && byIds[id]) || null
);
export const setPortfolioFilterEditing = setPortfolioFilterEditingId.prepend<SavedPortfolioFilter | null>(
  (filter) => filter?.uuid || null
);
export const [getPortfolioFilterEditing, usePortfolioFilterEditing] = getUseTuple(
  $portfolioFilterEditing,
  setPortfolioFilterEditing
);

export const [$portfolioItemsByBrand, setPortfolioItemsByBrand, getPortfolioItemsByBrand, usePortfolioItemsByBrand] =
  storeSetGetUseTuple<object>({}, "itemsByBrand");

export const [
  $portfolioItemsByCategory,
  setPortfolioItemsByCategory,
  getPortfolioItemsByCategory,
  usePortfolioItemsByCategory
] = storeSetGetUseTuple<object>({}, "itemsByCategory");

export const [
  $portfolioItemsByCustomAttribute,
  setPortfolioItemsByCustomAttribute,
  getPortfolioItemsByCustomAttribute,
  usePortfolioItemsByCustomAttribute
] = storeSetGetUseTuple<object>({}, "itemsByCustomAttribute");

export const [
  $portfolioItemsByCustomAttributeId,
  setPortfolioItemsByCustomAttributeId,
  getPortfolioItemsByCustomAttributeId,
  usePortfolioItemsByCustomAttributeId
] = storeSetGetUseTuple<object>({}, "itemsByCustomAttributeId");

export const [
  $portfolioItemsByCustomAttributeValue,
  setPortfolioItemsByCustomAttributeValue,
  getPortfolioItemsByCustomAttributeValue,
  usePortfolioItemsByCustomAttributeValue
] = storeSetGetUseTuple<object>({}, "itemsByCustomAttributeValue");

export const [
  $portfolioItemsByManufacturer,
  setPortfolioItemsByManufacturer,
  getPortfolioItemsByManufacturer,
  usePortfolioItemsByManufacturer
] = storeSetGetUseTuple<object>({}, "itemsByManufacturer");

export const [
  $portfolioItemsBySector,
  setPortfolioItemsBySector,
  getPortfolioItemsBySector,
  usePortfolioItemsBySector
] = storeSetGetUseTuple<object>({}, "itemsBySector");

export const [
  $portfolioItemsBySubcategory,
  setPortfolioItemsBySubcategory,
  getPortfolioItemsBySubcategory,
  usePortfolioItemsBySubcategory
] = storeSetGetUseTuple<object>({}, "itemsBySubcategory");

export const $portfolioItemsByGroup = combine({
  brand: $portfolioItemsByBrand,
  category: $portfolioItemsByCategory,
  customAttribute: $portfolioItemsByCustomAttribute,
  customAttributeId: $portfolioItemsByCustomAttributeId,
  customAttributeValue: $portfolioItemsByCustomAttributeValue,
  manufacturer: $portfolioItemsByManufacturer,
  sector: $portfolioItemsBySector,
  subcategory: $portfolioItemsBySubcategory
});
export const [getPortfolioItemsByGroup, usePortfolioItemsByGroup] = getUse($portfolioItemsByGroup);

// export const $portfolio =
//   combine({
//     branches: $portfolioBranches,
//     isLoading: $portfolioLoading,
//     filterOriginal: $portfolioFilterOriginal,
//     filterActivated: $portfolioFilterActivated,
//     filterEditingId: $portfolioFilterEditingId,
//     // filtersById: $filtersById,
//     filterSavedByIds: $portfolioFiltersSavedByIds,
//     itemsByGroup: $portfolioItemsByGroup,
//   });
// export const [getPortfolio, usePortfolio] =
//   getUse($portfolio);
