import React from "react";
import { RouteValue } from "lib/constants/routes";
import { usePathname } from "lib/stores/pathname";
import { findRoute } from "lib/utilities/route";

export default function useActiveRoute(): RouteValue {
  const pathname = usePathname();
  return React.useMemo(() => {
    return findRoute(pathname, "Link");
  }, [pathname]) as RouteValue;
}
