import React from "react";
import styled from "styled-components/macro";
import { variant, SpaceProps, space } from "styled-system";
import { Link as RouterLink } from "react-router-dom";
import background from "./background.jpg";
import panelBackground from "./panel-bg.svg";
import logo from "./edge_logo.png";

export const Background = styled.div`
  background-color: #303135;
  background-image: url(${background});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 1;
  height: 100%;
  min-height: 100vh;
  display: flex;
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin: 0 auto;
  min-height: 548px;
  padding: 24px 24px 100px;
  position: relative;
  width: 848px;
  z-index: 10;
`;

export const LoginPanel = styled.div`
  background-color: #fff;
  background-image: url(${panelBackground});
  background-position: 0 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  min-height: 475px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: stretch;
  width: 100%;
  position: relative;
`;

const Header = styled.header`
  height: 100%;
  position: relative;
  width: 380px;
  flex: 0 0 auto;
`;

const Logo = styled.img`
  margin-left: 180px;
  position: relative;
  top: 159px;
  width: 140px;
`;

export const Identity = function () {
  return (
    <Header>
      <Logo src={logo} alt="Edge Market Share" />
    </Header>
  );
};

export const Content = styled.main`
  box-sizing: border-box;
  background: #fff;
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 424px;
  padding: 48px;
  position: relative;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

export const Title = styled.h3`
  color: #00a064;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  margin: 0 0 32px;
  text-align: center;
  text-transform: uppercase;
`;

export const Field = styled.div<SpaceProps>`
  margin-bottom: 24px;
  ${space}
`;

interface LabelProps {
  variant?: string;
}
export const Label = styled.label<LabelProps>`
  color: #848b90;
  display: block;
  font-size: 11px;
  line-height: 13px;
  opacity: 0;
  transition: opacity 300ms, color 300ms;

  ${variant({
    variants: {
      active: {
        opacity: 1,
        color: "#00A064"
      }
    }
  })}
`;

export const Input = styled.input`
  border: none;
  border-bottom: 1px solid #adb1b5;
  display: block;
  font-size: 14px;
  height: 33px;
  line-height: 18px;
  width: 100%;
  transition: border-color 300ms;

  ::placeholder {
    transition: color 300ms;
  }

  :focus {
    outline: none;
    border-color: #00a064;

    ::placeholder {
      color: transparent;
    }
  }
`;

export const Button = styled.button`
  background: #00a064;
  border-radius: 20px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  text-transform: uppercase;
  width: 100%;

  :focus {
    outline: none;
  }

  :hover {
    background-color: #66c6a2;
  }

  :disabled {
    background-color: #ccc;
    cursor: default;
  }
`;

export const Terms = styled.p`
  color: #848b90;
  font-size: 12px;
  line-height: 20px;
  margin: 0 0 24px;
`;

export const ForgotPasswordLink = styled(RouterLink)`
  color: #00a064;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export const Link = styled(RouterLink)`
  color: inherit;
  font-size: 16px;
`;

export const LinkWrapper = styled.div`
  margin: 16px 0;
  text-align: center;
`;

export const End = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const SuccessLabel = styled.p`
  color: var(--color-green);
  margin: 0;
  position: relative;
  top: -20px;
  align-self: center;
`;

export const Icon = styled.i`
  margin-right: 5px;
`;
