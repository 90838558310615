import { RETAILER_IDS } from "./retailers";

export const API_BASES = [
  {
    Key: "dev",
    Label: "Dev",
    Url: "https://marketshareapi-dev.ms.ascentialedge.com"
  },
  {
    Key: "stage",
    Label: "Stage",
    Url: "https://marketshareapi-stage.ms.ascentialedge.com"
  },
  {
    Key: "prod",
    Label: "Prod",
    Url: "https://marketshareapi.ascentialedge.com"
  },
  {
    Key: "qa",
    Label: "QA",
    Url: "https://marketshareapi-qa.ms.ascentialedge.com"
  }
];

export const API_BASE_DEFAULT_URL =
  process.env.REACT_APP_MS_API_NEW ||
  // If no env var is set, then return a suitable API based on the browser hostname.
  API_BASES.find(
    ({ Key }) =>
      Key ===
      (window.location.hostname === "marketshare.ascentialedge.com"
        ? "prod"
        : window.location.hostname === "marketshare-dev.ms.ascentialedge.com"
        ? "dev"
        : window.location.hostname === "marketshare-stage.ms.ascentialedge.com"
        ? "stage"
        : window.location.hostname === "marketshare-qa.ms.ascentialedge.com"
        ? "qa"
        : window.location.hostname === "localhost"
        ? "dev"
        : "prod")
  )!.Url;

export const API_BASE_DEFAULT = API_BASES.find(({ Url }) => Url === API_BASE_DEFAULT_URL);

export const RETAILERS_TAG_STORE = {
  [RETAILER_IDS.AMAZON]: "dp",
  [RETAILER_IDS.WALMART]: "ip"
};
