export type PeriodStart = string;

export type PeriodEnd = string;

export type PeriodParts = [PeriodStart, PeriodEnd, PeriodRollup, PeriodSelection];

export interface PeriodConfig {
  start: PeriodStart;
  end: PeriodEnd;
  rollup: PeriodRollup;
}

export interface Period extends PeriodConfig {
  selection: PeriodSelection;
}

export type PeriodAbbr =
  | "ld"
  | "lw"
  | "lm"
  | "lq"
  | "ly"
  | "pd"
  | "pw"
  | "pm"
  | "pq"
  | "py"
  | "l4w"
  | "l13w"
  | "l26w"
  | "l52w"
  | "l13m"
  | "l25m";

export type PeriodSelection =
  | "TODAY"
  | "THIS_WEEK"
  | "THIS_MONTH"
  | "THIS_QUARTER"
  | "THIS_YEAR"
  | "YESTERDAY"
  | "LAST_WEEK"
  | "LAST_MONTH"
  | "LAST_QUARTER"
  | "LAST_YEAR"
  | "FOUR_WEEKS_TO_DATE"
  | "THIRTEEN_WEEKS_TO_DATE"
  | "TWENTYSIX_WEEKS_TO_DATE"
  | "FIFTYTWO_WEEKS_TO_DATE"
  | "THIRTEEN_MONTHS_TO_DATE"
  | "TWENTYFIVE_MONTHS_TO_DATE";

export type PeriodSelectionConfigurable = PeriodSelection &
  (
    | "THIS_MONTH"
    | "THIS_QUARTER"
    | "THIS_YEAR"
    | "LAST_MONTH"
    | "LAST_QUARTER"
    | "LAST_YEAR"
    | "FOUR_WEEKS_TO_DATE"
    | "THIRTEEN_WEEKS_TO_DATE"
    | "TWENTYSIX_WEEKS_TO_DATE"
    | "FIFTYTWO_WEEKS_TO_DATE"
  );

export type PeriodRollup = "day" | "week" | "month" | "quarter" | "year";

export enum PeriodWeekdayNumber {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}
