import type { Client } from "lib/types/auth";
import { NON_EMEA_TLDS, CUSTOM_DOWNLOAD_METRICS } from "lib/pages/ReportScheduler/constants";
import { SUBSCRIPTION_TYPE_IDS } from "lib/constants/subscriptions";
import useRetailerSpecificPageRules from "lib/bussinessRules/hooks/useRetailerSpecificPageRules";

export const getRetailerSpecificMetrices = () => {
  const {
    common: { defaultGetDataMatrices: defaultMatrices }
  } = useRetailerSpecificPageRules();
  return defaultMatrices;
};

export const getAllDefaultMetrics = (client: Client, subscriptionTypeId: number) => {
  const reatilerSpecificDefMetrics = getRetailerSpecificMetrices();
  let bySubscription = "PRODUCT";
  if (subscriptionTypeId === SUBSCRIPTION_TYPE_IDS.LITE) {
    bySubscription = "PRODUCT_LITE";
  } else if (subscriptionTypeId === SUBSCRIPTION_TYPE_IDS.MSX) {
    bySubscription = "MARKET_SHARE_X";
  }
  const byTld = clientVatField(client);
  return Object.keys(reatilerSpecificDefMetrics).reduce(
    (arr, k) =>
      Array.isArray(reatilerSpecificDefMetrics[k])
        ? arr.concat(reatilerSpecificDefMetrics[k])
        : arr.concat(reatilerSpecificDefMetrics[k][bySubscription][byTld]),
    []
  );
};

export function clientVatField(client: Client) {
  return clientHasVatMetrics(client) ? "VAT_METRICS" : "NO_VAT_METRICS";
}

export const clientHasVatMetrics = (client: Client) => {
  return !NON_EMEA_TLDS.includes(getClientTld(client));
};

export function getClientTld(client: Client) {
  return (typeof client.tld === "string" ? client.tld : client.tld.name).toLowerCase();
}
