/* eslint-disable @typescript-eslint/prefer-as-const */

import { NodeChoice, NodeStore } from "./node";
import { CONFIGURABLE_PERIODS, ConfigurablePeriod, DEFAULT_PERIOD } from "./period";
import { CONFIGURABLE_ROUTES, ConfigurableRoute, DEFAULT_ROUTE } from "./routes";

// Helpers
const graphTypeLineBar = {
  title: "Weekly graph type" as "Weekly graph type",
  initialValue: "line" as "line",
  optionInputs: {
    bar: "Bar" as "Bar",
    line: "Line" as "Line"
  }
};
const graphTypeLineArea = {
  ...graphTypeLineBar,
  optionInputs: {
    bar: "Area" as "Area",
    line: "Line" as "Line"
  }
};
const graphViewAccrued = {
  title: "Default graph view" as "Default graph view",
  initialValue: "weekly" as "weekly",
  optionInputs: {
    weekly: "Weekly" as "Weekly",
    ascending: "Accrued (asc)" as "Accrued (asc)",
    descending: "Accrued (desc)" as "Accrued (desc)"
  }
};
const salesTypeChoice = {
  title: "Sales Type" as "Sales Type",
  initialValue: ["1p", "2p", "3p"],
  optionInputs: {
    "1p": "1P" as "1P",
    "2p": "2P" as "2P",
    "3p": "3P" as "3P"
  },
  emptiedValue: ["1p" as "1p", "2p" as "2p", "3p" as "3p"]
};
const asinSortLeaders = {
  title: "ASINs" as "ASINs",
  initialValue: "leaders" as "leaders",
  optionInputs: {
    leaders: "Leaders" as "Leaders",
    movers: "Movers" as "Movers"
  }
};
const asinSortMovers = {
  ...asinSortLeaders,
  initialValue: "movers" as "movers"
};
const inPageMetric = {
  title: "Metric" as "Metric",
  initialValue: "sales" as "sales",
  optionInputs: {
    sales: "Sales" as "Sales",
    units: "Units" as "Units"
  }
};
const inPageVat = {
  title: "Show VAT (non US)" as "Show VAT (non US)",
  initialValue: "No" as "No",
  optionInputs: {
    No: "No" as "No",
    Yes: "Yes" as "Yes"
  }
};
const sectionView = {
  title: "View by" as "View by",
  initialValue: "manufacturer" as "manufacturer",
  optionInputs: {
    manufacturer: "Manufacturer" as "Manufacturer",
    brand: "Brand" as "Brand",
    sector: "Product Group" as "Product Group",
    category: "Category" as "Category",
    subcategory: "Subcategory" as "Subcategory"
  }
};

const inPagePeriodView = {
  title: "Period View By" as "Period View By",
  initialValue: "week" as "week",
  optionInputs: {
    week: "Week" as "Week",
    month: "Month" as "Month",
    quarter: "Quarter" as "Quarter",
    year: "Year" as "Year"
  }
};

// App
export const StartPage = NodeStore(
  { domainName: "StartPage", storageKey: "pref:sp" },
  NodeChoice(
    { nodeId: "startPage" },
    {
      title: "Default to" as "Default to",
      initialValue: DEFAULT_ROUTE.Constant,
      optionInputs: Object.fromEntries(CONFIGURABLE_ROUTES.map((r) => [r.Constant, r.TextMenu])) as Record<
        ConfigurableRoute["Constant"],
        ConfigurableRoute["TextMenu"]
      >
    }
  )
);

export const Retailers = NodeStore(
  { domainName: "Retailers", storageKey: "pref:r" },
  NodeChoice(
    { nodeId: "retailers" },
    {
      title: "Default Retailer" as "Default Retailer",
      initialValue: 1,
      optionInputs: {
        1: "Amazon" as "Amazon",
        2: "Walmart" as "Walmart"
      }
    }
  )
);

// Filters
export const FilterPeriod = NodeStore(
  { domainName: "FilterPeriod", storageKey: "pref:fp" },
  NodeChoice(
    { nodeId: "filterPeriod" },
    {
      title: "Period" as "Period",
      initialValue: DEFAULT_PERIOD.Constant,
      optionInputs: Object.fromEntries(CONFIGURABLE_PERIODS.map((r) => [r.Constant, r.MenuTitle])) as Record<
        ConfigurablePeriod["Constant"],
        ConfigurablePeriod["MenuTitle"]
      >
    }
  )
);
export const FilterPortfolio = NodeStore(
  { domainName: "FilterPortfolio", storageKey: "pref:fP" },
  NodeChoice(
    { nodeId: "filterPortfolio" },
    {
      title: "Portfolio" as "Portfolio",
      initialValue: "no_filter" as "no_filter",
      optionInputs: { no_filter: "No Filter" }
    }
  )
);
export const FilterSaved = NodeStore(
  { domainName: "FilterSaved", storageKey: "pref:fs" },
  NodeChoice(
    { nodeId: "filterSaved" },
    {
      title: "Get Data" as "Get Data",
      initialValue: -1 as -1,
      optionInputs: { [-1]: "All Metrics" }
    }
  )
);

// My Business - My Sales
export const MyBusinessMySalesGraphView = NodeStore(
  { domainName: "MyBusinessMySalesGraphView", storageKey: "pref:mb-ms-gv" },
  NodeChoice(
    { nodeId: "myBusiness-mySales-graphView" },
    {
      ...graphViewAccrued,
      initialValue: "ascending" as "ascending"
    }
  )
);
export const MyBusinessMySalesGraphType = NodeStore(
  { domainName: "MyBusinessMySalesGraphType", storageKey: "pref:mb-ms-gt" },
  NodeChoice(
    { nodeId: "myBusiness-mySales-graphType" },
    {
      ...graphTypeLineBar,
      initialValue: "bar" as "bar"
    }
  )
);
export const MyBusinessMySalesSalesType = NodeStore(
  { domainName: "MyBusinessMySalesSalesType", storageKey: "pref:mb-ms-st" },
  NodeChoice(
    { nodeId: "myBusiness-mySales-salesType" },
    {
      ...salesTypeChoice
    }
  )
);

// My Business - My Brand
export const MyBusinessMyBrandGraphShow = NodeStore(
  { domainName: "MyBusinessMyBrandGraphShow", storageKey: "pref:mb-mb-gs" },
  NodeChoice(
    { nodeId: "myBusiness-myBrand-graphShow" },
    {
      title: "Default graph" as "Default graph",
      initialValue: "sales" as "sales",
      optionInputs: {
        sales: "Sales" as "Sales",
        share: "Share" as "Share"
      }
    }
  )
);
export const MyBusinessMyBrandAsinSort = NodeStore(
  { domainName: "MyBusinessMyBrandAsinSort", storageKey: "pref:mb-mb-as" },
  NodeChoice(
    { nodeId: "myBusiness-myBrand-asinSort" },
    {
      ...asinSortLeaders
    }
  )
);
export const MyBusinessMyBrandGraphType = NodeStore(
  { domainName: "MyBusinessMyBrandGraphType", storageKey: "pref:mb-mb-gt" },
  NodeChoice(
    { nodeId: "myBusiness-myBrand-graphType" },
    {
      ...graphTypeLineBar,
      initialValue: "bar" as "bar"
    }
  )
);
export const MyBusinessMyBrandSalesType = NodeStore(
  { domainName: "MyBusinessMyBrandSalesType", storageKey: "pref:mb-mb-st" },
  NodeChoice(
    { nodeId: "myBusiness-myBrand-salesType" },
    {
      ...salesTypeChoice
    }
  )
);

// My Business - My Drivers
export const MyBusinessMyDriversAsinMetric = NodeStore(
  { domainName: "MyBusinessMyDriversAsinMetric", storageKey: "pref:mb-md-am" },
  NodeChoice(
    { nodeId: "myBusiness-myDrivers-asinMetric" },
    {
      title: "Default ASIN metric" as "Default ASIN metric",
      initialValue: "conversion" as "conversion",
      optionInputs: {
        traffic: "Traffic" as "Traffic",
        conversion: "Conversion" as "Conversion"
      }
    }
  )
);
export const MyBusinessMyDriversAsinSort = NodeStore(
  { domainName: "MyBusinessMyDriversAsinSort", storageKey: "pref:mb-md-as" },
  NodeChoice(
    { nodeId: "myBusiness-myDrivers-asinSort" },
    {
      ...asinSortMovers
    }
  )
);

// My Business - In-page Filters
export const MyBusinessInPageMetric = NodeStore(
  { domainName: "MyBusinessInPageMetric", storageKey: "pref:mb-ip-m" },
  NodeChoice(
    { nodeId: "myBusiness-inPage-metric" },
    {
      ...inPageMetric
    }
  )
);
export const MyBusinessInPageVat = NodeStore(
  { domainName: "MyBusinessInPageVat", storageKey: "pref:mb-ip-v" },
  NodeChoice(
    { nodeId: "myBusiness-inPage-vat" },
    {
      ...inPageVat
    }
  )
);

export const MyBusinessInPagePeriod = NodeStore(
  { domainName: "MyBusinessInPagePeriod", storageKey: "pref:mb-ip-p" },
  NodeChoice(
    { nodeId: "myBusiness-inPage-period" },
    {
      ...inPagePeriodView
    }
  )
);

// Competitive - Sales
export const CompetitiveSalesGraphView = NodeStore(
  { domainName: "CompetitiveSalesGraphView", storageKey: "pref:c-sl-gv" },
  NodeChoice(
    { nodeId: "competitive-sales-graphView" },
    {
      ...graphViewAccrued
    }
  )
);
export const CompetitiveSalesAsinSort = NodeStore(
  { domainName: "CompetitiveSalesAsinSort", storageKey: "pref:c-sl-as" },
  NodeChoice(
    { nodeId: "competitive-sales-asinSort" },
    {
      ...asinSortLeaders
    }
  )
);
export const CompetitiveSalesGraphType = NodeStore(
  { domainName: "CompetitiveSalesGraphType", storageKey: "pref:c-sl-gt" },
  NodeChoice(
    { nodeId: "competitive-sales-graphType" },
    {
      ...graphTypeLineBar,
      initialValue: "bar" as "bar"
    }
  )
);

// Competitive - Share
export const CompetitiveShareGraphView = NodeStore(
  { domainName: "CompetitiveShareGraphView", storageKey: "pref:c-sh-gv" },
  NodeChoice(
    { nodeId: "competitive-share-graphView" },
    {
      ...graphViewAccrued
    }
  )
);
export const CompetitiveShareAsinSort = NodeStore(
  { domainName: "CompetitiveShareAsinSort", storageKey: "pref:c-sh-as" },
  NodeChoice(
    { nodeId: "competitive-share-asinSort" },
    {
      ...asinSortLeaders
    }
  )
);
export const CompetitiveShareGraphType = NodeStore(
  { domainName: "CompetitiveShareGraphType", storageKey: "pref:c-sh-gt" },
  NodeChoice(
    { nodeId: "competitive-share-graphType" },
    {
      ...graphTypeLineArea
    }
  )
);

// Competitive - In-page Filters
export const CompetitiveInPageMetric = NodeStore(
  { domainName: "CompetitiveInPageMetric", storageKey: "pref:c-ip-m" },
  NodeChoice(
    { nodeId: "competitive-inPage-metric" },
    {
      ...inPageMetric
    }
  )
);
export const CompetitiveInPageSalesType = NodeStore(
  { domainName: "CompetitiveInPageSalesType", storageKey: "pref:c-ip-st" },
  NodeChoice(
    { nodeId: "competitive-inPage-salesType" },
    {
      ...salesTypeChoice,
      initialValue: ["1p", "3p"]
    }
  )
);
export const CompetitiveInPageSectionView = NodeStore(
  { domainName: "CompetitiveInPageSectionView", storageKey: "pref:c-ip-sv" },
  NodeChoice(
    { nodeId: "competitive-inPage-sectionView" },
    {
      ...sectionView,
      optionInputs: {
        ...sectionView.optionInputs,
        sales_type: "Sales Type" as "Sales Type"
      }
    }
  )
);

export const CompetitiveInPagePeriod = NodeStore(
  { domainName: "CompetitiveInPagePeriod", storageKey: "pref:c-ip-p" },
  NodeChoice(
    { nodeId: "competitive-inPage-period" },
    {
      ...inPagePeriodView
    }
  )
);

// Scorecard
export const ScorecardDefaultTab = NodeStore(
  { domainName: "ScorecardDefaultTab", storageKey: "pref:sc-dt" },
  NodeChoice(
    { nodeId: "scorecard-defaultTab" },
    {
      title: "Default tab" as "Default tab",
      initialValue: "traffic" as "traffic",
      optionInputs: {
        traffic: "Traffic" as "Traffic",
        conversion: "Conversion" as "Conversion",
        promotions: "Promotions" as "Promotions",
        ratings_review: "Ratings & Reviews" as "Ratings & Reviews",
        pricing: "Pricing" as "Pricing",
        availability: "Availability" as "Availability",
        profitability: "Profitability" as "Profitability"
      }
    }
  )
);

// Scorecard - In-page Filters
export const ScorecardInPageSectionView = NodeStore(
  { domainName: "ScorecardInPageSectionView", storageKey: "pref:sc-ip-sv" },
  NodeChoice(
    { nodeId: "scorecard-inPage-sectionView" },
    {
      ...sectionView
    }
  )
);

// Popular Reports - In-page Filters
export const PopularReportsInPageSalesType = NodeStore(
  { domainName: "PopularReportsInPageSalesType", storageKey: "pref:pr-ip-st" },
  NodeChoice(
    { nodeId: "popularReports-inPage-salesType" },
    {
      ...salesTypeChoice,
      initialValue: ["1p", "3p"]
    }
  )
);
export const PopularReportsInPageSectionView = NodeStore(
  { domainName: "PopularReportsInPageSectionView", storageKey: "pref:pr-ip-sv" },
  NodeChoice(
    { nodeId: "popularReports-inPage-sectionView" },
    {
      ...sectionView
    }
  )
);
