import type { PeriodAbbr, PeriodRollup, PeriodSelection } from "lib/types/period.d";
import { PERIODS, PERIODS_BY_CONSTANT } from "../constants/period";
import { FilterPeriod } from "../constants/preferences";

// API

export function toAbbr<T extends A>(arg: T): PeriodAbbr {
  const abbr = (() => {
    const re = /([^_]*)_([^_]*)_TO_DATE/;
    if (re.test(arg)) {
      return arg.replace(re, (_, $n, $u) => `l${num2char($n)}${unit2char($u)}`);
    }
    return arg
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/^last/, "previous")
      .split(" ")
      .map((part) => part[0])
      .join("")
      .replace(/^t$/, "td")
      .replace(/^y$/, "pd")
      .replace(/^t/, "l");
  })();
  if (abbr === "ld") return abbr;
  if (abbr === "lw") return abbr;
  if (abbr === "lm") return abbr;
  if (abbr === "lq") return abbr;
  if (abbr === "ly") return abbr;
  if (abbr === "pd") return abbr;
  if (abbr === "pw") return abbr;
  if (abbr === "pm") return abbr;
  if (abbr === "pq") return abbr;
  if (abbr === "py") return abbr;
  if (abbr === "l4w") return abbr;
  if (abbr === "l13w") return abbr;
  if (abbr === "l26w") return abbr;
  if (abbr === "l52w") return abbr;
  if (abbr === "l13m") return abbr;
  if (abbr === "l25m") return abbr;
  throw new Error(`invalid abbr: "${abbr}" given "${arg}"`);
}

export function findPeriod(value: string, ...keys: (keyof PERIODS)[]): PERIODS {
  if (keys.length === 0) {
    keys = ["Constant", "Abbreviated"];
  }
  for (const period of PERIODS) {
    for (const key of keys) {
      if (value === period[key]) {
        return period;
      }
    }
  }
  return PERIODS_BY_CONSTANT[FilterPeriod.getValue()];
}

export function toSelection<T extends A>(arg: T): PeriodSelection {
  const abbr = toAbbr(arg);
  if (abbr === "ld") return "TODAY";
  if (abbr === "lw") return "THIS_WEEK";
  if (abbr === "lm") return "THIS_MONTH";
  if (abbr === "lq") return "THIS_QUARTER";
  if (abbr === "ly") return "THIS_YEAR";
  if (abbr === "pd") return "YESTERDAY";
  if (abbr === "pw") return "LAST_WEEK";
  if (abbr === "pm") return "LAST_MONTH";
  if (abbr === "pq") return "LAST_QUARTER";
  if (abbr === "py") return "LAST_YEAR";
  if (abbr === "l4w") return "FOUR_WEEKS_TO_DATE";
  if (abbr === "l13w") return "THIRTEEN_WEEKS_TO_DATE";
  if (abbr === "l26w") return "TWENTYSIX_WEEKS_TO_DATE";
  if (abbr === "l52w") return "FIFTYTWO_WEEKS_TO_DATE";
  if (abbr === "l13m") return "THIRTEEN_MONTHS_TO_DATE";
  if (abbr === "l25m") return "TWENTYFIVE_MONTHS_TO_DATE";
  throw new Error(`invalid selection: "${abbr}" given "${arg}"`);
}

export function toRollup<T extends A>(arg: T): PeriodRollup {
  const abbr = toAbbr(arg);
  if (abbr.includes("d")) return "day";
  if (abbr.includes("w")) return "week";
  if (abbr.includes("m")) return "month";
  if (abbr.includes("q")) return "quarter";
  if (abbr.includes("y")) return "year";
  throw new Error(`invalid rollup: "${abbr}" given "${arg}"`);
}

// Private

type Upp<T extends string> = Uppercase<T>;
type Low<T extends string> = Lowercase<T>;
type Cap<T extends string> = Capitalize<T>;
type UppLow<T extends string> = T | Low<T> | Upp<T>;
type UppLowCap<T extends string> = UppLow<T> | Cap<T>;

type Delim = "_" | " ";

type This<T extends string> = `${UppLowCap<"this">}${Delim}${UppLowCap<T>}`;
type Last<T extends string> = `${UppLowCap<"last">}${Delim}${UppLowCap<T>}`;
type Latest<T extends string> = `${UppLowCap<"latest">}${Delim}${UppLowCap<T>}`;
type Previous<T extends string> = `${UppLowCap<"previous">}${Delim}${UppLowCap<T>}`;

type NumberOf<
  PC extends string,
  PF extends string,
  NC extends string,
  NF extends string,
  UC extends string,
  UF extends string
> =
  | UppLow<`${PC}${NC}${UC}`>
  | UppLowCap<`${PF} ${NC} ${UF}`>
  | UppLow<`${PF}_${NC}_${UF}`>
  | Upp<`${NF}_${UF}_to_date`>;

type L = LD | LW | LM | LQ | LY;
type P = PD | PW | PM | PQ | PY;
type N = L4W | L13W | L26W | L52W | L13M | L25M;
type A = L | P | N | PeriodSelection;

type LD = UppLow<"ld"> | Latest<"day"> | UppLow<"t" | "today">;
type LW = UppLow<"lw"> | Latest<"week"> | UppLow<"tw"> | This<"week">;
type LM = UppLow<"lm"> | Latest<"month"> | UppLow<"tm"> | This<"month">;
type LQ = UppLow<"lw"> | Latest<"quarter"> | UppLow<"tq"> | This<"quarter">;
type LY = UppLow<"lw"> | Latest<"year"> | UppLow<"tq"> | This<"year">;

type PD = UppLow<"pd"> | Previous<"day"> | UppLow<"y" | "yesterday">;
type PW = UppLow<"pw"> | Previous<"week"> | Last<"week">;
type PM = UppLow<"pm"> | Previous<"month"> | Last<"month">;
type PQ = UppLow<"pq"> | Previous<"quarter"> | Last<"quarter">;
type PY = UppLow<"py"> | Previous<"year"> | Last<"year">;

type L4W = NumberOf<"l", "latest", "4", "FOUR", "w", "weeks">;
type L13W = NumberOf<"l", "latest", "13", "THIRTEEN", "w", "weeks">;
type L26W = NumberOf<"l", "latest", "26", "TWENTYSIX", "w", "weeks">;
type L52W = NumberOf<"l", "latest", "52", "FIFTYTWO", "w", "weeks">;
type L13M = NumberOf<"l", "latest", "13", "THIRTEEN", "m", "months">;
type L25M = NumberOf<"l", "latest", "25", "TWENTYFIVE", "m", "months">;

function num2char(arg: string) {
  return {
    FOUR: 4,
    THIRTEEN: 13,
    TWENTYFIVE: 25,
    TWENTYSIX: 26,
    FIFTYTWO: 52
  }[arg.toUpperCase()];
}

function unit2char(arg: string) {
  return arg.toLowerCase()[0];
}
