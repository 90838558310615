import { useStore } from "effector-react";
import { createEvent, createStore } from "effector";
import { persistLocal } from "./utils/storage";

export const initialDevLocked = ["1", "true"].includes(localStorage.getItem("dev:locked") || "");

export const $devLocked = createStore<boolean>(initialDevLocked, { name: "devLocked" });
export const setDevLocked = createEvent<boolean>("setDevLocked");
export const getDevLocked = $devLocked.getState;
export const toggleDevLocked = createEvent("toggleDevLocked");
export const useDevLocked = () => useStore($devLocked);

$devLocked.on(setDevLocked, (_state, payload) => payload).on(toggleDevLocked, (state, _payload) => !state);

persistLocal<boolean>("dev:locked", $devLocked);
