import * as Effector from "effector";
import { useStore } from "effector-react";
import { camelCase } from "lodash";

import type { Domain, Event, Store } from "effector";

type Conf = { domain?: Domain; prefix?: string };

export const storeWithConfig =
  (conf: Conf = {}) =>
  <T>(initial: T, name: string) => {
    if (conf.prefix) name = `${conf.prefix}-${name}`;
    const domain = conf.domain || Effector;
    return domain.createStore<T>(initial, { name: `$${camelCase(name)}` }) as Store<T>;
  };

export const setWithConfig =
  (conf: Conf = {}) =>
  <T>(name: string) => {
    const domain = conf.domain || Effector;
    return domain.createEvent<T>(camelCase(name)) as Event<T>;
  };

export const getWithConfig =
  (_conf: Conf = {}) =>
  <T>(store: Store<T>) => {
    return () => store.getState() as T;
  };

export const useWithConfig =
  (_conf: Conf = {}) =>
  <T>(store: Store<T>) => {
    return () => useStore(store) as T;
  };

export const useSetWithConfig =
  (conf: Conf = {}) =>
  <T>(store: Store<T>, name?: string) => {
    const set = setWithConfig(conf)<T>(name || `set-${store.shortName}`);
    const use = useWithConfig(conf)<T>(store);
    return [use, set] as const;
  };
export const useSetTupleWithConfig =
  (conf: Conf = {}) =>
  <T>(store: Store<T>, name?: string) => {
    const [use, set] = useSetWithConfig(conf)<T>(store, name);
    return () => [use(), set] as const;
  };

export const getUseWithConfig =
  (conf: Conf = {}) =>
  <T>(store: Store<T>) => {
    const get = getWithConfig(conf)<T>(store);
    const use = useWithConfig(conf)<T>(store);
    return [get, use] as const;
  };
export const getUseTupleWithConfig =
  (conf: Conf = {}) =>
  <T>(store: Store<T>, set: Event<T>) => {
    const [get, use] = getUseWithConfig(conf)<T>(store);
    const $use = () => [use(), set] as const;
    return [get, $use] as const;
  };

export const getSetUseWithConfig =
  (conf: Conf = {}) =>
  <T>(store: Store<T>, name?: string) => {
    const [get, use] = getUseWithConfig(conf)<T>(store);
    const set = setWithConfig(conf)<T>(name || `set-${store.shortName}`);
    return [get, set, use] as const;
  };
export const getSetUseTupleWithConfig =
  (conf: Conf = {}) =>
  <T>(store: Store<T>, name?: string) => {
    const [get, set, use] = getSetUseWithConfig(conf)<T>(store);
    return [get, set, () => [use(), set]] as const;
  };

export const storeSetWithConfig =
  (conf: Conf = {}) =>
  <T>(initial: T, name: string, reducer: (s: T, p: T) => void | T = (_s, p) => p) => {
    const set = setWithConfig(conf)<T>(`set-${name}`);
    const store = storeWithConfig(conf)<T>(initial, name);
    store.on(set, reducer);
    return [store, set] as const;
  };

export const storeSetUseWithConfig =
  (conf: Conf = {}) =>
  <T>(initial: T, name: string) => {
    const [store, set] = storeSetWithConfig(conf)<T>(initial, name);
    const use = useWithConfig(conf)<T>(store);
    return [store, set, use] as const;
  };
export const storeSetUseTupleWithConfig =
  (conf: Conf = {}) =>
  <T>(initial: T, name: string) => {
    const [store, set, use] = storeSetUseWithConfig(conf)<T>(initial, name);
    const $use = () => [use(), set] as const;
    return [store, set, $use] as const;
  };

export const storeSetGetUseWithConfig =
  (conf: Conf = {}) =>
  <T>(initial: T, name: string) => {
    const [store, set] = storeSetWithConfig(conf)<T>(initial, name);
    const [get, use] = getUseWithConfig(conf)<T>(store);
    return [store, set, get, use] as const;
  };
export const storeSetGetUseTupleWithConfig =
  (conf: Conf = {}) =>
  <T>(initial: T, name: string) => {
    const [store, set] = storeSetWithConfig(conf)<T>(initial, name);
    const [get, use] = getUseTupleWithConfig(conf)<T>(store, set);
    return [store, set, get, use] as const;
  };

export const store = storeWithConfig();
export const set = setWithConfig();
export const get = getWithConfig();
export const use = useWithConfig();
export const useSet = useSetWithConfig();
export const useSetTulpe = useSetTupleWithConfig();
export const getUse = getUseWithConfig();
export const getUseTuple = getUseTupleWithConfig();
export const getSetUse = getSetUseWithConfig();
export const getSetUseTuple = getSetUseTupleWithConfig();
export const storeSet = storeSetWithConfig();
export const storeSetUse = storeSetUseWithConfig();
export const storeSetUseTuple = storeSetUseTupleWithConfig();
export const storeSetGetUse = storeSetGetUseWithConfig();
export const storeSetGetUseTuple = storeSetGetUseTupleWithConfig();

export const withConfig = (conf: Conf) => ({
  ...(conf.domain ? { domain: conf.domain } : {}),

  store: storeWithConfig(conf),
  set: setWithConfig(conf),
  get: getWithConfig(conf),
  use: useWithConfig(conf),
  useSet: useSetWithConfig(conf),
  useSetTulpe: useSetTupleWithConfig(conf),
  getUse: getUseWithConfig(conf),
  getUseTuple: getUseTupleWithConfig(conf),
  getSetUse: getSetUseWithConfig(conf),
  getSetUseTuple: getSetUseTupleWithConfig(conf),
  storeSet: storeSetWithConfig(conf),
  storeSetUse: storeSetUseWithConfig(conf),
  storeSetUseTuple: storeSetUseTupleWithConfig(conf),
  storeSetGetUse: storeSetGetUseWithConfig(conf),
  storeSetGetUseTuple: storeSetGetUseTupleWithConfig(conf),

  withConfig: (conf2: Conf) => withConfig({ ...conf, ...conf2 })
});
