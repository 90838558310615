/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/prefer-as-const */
import Logout from "lib/pages/Logout";
import Missing from "lib/pages/Missing";
import getAsyncComponent from "lib/utilities/getAsyncComponent";

export type ROUTE = typeof ROUTE;
export const ROUTE = {
  MyBusiness: {
    Constant: "my_business" as "my_business",
    LegacyId: "myBusiness" as "myBusiness",
    Link: "/my-business" as "/my-business",
    TextMenu: "My Business",
    TextHeading: "My Business",
    IsBeta: false as false,
    IsComingSoon: false as false,
    IsConfigurable: true as true,
    IsDefault: true as true,
    IsPrivate: true as true,
    ShowActions: false as false,
    ShowDownload: true as true,
    ShowFilters: true as true,
    ShowFooter: true as true,
    ShowForLite: true as true,
    ShowHeading: true as true,
    ShowInMenu: true as true,
    Component: getAsyncComponent("lib/pages/MyBusiness")
  },

  MarketView: {
    Constant: "market_view" as "market_view",
    LegacyId: "competitive" as "competitive",
    Link: "/market-view" as "/market-view",
    TextMenu: "Market View",
    TextHeading: "Market View",
    IsBeta: false as false,
    IsComingSoon: false as false,
    IsConfigurable: true as true,
    IsDefault: false as false,
    IsPrivate: true as true,
    ShowActions: false as false,
    ShowDownload: true as true,
    ShowFilters: true as true,
    ShowFooter: true as true,
    ShowForLite: false as false,
    ShowHeading: true as true,
    ShowInMenu: true as true,
    Component: getAsyncComponent("lib/pages/Competition")
  },

  Scorecard: {
    Constant: "scorecard" as "scorecard",
    LegacyId: "scorecard" as "scorecard",
    Link: "/scorecard" as "/scorecard",
    TextMenu: "Scorecard",
    TextHeading: "Scorecard",
    IsBeta: false as false,
    IsComingSoon: true as true,
    IsConfigurable: false as false,
    IsDefault: false as false,
    IsPrivate: true,
    ShowActions: false as false,
    ShowDownload: true as true,
    ShowFilters: true as true,
    ShowFooter: true as true,
    ShowForLite: false as false,
    ShowHeading: true as true,
    ShowInMenu: true as true,
    Component: getAsyncComponent("lib/pages/Scorecard")
  },

  PopularReports: {
    Constant: "popular_reports" as "popular_reports",
    LegacyId: "popularReports" as "popularReports",
    Link: "/popular-reports" as "/popular-reports",
    TextMenu: "Popular Reports",
    TextHeading: "Popular Reports",
    IsBeta: false as false,
    IsComingSoon: false as false,
    IsConfigurable: false as false,
    IsDefault: false as false,
    IsPrivate: true as true,
    ShowActions: false as false,
    ShowDownload: true as true,
    ShowFilters: true as true,
    ShowFooter: true as true,
    ShowForLite: false as false,
    ShowHeading: true as true,
    ShowInMenu: true as true,
    Component: getAsyncComponent("lib/pages/CustomReports")
  },

  Help: {
    Constant: "help" as "help",
    LegacyId: "help" as "help",
    Link: "/help" as "/help",
    TextMenu: "Get Help",
    TextHeading: "Get Help",
    IsBeta: false as false,
    IsComingSoon: false as false,
    IsConfigurable: false as false,
    IsDefault: false as false,
    IsPrivate: true,
    ShowActions: true as true,
    ShowDownload: false as false,
    ShowFilters: false as false,
    ShowForLite: false as false,
    ShowHeading: true as true,
    ShowInMenu: false as false
  },

  ReportSharing: {
    Constant: "report_sharing" as "report_sharing",
    LegacyId: "manage.reportSharing" as "manage.reportSharing",
    Link: "/manage/report-sharing" as "/manage/report-sharing",
    TextMenu: "Report Sharing",
    TextHeading: "Report Sharing",
    IsBeta: false as false,
    IsComingSoon: true as true,
    IsConfigurable: false as false,
    IsDefault: false as false,
    IsPrivate: true as true,
    ShowActions: true as true,
    ShowDownload: true as true,
    ShowFilters: false as false,
    ShowForLite: true as true,
    ShowHeading: true as true,
    ShowInMenu: false as false,
    Component: getAsyncComponent("lib/pages/ReportScheduler")
  },

  Users: {
    Constant: "users" as "users",
    LegacyId: "manage.users" as "manage.users",
    Link: "/manage/users" as "/manage/users",
    TextMenu: "Users",
    TextHeading: "User Management",
    IsBeta: false as false,
    IsComingSoon: true as true,
    IsConfigurable: false as false,
    IsDefault: false as false,
    IsPrivate: true as true,
    PermissionLevel: 600 as 600,
    ShowActions: true as true,
    ShowDownload: false as false,
    ShowFilters: false as false,
    ShowForLite: true as true,
    ShowHeading: true as true,
    ShowInMenu: false as false,
    Component: getAsyncComponent("lib/pages/Users")
  },

  Catalog: {
    Constant: "catalog" as "catalog",
    LegacyId: "manage.catalog" as "manage.catalog",
    Link: "/manage/catalog" as "/manage/catalog",
    TextMenu: "Catalog",
    TextHeading: "Manage Catalog",
    IsBeta: false as false,
    IsComingSoon: false as false,
    IsConfigurable: false as false,
    IsDefault: false as false,
    IsPrivate: true as true,
    ShowActions: false as false,
    ShowDownload: false as false,
    ShowFilters: false as false,
    ShowForLite: true as true,
    ShowHeading: true as true,
    ShowInMenu: false as false,
    Component: getAsyncComponent("lib/pages/Catalog")
  },

  Filters: {
    Constant: "filters" as "filters",
    LegacyId: "manage.filters" as "manage.filters",
    Link: "/manage/filters" as "/manage/filters",
    TextMenu: "Filters",
    TextHeading: "Manage Filters",
    IsBeta: false as false,
    IsComingSoon: false as false,
    IsConfigurable: false as false,
    IsDefault: false as false,
    IsPrivate: true as true,
    ShowActions: false as false,
    ShowDownload: false as false,
    ShowFilters: false as false,
    ShowForLite: true as true,
    ShowHeading: true as true,
    ShowInMenu: false as false,
    Component: getAsyncComponent("lib/pages/Filters")
  },

  Profile: {
    Constant: "profile" as "profile",
    LegacyId: "account.profile" as "account.profile",
    Link: "/account/profile" as "/account/profile",
    TextMenu: "Profile",
    TextHeading: "Profile Management",
    IsBeta: false as false,
    IsComingSoon: false as false,
    IsConfigurable: false as false,
    IsDefault: false as false,
    IsPrivate: true as true,
    ShowActions: true as true,
    ShowDownload: false as false,
    ShowFilters: false as false,
    ShowForLite: true as true,
    ShowHeading: true as true,
    ShowInMenu: false as false,
    Component: getAsyncComponent("lib/pages/Profile")
  },

  Logout: {
    Constant: "logout" as "logout",
    LegacyId: "logout" as "logout",
    Link: "/logout" as "/logout",
    TextMenu: "Log out",
    TextHeading: "Log out",
    IsBeta: false as false,
    IsDefault: false as false,
    IsPrivate: true as true,
    IsConfigurable: false as false,
    ShowActions: false as false,
    ShowDownload: false as false,
    ShowFilters: false as false,
    ShowForLite: true as true,
    ShowHeading: false as false,
    ShowInMenu: false as false,
    Component: Logout
  },

  Login: {
    Constant: "login" as "login",
    LegacyId: "login" as "login",
    Link: "/login" as "/login",
    TextMenu: "Login",
    TextHeading: "Login",
    IsBeta: false as false,
    IsConfigurable: false as false,
    IsDefault: false as false,
    IsPrivate: true as true,
    ShowActions: false as false,
    ShowDownload: false as false,
    ShowFilters: false as false,
    ShowForLite: true as true,
    ShowHeading: false as false,
    ShowInMenu: false as false
  },

  ResetPassword: {
    Constant: "reset-password" as "reset-password",
    LegacyId: "reset-password" as "reset-password",
    Link: "/reset-password" as "/reset-password",
    TextMenu: "Reset Password",
    TextHeading: "Reset Password",
    IsBeta: false as false,
    IsConfigurable: false as false,
    IsDefault: false as false,
    IsPrivate: true as true,
    ShowActions: false as false,
    ShowDownload: false as false,
    ShowFilters: false as false,
    ShowForLite: true as true,
    ShowHeading: false as false,
    ShowInMenu: false as false
  }
};

export type ROUTE_MISSING = typeof ROUTE_MISSING;
export const ROUTE_MISSING = {
  Constant: "missing" as "missing",
  LegacyId: "missing" as "missing",
  Link: "" as "",
  TextMenu: "",
  TextHeading: "",
  IsBeta: false as false,
  IsConfigurable: false as false,
  IsDefault: false as false,
  IsPrivate: true as true,
  ShowActions: false as false,
  ShowDownload: false as false,
  ShowFilters: false as false,
  ShowForLite: true as true,
  ShowHeading: false as false,
  ShowInMenu: false as false,
  Component: Missing
};

export type RouteKey = keyof ROUTE;
export type RouteValue = ROUTE[RouteKey];
export type RouteValues = RouteValue[];
export type RouteId = RouteValue["LegacyId"];
export type RouteLink = RouteValue["Link"];
export type RouteConstant = RouteValue["Constant"];

export const ROUTES = Object.values(ROUTE) as RouteValues;

export type ConfigurableRoute = Extract<RouteValue, { IsConfigurable: true }>;
export type CONFIGURABLE_ROUTES = ConfigurableRoute[];
export const CONFIGURABLE_ROUTES = ROUTES.filter((r) => r.IsConfigurable) as CONFIGURABLE_ROUTES;

export type MenuRoute = Extract<RouteValue, { ShownInMenu: true }>;
export type MENU_ROUTES = MenuRoute[];
export const MENU_ROUTES = ROUTES.filter((route) => route.ShowInMenu);

export type DEFAULT_ROUTE = Extract<ConfigurableRoute, { IsDefault: true }>;
export const DEFAULT_ROUTE = CONFIGURABLE_ROUTES.find((r) => r.IsDefault) as DEFAULT_ROUTE;

export type ROUTES_BY_ID = Record<RouteKey, RouteValue>;
export const ROUTES_BY_ID = ROUTES.reduce((a, r) => ({ ...a, [r.LegacyId]: r }), {}) as ROUTES_BY_ID;

export type ROUTES_BY_KEY = Record<Lowercase<RouteKey>, RouteValue>;
export const ROUTES_BY_KEY = ROUTES.reduce((a, r) => ({ ...a, [r.Constant.toLowerCase()]: r }), {}) as ROUTES_BY_ID;

export type ROUTE_IDS = Record<RouteConstant, RouteValue>;
export const ROUTE_IDS = ROUTES.reduce((a, r) => ({ ...a, [r.Constant]: r }), {}) as ROUTE_IDS;

export default ROUTES;
