import React from "react";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { makeUseAxios, Options as AxiosHookOptions } from "axios-hooks";
import { getApiBase } from "lib/api/utils";
import { axiosInstance } from "lib/modules/axios";
import { DATE_RANGE_TYPES } from "units/market-share/constants/date";
import useAuth from "./useAuth";
import useDateRange from "./useDateRange";
import useSavedPortfolioFilters from "./useSavedPortfolioFilters";

export const useAxiosNoCancel = (
  conf: string | AxiosRequestConfig | AxiosConfigOptions,
  opts: AxiosHookOptions = {}
) => {
  const [$conf, $opts] = normalizeConfigOptions(conf, opts);

  const [loading, setLoading] = React.useState<boolean>(!$opts.manual);
  const [data, setData] = React.useState<undefined | object>(undefined);
  const [error, setError] = React.useState<undefined | Error>(undefined);
  const [response, setResponse] = React.useState<undefined | AxiosResponse>(undefined);

  const makeRequest = (reqConf: AxiosRequestConfig = {}) => {
    setLoading(true);
    return axiosInstance
      .request({ ...$conf, ...reqConf })
      .then((res: AxiosResponse) => {
        setResponse(res);
        setData(res.data);
        setError(undefined);
        setLoading(false);
        return Promise.resolve(res);
      })
      .catch((err: Error) => {
        setResponse(undefined);
        setData(undefined);
        setError(err);
        setLoading(false);
        return Promise.reject(err);
      });
  };

  React.useEffect(() => {
    if (!$opts.manual) {
      makeRequest($conf);
    }
  }, [$conf, $opts]);

  const refetch = React.useCallback(
    (confOverride = {}) => {
      return makeRequest(confOverride);
    },
    [$conf]
  );

  return [{ loading, data, error, response }, refetch];
};

const $useAxios = makeUseAxios({ axios: axiosInstance });

type AxiosConfigOptions = AxiosRequestConfig & AxiosHookOptions;

export default function useAxios<TResponse = any>(
  conf: string | AxiosRequestConfig | AxiosConfigOptions,
  opts: AxiosHookOptions = {}
) {
  const [$conf, $opts] = normalizeConfigOptions(conf, opts);
  return $useAxios<TResponse>($conf, $opts);
}

useAxios.WithAuth = WithAuth;
useAxios.WithFilter = WithFilter;
useAxios.WithDateRange = WithDateRange;

type WithAuthT = {
  api?: string;
  user?: string;
  client?: string;
  headers?: Record<string, string>;
};

type WithFilterT = {
  filter_uuid?: string;
  filter_version?: string;
};

type WithDateRangeT = {
  start_date?: string;
  end_date?: string;
  rollup?: DATE_RANGE_TYPES;
};

export function WithAuth(cb: (auth: WithAuthT) => AxiosConfigOptions) {
  const {
    state: { authInformation: info, authTokens: tokens }
  } = useAuth();

  const authState = React.useMemo<WithAuthT>(
    () => ({
      api: getApiBase(),
      user: info?.user?.uuid,
      client: info?.client?.uuid,
      headers: { "X-API-KEY": tokens.user as any }
    }),
    [info, tokens]
  );

  const conf = cb(authState);
  if (
    conf.manual == null &&
    (authState.user == null ||
      authState.client == null ||
      (authState.headers && authState.headers["X-API-KEY"] == null))
  ) {
    conf.manual = true;
  }
  return conf;
}

export function WithFilter(cb: (filter: WithFilterT) => AxiosConfigOptions) {
  const { savedFilter: selectedSavedFilter } = useSavedPortfolioFilters();
  const { uuid, updatedAt } = selectedSavedFilter || {};

  const filterState = React.useMemo<WithFilterT>(
    () => ({
      filter_uuid: uuid,
      filter_version: updatedAt
    }),
    [uuid, updatedAt]
  );

  return cb(filterState);
}

export function WithDateRange(cb: (dateRange: WithDateRangeT) => AxiosConfigOptions) {
  const {
    state: { start, end },
    rollup
  } = useDateRange();

  const dateState = React.useMemo(
    () => ({
      start_date: start,
      end_date: end,
      rollup
    }),
    [start, end, rollup]
  );

  const conf = cb(dateState);
  if (conf.manual == null && (dateState.start_date == null || dateState.end_date == null || dateState.rollup == null)) {
    conf.manual = true;
  }
  return conf;
}

type NormalizedConfigOptions = [AxiosRequestConfig, AxiosHookOptions];

function normalizeConfigOptions(
  conf: string | AxiosRequestConfig | AxiosConfigOptions,
  opts: AxiosHookOptions = {}
): NormalizedConfigOptions {
  if (typeof conf === "string") conf = { url: conf };
  if (!conf.method) conf.method = "GET";
  if (!conf.headers) conf.headers = {};
  const { manual, useCache, autoCancel, ...$conf } = { ...conf, ...opts };
  const $opts = { manual, useCache, autoCancel };
  return [$conf, $opts];
}
