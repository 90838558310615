import React, { lazy, Suspense } from "react";
import useMounted from "lib/hooks/useMounted";

export default function asyncComponent(importComponent: any) {
  const Component = lazy(importComponent);

  const AsyncComponent = function (props: Record<string, any>) {
    const { fallback, ...otherProps } = props;

    const isMounted = useMounted()();

    return <Suspense fallback={fallback || null}>{isMounted ? <Component {...otherProps} /> : null}</Suspense>;
  };

  return AsyncComponent;
}
