import React from "react";
import "styled-components/macro";
import useAxios from "units/market-share/RootState/hooks/useAxios";
import { getEnv } from "units/market-share/utility/env";
import { Payload as AuthPayload } from "lib/types/auth";
import { Form, Title, Field, Label, Input, Button, Link, LinkWrapper, End } from "./UI";

interface LoginResponse {
  data: AuthPayload;
}

const useReset = () => {
  const { REACT_APP_MS_API_NEW } = getEnv();

  return useAxios<AuthPayload>(
    {
      url: `${REACT_APP_MS_API_NEW}/password/reset-request`,
      method: "POST"
    },
    { manual: true }
  );
};

enum FocusedField {
  Username
}

const ForgotPasswordForm: React.FC = function () {
  const [result, execute] = useReset();
  const [username, setUsername] = React.useState("");

  const [focusedField, setFocusedField] = React.useState<FocusedField>();

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        if (username) execute({ data: { username } });
      }}
    >
      <Title>Forgot Password</Title>
      {result.data || result.error ? (
        <>
          <div css="margin: 0 32px; text-align: center">
            <p>Further instructions have been sent to the email associated with the username, {username}.</p>
            <p>
              If you do not receive an email, please contact{" "}
              <a href="https://ascentialedge.zendesk.com/agent/dashboard">Edge Market Share Support</a>.
            </p>
          </div>
          <End>
            <LinkWrapper>
              <Link to="/login">Back to Log in</Link>
            </LinkWrapper>
          </End>
        </>
      ) : (
        <>
          <Field
            onFocus={() => setFocusedField(FocusedField.Username)}
            onBlur={() => setFocusedField(undefined)}
            my={32}
          >
            <Label variant={focusedField === FocusedField.Username ? "active" : undefined}>Username</Label>
            <Input
              name="username"
              type="text"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              placeholder="Username"
              required
            />
          </Field>
          <End>
            <Button type="submit" disabled={result.loading}>
              {result.loading ? "Please wait" : "Submit"}
            </Button>
            <LinkWrapper>
              <Link to="/login">Back to Log in</Link>
            </LinkWrapper>
          </End>
        </>
      )}
    </Form>
  );
};

export default ForgotPasswordForm;
