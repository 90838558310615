import { isPaneOpenAny } from "lib/stores/pane";

// TODO remove this component
export default function useDrawer() {
  return isPaneOpenAny();
}

export function applyDisabled(disabled: boolean) {
  return {
    className: disabled ? "disabled" : ""
  };
}
