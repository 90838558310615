import React from "react";
import { FormattedMessage } from "react-intl";
import { getDevFeature } from "lib/stores/devFeatures";
import {
  usePortfolioFilterEditing,
  usePortfolioFilterEditingId,
  usePortfolioFilterOriginal,
  usePortfolioFilterOriginalName,
  usePortfolioFilterSaved,
  usePortfolioFilterSavedId,
  usePortfolioFiltersSaved,
  usePortfolioLoading
} from "lib/stores/portfolio";

export default function useSavedPortfolioFilters() {
  const [loading, setLoading] = usePortfolioLoading();
  const [originalFilter, setOriginalFilter] = usePortfolioFilterOriginal();
  const [editingFilter, setEditingFilter] = usePortfolioFilterEditing();
  const [editingFilterId, setEditingFilterId] = usePortfolioFilterEditingId();
  const [savedFilter, setSavedFilter] = usePortfolioFilterSaved();
  const [savedFilterId, setSavedFilterId] = usePortfolioFilterSavedId();
  const [savedFilters, setSavedFilters] = usePortfolioFiltersSaved();
  const [originalFilterName, setOriginalFilterName] = usePortfolioFilterOriginalName();

  React.useEffect(() => {
    if (getDevFeature("search")) return;
    if (!savedFilter) {
      setOriginalFilterName(React.createElement(FormattedMessage, { id: "noFilter" }, []));
    } else if (originalFilter?.name) {
      setOriginalFilterName(originalFilter.name);
    } else if (!originalFilter?.name && savedFilter?.name) {
      setOriginalFilterName(savedFilter.name);
      setOriginalFilter(savedFilter);
    }
  }, [savedFilter, originalFilter]);

  return {
    loading,
    setLoading,
    savedFilter,
    setSavedFilter,
    savedFilterId,
    setSavedFilterId,
    savedFilters,
    setSavedFilters,
    editingFilter,
    setEditingFilter,
    editingFilterId,
    setEditingFilterId,
    originalFilter,
    setOriginalFilter,
    originalFilterName
  };
}
