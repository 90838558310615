import React from "react";

import { ACTION_TYPES } from "../constants";
import useRootState from "./useRootState";

const useScoreCard = () => {
  const { state, dispatch } = useRootState();
  const setActiveCard = React.useCallback(
    (payload) =>
      dispatch({
        type: ACTION_TYPES.SCORE_CARD.SET_ACTIVE_CARD,
        payload
      }),
    [dispatch]
  );

  const setLookerContent = React.useCallback(
    (payload) =>
      dispatch({
        type: ACTION_TYPES.SCORE_CARD.SET_LOOKER_CONTENT,
        payload
      }),
    [dispatch]
  );

  return React.useMemo(
    () => ({
      state: {
        scoreCard: state.scoreCard.scoreCard ?? "",
        lookerContent: state.scoreCard.lookerContent ?? ""
      },
      setActiveCard,
      setLookerContent
    }),
    [state.scoreCard.scoreCard, state.scoreCard.lookerContent, setActiveCard, setLookerContent]
  );
};

export default useScoreCard;
