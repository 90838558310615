import { SUBSCRIPTION_TYPE_IDS } from "lib/constants/subscriptions";

export const REPORT_PERMISSION_LEVEL = 200;
export const CATALOUGE_ADMIN_PERMISSION_LEVEL = 400;
export const CLIENT_ADMIN_PERMISSION_LVL = 600;
export const EMS_ADMIN_PERMISSION_LEVEL = 800;
export const SUPER_ADMIN_PERMISSION_LEVEL = 1000;

// FOR USER MODULE
const USER_ADD_PERMISSION_LEVL = CLIENT_ADMIN_PERMISSION_LVL;
const USER_DELETE_PERMISSION_LEVL = CLIENT_ADMIN_PERMISSION_LVL;
const USER_UPDATE_PERMISSION_LEVL = CLIENT_ADMIN_PERMISSION_LVL;
// FILTER MODULE
const FILTER_ADD_PERMISSION_LEVL = REPORT_PERMISSION_LEVEL;
const FILTER_DELETE_PERMISSION_LEVL = CATALOUGE_ADMIN_PERMISSION_LEVEL;
const FILTER_UPDATE_PERMISSION_LEVL = SUPER_ADMIN_PERMISSION_LEVEL;

// Client MODULE
const CLIENT_VIEW_PERMISSION_LEVL = EMS_ADMIN_PERMISSION_LEVEL;

// Subscription Module
export const SALES_TYPES_MARKET_VIEW_POPULAR_REPORTS = "market_share_market_view_popular_reports_sales_type";
export const MARKET_SHARE_POPULAR_REPORTS = "market_share_popular_reports_promotionalDetail";
export const SCORECARD_PROMOTIONS = "market_share_scorecard_promotions";
export const SCORECARD_RATINGS_REVIEWS = "market_share_scorecard_ratings_reviews";
export const SCORECARD_PRICING = "market_share_scorecard_pricing";
export const GET_DATA_2P_REVIEWCOUT_RATINGAVG = "market_share_get_data_2p_reviewcount_ratingavg";
export const MY_SALES_2P_MY_BRAND_SALES_2P = "market_share_my_sales_2p_my_brand_sales_2p";

const HIDE_FOR_SUBSCRIPTIONS_ID = {
  [SUBSCRIPTION_TYPE_IDS.MSX]: [
    SALES_TYPES_MARKET_VIEW_POPULAR_REPORTS,
    MARKET_SHARE_POPULAR_REPORTS,
    SCORECARD_PROMOTIONS,
    SCORECARD_RATINGS_REVIEWS,
    SCORECARD_PRICING,
    GET_DATA_2P_REVIEWCOUT_RATINGAVG,
    MY_SALES_2P_MY_BRAND_SALES_2P
  ]
};

// call this function as below
// checkUserCanAccess('filter', permissionLevel, 'edit', 'delete')
const checkUserCanAccess = (moduleName: string, userPermissionLevel: number | string, ...actions: string[]) => {
  let canUserEdit = false;
  let canUserDelete = false;
  let canUserAdd = false;
  let canUserView = false;

  const permissionCheck = (grantedPermissionLvl) => {
    if (Array.isArray(grantedPermissionLvl)) {
      if (grantedPermissionLvl.includes(userPermissionLevel)) {
        return true;
      }
      return false;
    }

    if (userPermissionLevel >= grantedPermissionLvl) {
      return true;
    }
    return false;
  };

  const subscriptionCheck = (subscriptionTypeId, moduleName) => {
    if (subscriptionTypeId && HIDE_FOR_SUBSCRIPTIONS_ID[subscriptionTypeId]?.includes(moduleName)) {
      return false;
    }
    return true;
  };

  const checkIfUserHasEditPermission = (moduleName) => {
    switch (moduleName) {
      case "user":
        canUserEdit = permissionCheck(USER_UPDATE_PERMISSION_LEVL);
        break;
      case "filter":
        canUserEdit = permissionCheck(FILTER_UPDATE_PERMISSION_LEVL);
        break;
      default:
        canUserEdit = permissionCheck(CLIENT_ADMIN_PERMISSION_LVL);
    }
  };

  const checkIfUserHasAddPermission = (moduleName) => {
    switch (moduleName) {
      case "user":
        canUserAdd = permissionCheck(USER_ADD_PERMISSION_LEVL);
        break;
      case "filter":
        canUserAdd = permissionCheck(FILTER_ADD_PERMISSION_LEVL);
        break;
      default:
        canUserAdd = permissionCheck(CLIENT_ADMIN_PERMISSION_LVL);
    }
  };

  const checkIfUserHasDeletePermission = (moduleName) => {
    switch (moduleName) {
      case "user":
        canUserDelete = permissionCheck(USER_DELETE_PERMISSION_LEVL);
        break;
      case "filter":
        canUserDelete = permissionCheck(FILTER_DELETE_PERMISSION_LEVL);
        break;
      default:
        canUserDelete = permissionCheck(CLIENT_ADMIN_PERMISSION_LVL);
    }
  };

  const checkIfUserHasViewPermission = (moduleName) => {
    switch (moduleName) {
      case "client":
        canUserView = permissionCheck(CLIENT_VIEW_PERMISSION_LEVL);
        break;
      case moduleName.match(/market_share_/)?.input:
        canUserView = subscriptionCheck(userPermissionLevel, moduleName);
        break;
      default:
        canUserView = permissionCheck(CLIENT_ADMIN_PERMISSION_LVL);
    }
  };

  // calling seperate functions based on actions supplied
  const checkPermissiableActions = (action, moduleName) => {
    (action.match(/edit/i) && checkIfUserHasEditPermission(moduleName)) ||
      (action.match(/delete/i) && checkIfUserHasDeletePermission(moduleName)) ||
      (action.match(/add/i) && checkIfUserHasAddPermission(moduleName)) ||
      (action.match(/view/i) && checkIfUserHasViewPermission(moduleName));
  };

  // mapping through actions array eg [edit, delete, add]
  actions.forEach((action) => checkPermissiableActions(action, moduleName));

  return {
    canUserAdd,
    canUserEdit,
    canUserDelete,
    canUserView
  };
};

export default checkUserCanAccess;
