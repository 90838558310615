import { createGlobalStyle } from "styled-components/macro";
import { fadeIn } from "./keyframes";
import { root } from "./system";

const globalStyle = createGlobalStyle`
  :root {
    /* Base local variables */
    --space: .75rem;
    --space-y: var(--space);
    --space-x: calc(var(--space) * 1.5);

    --duration: var(--duration-fast);

    --background-color: var(--color-white);
    --border-color: var(--color-grey-90);
    --border-style: solid;
    --border-width: 1px;
    --border: var(--border-width) var(--border-style) var(--color-grey-90);
    --color: var(--color-black);
    --font-size: var(--font-size-1);
    --font-weight: normal;

    /* Component variables */

    --html-font-size: var(--font-size-1);
    --html-font-family: var(--font-family-body);

    --body-background-color: var(--color-grey-90);
    --body-color: var(--color-black);
    --body-width: var(--content-width);

    --header-menu-duration: var(--duration-slow);
    --header-menu-line-height: 1.35;
    --header-nav-border-color: var(--color-grey-95);
    --header-nav-z-index: 30;
    --header-hr-color: var(--color-grey-90);
    --header-hr-z-index: 90;
    --header-vr-color: var(--color-grey_10);
    --header-vr-height: 3rem;
    --header-vr-space: var(--space-3);
    --header-vr-height-total: calc(var(--header-vr-height) + var(--header-vr-space));

    --nav-bar-z-index: 85;

    --active-bar-color: var(--color-green_50);

    --sticky-inner-z-index: 110;

    --content-padding-top: var(--title-bar-height);
    --content-padding-bottom: calc(var(--footer-height) + var(--space-4));

    --footer-z-index: 70;
    --footer-height: 50px;

    --drawer-wrapper-z-index: 80;

    --loading-color: var(--color-black);
    --loading-animation: 1s 0s alternate infinite ${fadeIn};

    --text-transitioner-fade-duration: var(--duration-fast);
    --text-transitioner-size-duration: var(--duration-normal);

    --section-body-loading-z-index: 15;
    --section-title-z-index: 20;
    --section-steppers-z-index: 25;

    --screen-background-color: var(--body-background-color);
    --screen-z-index: 200;

    --section-overlay-background-color: var(--color-white);
    --section-overlay-active-background-color: var(--color-overlay);
    --section-overlay-active-z-index: 80;

    --ease-extreme: cubic-bezier(1, 0.01, 0, 0.99);
    --ease-in-out: cubic-bezier(0.63, 0.37, 0.15, 1.07);

    --dev-panel-z-index: 300;

    --custom-search-drawer-background: var(--color-white);

    ${Object.entries(root).map(([prop, decl]) => `${prop}: ${decl};`)}
  }

  * {
   transition-timing-function: var(--ease-in-out);
  }

  html,
  body,
  #root {
    display: flex;
    flex: 1;
  }

  html {
    font-size: var(--html-font-size);
    font-family: var(--html-font-family);
    max-width: 100vw;
    overflow-x: hidden;
  }

  body {
    background-color: var(--body-background-color);
    color: var(--body-color);
    min-width: var(--body-width);
  }

  #root {
    flex-direction: column;
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--color-column-headers);
  }

  .sticky-inner-wrapper {
    display: flex;
    justify-content: center;
  }

  div.intercom-lightweight-app {
    z-index: 75;
  }

  /**
   * This is a start to the chart transitions.
   * They aren't ready yet.
   */


  /* div.rv-xy-plot,
  g.rv-xy-plot__series.rv-xy-plot__series--bar,
  rect {
    --duration: var(--duration-fast);
    transition-timing-function: linear;
  }

  div.rv-xy-plot {
    transition: height var(--duration);
  }

  g.rv-xy-plot__series.rv-xy-plot__series--bar {
    transition: transform var(--duration);
  }

  rect {
    transition: height var(--duration), y var(--duration);
  } */
`;

export default globalStyle;
