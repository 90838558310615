import React from "react";

import { useAuth } from "units/market-share/RootState";
import { getSelectedRetailerSubscriptionId } from "lib/stores/retailerSubscriptions";
import { SUBSCRIPTION_TYPE_IDS } from "lib/constants/subscriptions";

/**
 * Hook to get retailer subscription type.
 */
const useSubscriptionType = () => {

  const getRetailerSubscriptionTypeId = React.useCallback(
    () => getSelectedRetailerSubscriptionId(),
    [getSelectedRetailerSubscriptionId]
  );

  const getSubscriptionTypeId = React.useCallback(() => {
    return getRetailerSubscriptionTypeId() || SUBSCRIPTION_TYPE_IDS.NONE;
  }, [ getRetailerSubscriptionTypeId]);

  return React.useMemo(
    () => ({
      getRetailerSubscriptionTypeId,
      getSubscriptionTypeId
    }),
    [getRetailerSubscriptionTypeId, getSubscriptionTypeId]
  );
};

export default useSubscriptionType;
