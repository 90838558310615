import { ROUTE_MISSING, RouteConstant, RouteId, RouteLink, RouteValue } from "lib/constants/routes";
import { app } from "./app";
import { $pathname, initialPathname } from "./pathname";
import { setTitle } from "./title";
import { findRoute } from "../utilities/route";

export const initialRoute = findRoute(initialPathname, "Link");

export const $route = app.createStore<RouteValue | ROUTE_MISSING>(initialRoute, {
  name: "route"
});
export const setRoute = app.createEvent<string>("setRoute");
export const getRoute = () => $route.getState() as RouteValue;
export const isRouteActive = (...strs: (RouteConstant | RouteId | RouteLink | null)[]) => {
  const { Constant, LegacyId, Link } = getRoute();
  return !!strs.find((str) => str && [Constant, LegacyId, Link].includes(str));
};

export const $routeShowFilters = $route.map((route) => route.ShowFilters);

$route.on(setRoute, (_state, route) => findRoute(route, "Constant", "LegacyId", "Link"));
$route.watch((route) => setTitle(route.TextHeading));
$pathname.watch(setRoute);
