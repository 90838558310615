export const DOWNLOAD_FORMATS = {
  CSV: "csv",
  XLSX: "xlsx",
  API: "api"
};

export const DOWNLOAD_PERIODS = {
  MONTH: "month",
  QUARTER: "quarter",
  YEAR: "year",
  TO_DATE_4: "toDate4Week",
  TO_DATE_13_W: "toDate13Week",
  TO_DATE_26_W: "toDate26Week",
  TO_DATE_52_W: "toDate52Week",
  TO_DATE_13: "toDate13",
  TO_DATE_25: "toDate25",
  CUSTOM: "custom"
};

export const DOWNLOAD_GRANULARITY = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  QUARTERLY: "quarterly"
};

export const DOWNLOAD_DATA_BY = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  QUARTER: "quarter"
};

export const OPERATIONS_METRICS = [
  "RepOOS %",
  "Inventory Weeks on Hand",
  "Open PO Quantity",
  "Available Inventory",
  "Sellable On-Hand Inventory",
  "Sell Through Rate",
  "Vendor Lead Time"
];

export const TRAFFIC_METRICS = ["Page Views", "Unique Customers", "Fast Track GV %", "Lost Buy Box Price %"];

// deprecated
export const CUSTOM_DOWNLOAD_METRICS_OLD = {
  ATTRIBUTE: [
    "ASIN",
    "UPC",
    "Name",
    "Beginning Date Range",
    // "Day",
    "Week",
    "Month",
    "Quarter",
    "Year",
    "Manufacturer",
    "Brand",
    "Product Group",
    "Category",
    "Subcategory",
    "Platform"
    // "Merchant",
  ],
  PRODUCT_LITE: {
    NO_VAT_METRICS: [
      "1P Ordered Sales",
      "1P Shipped Sales",
      "1P Shipped COGS",
      "1P Ordered Units",
      "1P Shipped Units",
      "Average 1P Price"
    ],
    VAT_METRICS: [
      "My 1P Non-VAT Sales",
      "My 1P Non-VAT Shipped Sales",
      "Market 1P VAT Sales",
      "Market 1P VAT Shipped Sales",
      "1P Ordered Units",
      "1P Shipped Units",
      "Average 1P Price"
    ]
  },
  PRODUCT: {
    NO_VAT_METRICS: [
      "1P Ordered Sales",
      "1P Shipped Sales",
      "1P Shipped COGS",
      "2P Ordered Sales",
      "3P Ordered Sales",
      "Total Sales",
      "1P Ordered Units",
      "2P Ordered Units",
      "3P Ordered Units",
      "1P Shipped Units",
      "Average 1P Price",
      "Average 3P Price"
    ],
    VAT_METRICS: [
      "My 1P Non-VAT Sales",
      "My 1P Non-VAT Shipped Sales",
      "My 2P Non-VAT Sales",
      "My 3P Non-VAT Sales",
      "My Total Non-VAT Sales",
      "Market 1P VAT Sales",
      "Market 1P VAT Shipped Sales",
      "Market 2P VAT Sales",
      "Market 3P VAT Sales",
      "Market Total VAT Sales",
      "1P Ordered Units",
      "1P Shipped Units",
      "3P Ordered Units",
      "Average 1P Price",
      "Average 3P Price"
    ]
  },
  TRAFFIC: ["Page Views"],
  CONVERSION: ["Conversion Rate", "Review Count", "Average Rating"],
  OPERATIONS: ["RepOOS %"]
};

export const CUSTOM_DOWNLOAD_METRICS = {
  // ATTRIBUTE: [
  //   "ASIN",
  //   "UPC",
  //   "Name",
  //   "Beginning Date Range",
  //   // "Day",
  //   "Week",
  //   "Month",
  //   "Quarter",
  //   "Year",
  //   "Manufacturer",
  //   "Brand",
  //   "Product Group",
  //   "Category",
  //   "Subcategory",
  //   "Platform",
  //   // "Merchant",
  // ],
  INTERVAL: ["Beginning Date Range", "Week", "Month", "Quarter", "Year"],
  VIEW_BY: ["Manufacturer", "Brand", "Product Group", "Category", "Subcategory", "ASIN", "Name", "UPC"],
  CUSTOM_ATTRIBUTES: [],
  FIRST_PARTY: {
    PRODUCT_LITE: {
      NO_VAT_METRICS: [
        "1P Ordered Sales",
        "1P Shipped Sales",
        "1P Shipped COGS",
        "1P Ordered Units",
        "1P Shipped Units",
        "1P Orders",
        "1P Returns",
        "Repeat Purchase 1P Revenue",
        "1P Shipped ACU",
        "Average 1P Price"
      ],
      VAT_METRICS: [
        "My 1P Non-VAT Sales",
        "My 1P Non-VAT Shipped Sales",
        "Market 1P VAT Sales",
        "Market 1P VAT Shipped Sales",
        "1P Ordered Units",
        "1P Shipped Units",
        "1P Orders",
        "1P Returns",
        "Repeat Purchase 1P Revenue",
        "1P Shipped ACU",
        "Average 1P Price"
      ]
    },
    PRODUCT: {
      NO_VAT_METRICS: [
        "1P Ordered Sales",
        "1P Shipped Sales",
        "1P Shipped COGS",
        "1P Ordered Units",
        "1P Shipped Units",
        "1P Orders",
        "1P Returns",
        "Repeat Purchase 1P Revenue",
        "1P Shipped ACU",
        "Average 1P Price"
      ],
      VAT_METRICS: [
        "My 1P Non-VAT Sales",
        "My 1P Non-VAT Shipped Sales",
        "Market 1P VAT Sales",
        "Market 1P VAT Shipped Sales",
        "1P Ordered Units",
        "1P Shipped Units",
        "1P Orders",
        "1P Returns",
        "Repeat Purchase 1P Revenue",
        "1P Shipped ACU",
        "Average 1P Price"
      ]
    },
    MARKET_SHARE_X: {
      NO_VAT_METRICS: [
        "1P Ordered Sales",
        "1P Shipped Sales",
        "1P Shipped COGS",
        "1P Ordered Units",
        "1P Shipped Units",
        "1P Orders",
        "1P Returns",
        "Repeat Purchase 1P Revenue",
        "1P Shipped ACU",
        "Average 1P Price"
      ],
      VAT_METRICS: [
        "My 1P Non-VAT Sales",
        "My 1P Non-VAT Shipped Sales",
        "Market 1P VAT Sales",
        "My 1P VAT Shipped Sales",
        "1P Ordered Units",
        "1P Shipped Units",
        "1P Orders",
        "1P Returns",
        "Repeat Purchase 1P Revenue",
        "1P Shipped ACU",
        "Average 1P Price with VAT"
      ]
    }
  },
  SECOND_PARTY: {
    PRODUCT_LITE: {
      NO_VAT_METRICS: [],
      VAT_METRICS: []
    },
    PRODUCT: {
      NO_VAT_METRICS: ["2P Ordered Sales", "2P Ordered Units"],
      VAT_METRICS: ["My 2P Non-VAT Sales", "Market 2P VAT Sales", "2P Ordered Units"]
    },
    MARKET_SHARE_X: {
      NO_VAT_METRICS: [],
      VAT_METRICS: []
    }
  },
  THIRD_PARTY: {
    PRODUCT_LITE: {
      NO_VAT_METRICS: [],
      VAT_METRICS: []
    },
    PRODUCT: {
      NO_VAT_METRICS: ["3P Ordered Sales", "3P Ordered Units", "Average 3P Price"],
      VAT_METRICS: ["My 3P Non-VAT Sales", "Market 3P VAT Sales", "3P Ordered Units", "Average 3P Price"]
    },
    MARKET_SHARE_X: {
      NO_VAT_METRICS: ["3P Ordered Sales", "3P Ordered Units", "Average 3P Price"],
      VAT_METRICS: ["My 3P Sales with VAT", "My 3P Units", "Average 3P Price with VAT"]
    }
  },
  TOTALS: {
    PRODUCT_LITE: {
      NO_VAT_METRICS: [],
      VAT_METRICS: []
    },
    PRODUCT: {
      NO_VAT_METRICS: ["Total Sales"],
      VAT_METRICS: ["My Total Non-VAT Sales", "Market Total VAT Sales"]
    },
    MARKET_SHARE_X: {
      NO_VAT_METRICS: ["Total Sales", "Total Units"],
      VAT_METRICS: ["Market Total VAT Sales", "Market Total Units"]
    }
  },
  TRAFFIC: {
    PRODUCT: {
      NO_VAT_METRICS: TRAFFIC_METRICS,
      VAT_METRICS: TRAFFIC_METRICS
    },
    PRODUCT_LITE: {
      NO_VAT_METRICS: TRAFFIC_METRICS,
      VAT_METRICS: TRAFFIC_METRICS
    },
    MARKET_SHARE_X: {
      NO_VAT_METRICS: ["Page Views", "Unique Customers"],
      VAT_METRICS: ["Page Views", "Unique Customers"]
    }
  },
  CONVERSION: {
    PRODUCT_LITE: {
      NO_VAT_METRICS: ["Conversion Rate", "Count of Reviews", "Average Rating"],
      VAT_METRICS: ["Conversion Rate", "Count of Reviews", "Average Rating"]
    },
    PRODUCT: {
      NO_VAT_METRICS: ["Conversion Rate", "Count of Reviews", "Average Rating"],
      VAT_METRICS: ["Conversion Rate", "Count of Reviews", "Average Rating"]
    },
    MARKET_SHARE_X: {
      NO_VAT_METRICS: ["Conversion Rate"],
      VAT_METRICS: ["Conversion Rate"]
    }
  },
  OPERATIONS: {
    PRODUCT: {
      NO_VAT_METRICS: OPERATIONS_METRICS,
      VAT_METRICS: OPERATIONS_METRICS
    },
    PRODUCT_LITE: {
      NO_VAT_METRICS: OPERATIONS_METRICS,
      VAT_METRICS: OPERATIONS_METRICS
    },
    MARKET_SHARE_X: {
      NO_VAT_METRICS: OPERATIONS_METRICS.filter((metric) => metric !== "Available Inventory"),
      VAT_METRICS: OPERATIONS_METRICS.filter((metric) => metric !== "Available Inventory")
    }
  }
};

export const EMEA_TLDS = ["de", "fr", "es", "it", "eu", "couk", "in", "nl", "cojp"];
export const NON_EMEA_TLDS = ["com", "ca"];

export const DOWNLOAD_VIEW_BY = {
  MANUFACTURER: "manufacturer",
  BRAND: "brand",
  // MERCHANT: "merchant",
  SECTOR: "sector",
  CATEGORY: "category",
  SUBCATEGORY: "subcategory",
  // PLATFORM: "platform",
  // SALESTYPE: "salesType",
  ASIN: "asin"
};

export const DELETE_STATUS = {
  NO_ACTION: "NO_ACTION",
  CONFIRMING: "CONFIRMING",
  DELETING: "DELETING",
  DELETED: "DELETED",
  POST_DELETE: "POST_DELETE"
};
