/* eslint-disable no-case-declarations */

import produce from "immer";
import { setDownload } from "lib/stores/download";
import { setFilterData } from "lib/stores/filterData";
import { setPeriod, setPeriodSelection } from "lib/stores/period";
import {
  setPortfolioBranches,
  setPortfolioFilterEditingId,
  setPortfolioFilterOriginal,
  setPortfolioFiltersSavedByIds,
  setPortfolioLoading
} from "lib/stores/portfolio";
// import { setPortfolioItemsByGroup } from "lib/stores/portfolioItemsByGroup";
import { App } from "lib/types/app";
import { ACTION_TYPES } from "./constants";
import { Debug } from "lib/debug";

// NOTE: This reducer uses Immer tlib/stores/filterEditingId changes in a convenient way
//       https://immerjs.github.io/immer/docs/introduction

const cache: Map<string, string> = new Map();

const reducer = produce((state: App, action) => {
  const { type, payload } = action;

  const [key, val] = JSON.stringify([type, "|||", payload]).split("|||");
  const curr = cache.get(key);
  const hash = btoa(unescape(encodeURIComponent(val)));
  if (curr !== hash) {
    cache.set(key, hash);
    Debug(`RootState:reducer`)(type, payload);
  }

  let value: any;

  switch (type) {
    case ACTION_TYPES.SET_DATE_RANGE:
      const { start, end, type } = payload;
      setPeriod({ start, end, rollup: type });
      Object.assign(state.date, { start, end, type });
      break;

    case ACTION_TYPES.SET_AUTH:
      if (
        payload.data.admin.uuid &&
        payload.data.client.uuid &&
        payload.data.jwts.user &&
        payload.data.user.uuid &&
        payload.tokens.user
      ) {
        state.auth.isAuth = true;
        state.auth.authInformation = payload.data;
        state.auth.authTokens = payload.tokens;
      }
      break;

    case ACTION_TYPES.SET_SUBSCRIPTIONS:
      state.subscriptions = payload.reduce(
        (prev, sub) => {
          const prevTemp = prev;
          if (sub.deleted_at === null) {
            prevTemp[sub.type] = true;
          }
          return prevTemp;
        },
        {
          "amz_dash": false,
          "amz_promo": false,
          "3p_dash": false,
          "traffic": false
        }
      );
      break;

    case ACTION_TYPES.SET_ROUTE_FOR_REFRESH:
      state.route = payload;
      break;

    case ACTION_TYPES.SET_CHART_OPTIONS:
      state.chartoptions[payload.optionsChart] = payload.data;
      break;

    case ACTION_TYPES.DOWNLOAD_OPTIONS.INIT:
      state.downloadOptions[payload.id] = payload.initObj;
      break;

    case ACTION_TYPES.DOWNLOAD_OPTIONS.SET_FILENAME:
      state.downloadOptions[payload.id] = {
        ...state.downloadOptions[payload.id],
        fileName: payload.fileName
      };
      break;

    case ACTION_TYPES.TABLE_VIEW_TYPE_OPTIONS.INIT:
      state.tableViewTypeOptions[payload.id] = payload.initObj;
      break;

    case ACTION_TYPES.TABLE_VIEW_TYPE_OPTIONS.SET_TYPE:
      state.tableViewTypeOptions[payload.id] = {
        ...state.tableViewTypeOptions[payload.id],
        type: payload.type
      };
      break;

    case ACTION_TYPES.STEPPERS.SET_VALUE:
      state.steppers[payload.id] = payload.value;
      break;

    case ACTION_TYPES.SCORE_CARD.SET_ACTIVE_CARD:
      state.scoreCard.scoreCard = payload;
      break;

    case ACTION_TYPES.SCORE_CARD.SET_LOOKER_CONTENT:
      state.scoreCard.lookerContent = payload;
      break;

    case ACTION_TYPES.IN_PAGE_FILTERS.ACTIVATE:
      state.inPageFilter[payload.id].activated = payload.value;
      break;

    case ACTION_TYPES.IN_PAGE_FILTERS.SETFILTER:
      state.inPageFilter[payload.id][payload.value.filter] = payload.value.field;
      break;

    case ACTION_TYPES.FILTERS.SET_DATA:
      value = payload || {};
      state.filterData = value;
      setFilterData(value);
      break;

    case ACTION_TYPES.FILTERS.SET_EDITING:
      value = payload || null;
      state.filterEditingId = value;
      setPortfolioFilterEditingId(value);
      break;

    case ACTION_TYPES.PORTFOLIO.SET_ORIGINAL_FILTER:
      value = payload;
      state.filterOriginal = payload;
      setPortfolioFilterOriginal(payload);
      break;

    case ACTION_TYPES.PORTFOLIO.SET_SAVED_FILTERS:
      value = Object.values(payload).reduce(
        (acc: any, filter: any) => ({
          ...acc,
          [filter.uuid]: filter
        }),
        {}
      ) as Record<string, any>;
      state.filterSavedByIds = value;
      setPortfolioFiltersSavedByIds(value);
      break;

    case ACTION_TYPES.PORTFOLIO.SET_SAVED_FILTER:
      value = { ...state.filterSavedByIds, [payload.uuid]: payload };
      state.filterSavedByIds = value;
      setPortfolioFiltersSavedByIds(value);
      break;

    case ACTION_TYPES.PORTFOLIO.REMOVE_SAVED_FILTER:
      value = { ...state.filterSavedByIds };
      delete value[payload.uuid];
      state.filterSavedByIds = value;
      setPortfolioFiltersSavedByIds(value);
      break;

    case ACTION_TYPES.PORTFOLIO.SET_LOADING:
      value = !!payload;
      state.portfolioLoading = value;
      setPortfolioLoading(value);
      break;

    case ACTION_TYPES.PORTFOLIO.SET_BRANCHES:
      value = payload;
      state.portfolioBranches = value;
      setPortfolioBranches(value);
      break;

    case ACTION_TYPES.PORTFOLIO.SET_ITEMS:
      value = { ...state.portfolioItemsByGroup };
      Object.values(payload).forEach((entity: any) => {
        if (!value[entity.type]) value[entity.type] = {};
        value[entity.type][entity.id] = entity;
      });
      state.portfolioItemsByGroup = value;
      // setPortfolioItemsByGroup(value);
      break;

    case ACTION_TYPES.CUSTOM_DOWNLOAD:
      state.download = payload;
      setDownload(payload);
      break;

    case ACTION_TYPES.CREATE_USER:
      state.createUserOpen = payload;
      break;

    case ACTION_TYPES.CALENDAR.SET_ITEMS:
      state.calendar.items = payload;
      break;

    case ACTION_TYPES.CALENDAR.SET_INITIAL_LOAD:
      state.calendar.isInitialLoad = payload;
      break;

    case ACTION_TYPES.CALENDAR.SET_SELECTION:
      state.calendar.selection = payload;
      setPeriodSelection(payload);
      break;

    case ACTION_TYPES.CALENDAR.SET_ORIGINAL_SELECTION:
      state.calendar.originalSelection = payload;
      break;

    case ACTION_TYPES.SET_CUSTOM_FIELDS:
      state.customFields = payload;
      break;

    case ACTION_TYPES.USERS.SET_EDIT_USER:
      state.usersManagement.user = payload;
      break;

    case ACTION_TYPES.RATECARDS.TOGGLE_RATECARD_DRAWER:
      state.rateCard.openRateCardDrawer = payload;
      break;

    case ACTION_TYPES.RATECARDS.SAVE_DEFAULT_RATECARD_DATA:
      state.rateCard.clientDefRateCardData = payload;
      break;

    case ACTION_TYPES.RATECARDS.UPDATE_RATE_CARDS:
      state.rateCard.updateRateCards = payload;
      break;

    case ACTION_TYPES.RATECARDS.IS_EDIT_RATECARD_DRAWER:
      state.rateCard.editRateCardData = payload;
      break;

    case ACTION_TYPES.RATECARDS.SHOW_RATECARD_INFO:
      state.rateCard.showRateCardData = payload;
      break;

    case ACTION_TYPES.RATECARDS.SET_UPDATE_LOADING:
      state.rateCard.updateLoading = payload;
      break;

    case ACTION_TYPES.RATECARDS.SET_RATE_CARDS:
      Object.assign(state, { rateCard: { ...state.rateCard, rateCards: payload } });
      break;
    case ACTION_TYPES.PROFITLOSS.SET_PROFIT_LOSS_CARDS:
      Object.assign(state, { profitLoss: { ...state.profitLoss, [payload.type]: payload.data } });
      break;
    case ACTION_TYPES.PROFITLOSS.SET_PROFIT_LOSS_SCENARIO:
      Object.assign(state, { profitLoss: { ...state.profitLoss, selectedScenario: payload } });
      break;

    case ACTION_TYPES.RISK_CHART.SET_SELECTED_RISK_PERIOD:
      Object.assign(state, { riskChart: { ...state.riskChart, x: payload } });
      break;
    case ACTION_TYPES.RISK_CHART.SET_XDOMAIN:
      Object.assign(state, { riskChart: { ...state.riskChart, xDomain: payload } });
      break;
    case ACTION_TYPES.RISK_CHART.SET_PERIOD_END_DATE:
      Object.assign(state, { riskChart: { ...state.riskChart, periodEnd: payload } });
      break;

    case ACTION_TYPES.HEAT_MAP.SET_XY:
      Object.assign(state, { heatMap: { ...state.heatMap, x: payload.x, y: payload.y } });
      break;

    case ACTION_TYPES.GET_CURRENCY_DATA:
      state.currencyData = payload;
      break;

    case ACTION_TYPES.SEARCH.GET_DEPARTMENT_LIST:
      state.search.departmentList = payload;
      break;

    case ACTION_TYPES.SEARCHV2.GET_CLICKS_GRAPH_DATA:
      state.searchv2.allGraphData = {
        ...state?.searchv2?.allGraphData,
        searchTerm: payload.searchTerm,
        clicks: payload?.clicks
      };
      break;

    case ACTION_TYPES.SEARCHV2.GET_CONVERSIONS_GRAPH_DATA:
      state.searchv2.allGraphData = {
        ...state?.searchv2?.allGraphData,
        conversions: payload.conversions
      };
      break;

    case ACTION_TYPES.SEARCHV2.GET_SEARCH_GRAPH_DATA:
      state.searchv2.allGraphData = {
        ...state?.searchv2?.allGraphData,
        ranks: payload.ranks,
        department: payload?.department,
        startDate: payload?.startDate,
        endDate: payload?.endDate
      };
      break;

    default:
      break;
  }
});

export default reducer;
